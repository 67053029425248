import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import { KycService as ModalKycSrv } from 'src/app/services/modal-kyc/kyc.service';
import { KycService } from 'src/app/services/kyc.service';
import Swal from 'sweetalert2';
import * as jsonSeller from '../../abis/Seller.json';
import * as jsonStableCoin from '../../abis/ERC20.json';
import { PaymentService } from 'src/app/services/payment.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from 'src/app/services/auth.service';
import { debounceTime, firstValueFrom } from 'rxjs';
import { Location } from '@angular/common';
import { LoaderService } from 'src/app/services/loader.service';
import { DetailRealEstateComponent } from '../detail-real-estate/detail-real-estate.component';
import { MatDialog } from '@angular/material/dialog';
import truncateEthAddress from 'truncate-eth-address';

@Component({
  selector: 'app-invest-real-estate',
  templateUrl: './invest-real-estate.component.html',
  styleUrls: ['./invest-real-estate.component.css']
})
export class InvestRealEstateComponent implements OnInit {

  projectId: string | null = null;
  userInversor: boolean = false
  info: any = null
  logged: boolean = false;
  web3: any | undefined;
  ourAddress: string | undefined;
  formBuy: FormGroup = this.createFormBuy();
  formBuyTarjeta: FormGroup = this.createFormBuyTarjeta();
  abiSeller: any = jsonSeller;
  abiStableCoin: any = jsonStableCoin;
  addressSellerSelected: string | undefined;
  nowTimeStamp: number = Math.floor(Date.now() / 1000);
  placeholderStablecoin: string = '0.00';
  minimumAmount: number = 0;
  maximumAmount: number = 0;
  eurosSold: number = 0;
  nameSymbolTokenToPay: string | undefined;
  checkedOkLegal: boolean = false;
  checkedOkLegalTransferencia: boolean = false;
  checkedOkLegalTarjeta: boolean = false;
  activeTab: string = 'tab1';
  euroAmount: number = 0;
  daiAmount: number = 0;
  selectedBox: number = 0;
  show: boolean = false;
  showCrypto: boolean = false;
  selectedBoxTransferencia: number = 0;
  showTransferencia: boolean = false;
  euroAmountTransferencia = 0;
  selectedBoxTarjeta: number = 0;
  showTarjeta: boolean = false;
  euroAmountTarjeta = 0;
  formBuyTransferencia: FormGroup = this.createFormBuyTransferencia();
  priceTokenBox1 = 500
  priceTokenBox2 = 2000
  priceTokenBox3 = 5000
  priceTokenBox4 = 10000
  transformDecimalsBox1: any
  transformDecimalsBox2: any
  transformDecimalsBox3: any
  transformDecimalsBox4: any
  numberOfTokens: any
  numberOfTokens2: any
  numberOfTokens3: any
  numberOfTokens4: any
  opcionSeleccionada!: string;
  rentabilidadTarjeta: any
  rentabilidadTransferencia: any
  rentabilidadBlockchain: any
  importe!: string;
  tarjeta!: string;
  caducidad!: string;
  cvv!: string;
  response: any;
  euroAmountTransferenciaConverted!: number;
  euroShowTransferencia = '0'
  euroShowTarjeta = '0'
  euroShowBlockchain = '0'
  datos: any = {};
  formTarjeta!: FormGroup;
  nameUser: string = '';
  Ds_MerchantParameters!: string;
  Ds_Signature!: string;
  Ds_SignatureVersion: string = 'HMAC_SHA256_V1';
  invertirTarjeta: boolean = false;
  hideBlockchain: boolean = true;
  acreditado: any;
  modalReference: any;
  walletSeleted: string = '';
  percentageSold: string = '0.00';
  percentageSoldNumber: number = 0;
  percentageToPrivateSale: number = 0;
  userWallets: string[] = [];
  loading: boolean = true;
  addressUser: string | null = null;
  addressUserTruncate: string | null = null;
  

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private afs: AngularFirestore, 
    private connSrv: ConnectionsService, 
    private kycSrv: KycService, 
    private crf: ChangeDetectorRef, 
    private paymentService: PaymentService, 
    private fns: AngularFireFunctions,
    private modalKycSrv: ModalKycSrv,
    private renderer: Renderer2,
    private authSrv: AuthService, 
    private location: Location,
    private loaderService: LoaderService,
    private dialog: MatDialog,
  ) { 

    this.web3 = this.connSrv.web3Instance;
    
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(async params => {
      this.projectId = params.get('id');
      if (!this.projectId) {
        // Redirigir a la ruta anterior si el ID no existe
        this.router.navigate(['/oportunidades']);
      } else {
        this.userInversor = this.authSrv.userAuth.getValue().uid;
        this.getProjectInfo(this.projectId);
        // Aquí puedes realizar acciones basadas en el ID de la oportunidad
      }
    });

    this.connSrv.addressUser.subscribe((res: string) => {
      this.ourAddress = res;
      if (this.ourAddress === undefined || this.ourAddress === null || this.ourAddress === '') {
        this.amountBuy?.disable();
      } else {
        this.amountBuy?.enable();
      }
    });

    this.opcionSeleccionada = 'anual';
  }


  getProjectInfo(id: string) {
    this.loading = true;
    this.loaderService.showSpinner('Cargando datos de la inversión');

    this.afs.collection('investments').doc(id).valueChanges().subscribe(async(doc: any) => {

       const firstRequest = this.info === null;
      if (doc && doc.hide === false ) {
        const res = doc;
        this.info = res;

        if(firstRequest) this.initialize();
        await this.setSellerAddress(this.info.sellerAddress);

        this.eurosSold = this.info.amountSold * (this.info.priceToken / 100);
        this.calculatePercentageSold();
        this.percentageToPrivateSale = this.info.percentageToPrivateSale ?? 0;
        this.loading = false;
        this.loaderService.hideSpinner();
      } else {
        this.loading = false;
        this.loaderService.hideSpinner();
        Swal.fire('Error', 'La oportunidad de inversión no existe o no está disponible', 'error');
        this.router.navigate(['/oportunidades']);
      }
    });   
  }

  initialize(){
    //this.setSellerAddress(this.info.sellerAddress);

    this.getWalletsUser();

    this.calcularRentabilidadTransferencia();
    this.calcularRentabilidadBlockchain();

    this.numberOfTokens = this.priceTokenBox1 / (this.info.priceToken / 100);
    this.numberOfTokens2 = this.priceTokenBox2 / (this.info.priceToken / 100);
    this.numberOfTokens3 = this.priceTokenBox3 / (this.info.priceToken / 100);
    this.numberOfTokens4 = this.priceTokenBox4 / (this.info.priceToken / 100);

    this.transformDecimalsBox1 = this.formatearNumeroConPunto(this.priceTokenBox1)
    this.transformDecimalsBox2 = this.formatearNumeroConPunto(this.priceTokenBox2)
    this.transformDecimalsBox3 = this.formatearNumeroConPunto(this.priceTokenBox3)
    this.transformDecimalsBox4 = this.formatearNumeroConPunto(this.priceTokenBox4)

    this.formTarjeta = new FormGroup({
      username: new FormControl('', Validators.required),
      pan: new FormControl('', Validators.required),
      month: new FormControl('', [Validators.required, Validators.min(1), Validators.max(12)]),
      year: new FormControl('', [Validators.required, Validators.min(0), Validators.max(99)]),
      cvv: new FormControl('', [Validators.required, Validators.pattern('[0-9]{3}')])
    });

    setInterval(() => {
      this.nowTimeStamp = Math.floor(Date.now() / 1000);
      this.crf.detectChanges();
    }, 3000);

    this.amountBuy?.valueChanges.pipe(debounceTime(200)).subscribe(async () => {
      if (this.addressSellerSelected === undefined) return;

      if (!this.web3 || !this.web3.eth) {
        console.error('Web3 no está inicializado correctamente');
        return;
      }

      const contractSeller: any = new this.web3.eth.Contract(this.abiSeller.abi, this.addressSellerSelected);
      const data = await contractSeller.methods.stableCoin().call();
      const symbol = data[2];

      if (this.amountBuy?.value === 0) {
        this.placeholderStablecoin = '0.00 ' + symbol + ' (0.00 EUR)';
      } else if (this.amountBuy?.value !== null) {
        const amount = this.formatToWei(this.amountBuy?.getRawValue());
        const data = await contractSeller.methods.priceAmountToken(amount).call();
        let valueETHFormatedEur = this.formatEtherTo2Decimals(String(data[0] / 100));
        let valueETHFormatedStableCoin = this.formatToEther(data[1]);
        this.euroShowBlockchain = this.formatearNumeroConPunto(Number(valueETHFormatedEur).toString())
        this.euroAmount = Number(valueETHFormatedEur);
        this.daiAmount = Number(valueETHFormatedStableCoin);
        this.placeholderStablecoin = valueETHFormatedStableCoin + ' '
          + symbol + ' (' + new Intl.NumberFormat('de-DE').format(Number(valueETHFormatedEur)) + ' EUR)';
        this.calcularRentabilidadBlockchain()
      }
     });

      this.amountBuyTransferencia?.valueChanges.pipe(debounceTime(200)).subscribe(async () => {

      if (this.amountBuyTransferencia?.value !== null) {
        const data = this.convertToAmount(this.amountBuyTransferencia?.getRawValue(), 100);
        const priceToken = data * this.info.priceToken / 100;
        let valueETHFormatedEur = this.formatEtherTo2Decimals(String(priceToken / 100));
        this.euroShowTransferencia = this.formatearNumeroConPunto(Number(valueETHFormatedEur).toString())
        this.euroAmountTransferencia = Number(valueETHFormatedEur);
        console.log(this.euroAmountTransferencia)
        console.log(this.euroShowTransferencia)
        this.calcularRentabilidadTransferencia()
      }
      });

      this.amountBuyTarjeta?.valueChanges.pipe(debounceTime(200)).subscribe(async () => {

      if (this.amountBuyTarjeta?.value !== null) {
        const data = this.convertToAmount(this.amountBuyTarjeta?.getRawValue(), 100);
        const priceToken = data * this.info.priceToken / 100;
        let valueETHFormatedEur = this.formatEtherTo2Decimals(String(priceToken / 100));
        this.euroShowTarjeta = this.formatearNumeroConPunto(Number(valueETHFormatedEur).toString())
        this.euroAmountTarjeta = Number(valueETHFormatedEur);
        this.calcularRentabilidadTarjeta()
      }
      });
  
    this.authSrv.userAuth.subscribe((res: any) => {
      const userAuth = res;
      if (userAuth && userAuth.uid) {
        this.afs.collection('users').doc(userAuth.uid).valueChanges().subscribe((res: any) => {
          (res !== undefined && res.displayName !== undefined) ? this.nameUser = res.displayName : this.nameUser = 'Test';
          if (res !== undefined || res != null)this.hideBlockchain = res.hideBlockchain
          if (this.hideBlockchain === false) {
            this.connSrv.addressUser.subscribe((res: any) => {
              (res !== '') ? this.addressUser = res :  this.addressUser = null;
        
              if (this.addressUser !== null) {
                this.addressUserTruncate = truncateEthAddress(this.addressUser);
              } else {
                this.addressUserTruncate = null;
              }
        
              this.crf.detectChanges();
            })
          }
        });
      }
    });

    this.kycSrv.kycSuccess.subscribe((res: any) => {
      this.acreditado = res;
      this.crf.detectChanges();
    });
  }

  calculatePercentageSold() {
    if (this.info.amountToSell && this.info.priceToken) {
      const result = (this.eurosSold * 100) / (this.info.amountToSell * (this.info.priceToken / 100));
      this.percentageSoldNumber = result;
      this.percentageSold = result.toFixed(2);
    }
  }

  setAmountBuy() {
    this.amountBuy?.patchValue(0);
    this.amountBuyTransferencia?.patchValue(0);
    this.amountBuyTarjeta?.patchValue(0);
  }

  checkMinimum(num: number) {
    return this.formatToWei(this.amountBuy?.value) >= this.formatToWei(num);
  }

  formatToWei(num: number) {
    return this.web3.utils.toWei(num.toString(), 'ether');
  }

  formatToEther(num: number) {
    const BN = this.web3.utils.BN;

    try {
      return this.web3.utils.fromWei(new BN(num).toString(), 'ether');
    } catch (error) {
      return this.web3.utils.fromWei(num.toString(), 'ether');
    }
  }

  formatEtherTo2Decimals(num: any) {
    (!num.includes('.')) ? num += '.00' : null;
    num = num.split('.');
    if (num[1].length < 2) {
      const _length = 2 - num[1].length;
      for (let i = 0; i < _length; i++) {
        num[1] += '0';
      }
    } else {
      num[1] = num[1].substring(0, 2);
    }
    return num[0] + '.' + num[1];
  }

  transformDecimals(num: number) {
    const numberFormated = new Intl.NumberFormat('de-DE').format(num);
    return numberFormated;
  }

  getUrlMainImage() {
    if (this.info && this.info.mainImage) {
      return `url('${this.info.mainImage}')`;
    } else {
      // Manejar el caso cuando this.info o this.info.mainImage es null o undefined
      return 'url(https://www.viewhotels.jp/asakusa-annex/wp-content/uploads/sites/6/2020/03/test-img.jpg)'; // Reemplaza 'default-image-url' con una URL de imagen por defecto
    }
  }

  toFloor(num: number) {
    if (num === undefined || num === null || num === 0) {
      return 'No activo';
    }

    const secondsDiff = num - this.nowTimeStamp;
    const minutesDiff = secondsDiff / 60;
    const hoursDiff = minutesDiff / 60;
    const daysDiff = hoursDiff / 24;

    if (daysDiff >= 1) {
      return 'Quedan: ' + Math.floor(daysDiff) + ' días';
    }

    if (hoursDiff >= 1) {
      return 'Quedan: ' + Math.floor(hoursDiff) + ' horas';
    }

    if (minutesDiff >= 1) {
      return 'Quedan: ' + Math.floor(minutesDiff) + ' minutos';
    }

    if (secondsDiff >= 1) {
      return 'Quedan: ' + Math.floor(secondsDiff) + ' segundos';
    }

    return 'Tiempo agotado';
  }

  async setSellerAddress(sellerContrat: string) {
    if (this.info?.onlyInversors && !this.userInversor) return;
    this.addressSellerSelected = sellerContrat;

    const contractSeller: any = new this.web3.eth.Contract(this.abiSeller.abi, this.addressSellerSelected);
    const data = await contractSeller.methods.stableCoin().call();
    const symbol = data[2];

    this.nameSymbolTokenToPay = data[1] + ' (' + data[2] + ')';
    this.placeholderStablecoin = '0.00 ' + symbol + ' (0.00 EUR)';

    const minimumAmount = await contractSeller.methods.minAmountToBuy().call();
    this.minimumAmount = this.formatToEther(minimumAmount);
    this.amountBuy?.addValidators([Validators.min(this.minimumAmount)]);

    const maximumAmount = await contractSeller.methods.balanceSeller().call();
    this.maximumAmount = this.formatToEther(maximumAmount);
    this.amountBuy?.addValidators([Validators.max(this.maximumAmount)]);
  }

  invertirModal(id: string) {
    const closeBtnModal = document.getElementById('modalClose' + id) as HTMLElement;
    closeBtnModal.click();

    const openBtnModal = document.getElementById('modalOpen' + id) as HTMLElement;
    openBtnModal.click();
  }

  goPolyscan(sellerAddress: string) {
    window.open(`https://polygonscan.com/address/${sellerAddress}`, '_blank');
  }

  clean() {
    const modalsBtnClose: any = document.getElementsByClassName('modal-backdrop');
    for (let i = 0; i < modalsBtnClose.length; i++) {
      const element = modalsBtnClose[i] as HTMLElement;
      element.style.display = 'none';
    }
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  createFormBuy() {
    return new FormGroup({
      amountBuy: new FormControl('', [Validators.required])
    })
  }

  createFormBuyTransferencia() {
    return new FormGroup({
      amountBuyTransferencia: new FormControl('')
    })
  }

  createFormBuyTarjeta() {
    return new FormGroup({
      amountBuyTarjeta: new FormControl('')
    })
  }

  get amountBuy() { return this.formBuy?.get('amountBuy'); }

  get amountBuyTransferencia() { return this.formBuyTransferencia?.get('amountBuyTransferencia'); }

  get amountBuyTarjeta() { return this.formBuyTarjeta?.get('amountBuyTarjeta'); }

  okToLegal(e: any) {
    this.checkedOkLegal = e.target.checked;
  }

  okToLegalTransferencia(e: any) {
    this.checkedOkLegalTransferencia = e.target.checked;
  }

  okToLegalTarjeta(e: any) {
    this.checkedOkLegalTarjeta = e.target.checked;
  }


  kycNoCompleted(){
    Swal.fire('Error', 'Debes completar la verificacion de tu cuenta para realizar esta operación.', 'error');
    this.router.navigate( ['/perfil'] );
    this.modalKycSrv.openModalKyc();
  }

  async buy(addressSeller: string, addressStableCoin: string) {

    if(!this.acreditado){
      this.kycNoCompleted();
      return;
    } 

    if (this.info?.onlyInversors && !this.userInversor && (this.percentageSoldNumber < this.percentageToPrivateSale) && (this.percentageToPrivateSale > 0)) {
      Swal.fire('Venta privada', 'Esta oportunidad se encuentra en venta privada, espera que esta termine y el equipo domoblock la haga publica', 'info');
      return;
    }
    if(this.info?.onlyInversors && this.percentageSoldNumber >= this.percentageToPrivateSale && this.percentageToPrivateSale > 0){
      Swal.fire('Venta privada finalizada', 'La venta privada está cerrada. Para poder invertir deberás esperar a la apertura de la venta pública', 'info');
      return;
    }

    const amountBuy = this.amountBuy?.value || this.selectedBox


    if (this.verificarCantidadTokensDisponibles(amountBuy, this.maximumAmount)) {
      const success_kyc = await this.kycSrv.kycSuccess.getValue();

      if (this.ourAddress === undefined) {
        Swal.fire({
          icon: 'error',
          title: 'Sin wallet',
          text: 'Es necesario conectar una wallet'
        });

        return;
      }

      if (
        success_kyc === undefined ||
        success_kyc === null ||
        success_kyc === false
      ) {
        Swal.fire({
          icon: 'error',
          title: 'KYC incorrecto',
          text: 'Es necesario completar el KYC'
        });

        return;
      }

      const uid = await this.authSrv.userAuth.getValue().uid;
      await this.afs.collection('users').doc(uid).get().subscribe(async (_res: any) => {
        if (_res.exists) {
          const res = _res.data();

          if (res.walletsWhitelist === undefined || res.walletsWhitelist === null || res.walletsWhitelist.lenght === 0) {
            Swal.fire({
              icon: 'error',
              title: 'Sin wallet registrada',
              text: 'Accede a tu perfil y añade una wallet'
            });

            return;
          }
          if(!(res?.particular && res?.particular?.pais_de_residencia) && (!res?.Empresa?.Representante?.pais_de_residencia)){
            Swal.fire({
              icon: 'error',
              title: 'Error Irregular',
              text: 'Su usuario presenta un error en la verificación, por favor póngase en contacto con soporte en info@domoblock.io o llamando al número 690 84 26 48.'
            });

            return;
          }

          let flagWallet: boolean = false;
          res.walletsWhitelist.forEach((wallet: string) => {
            (this.web3.utils.toChecksumAddress(wallet) === this.web3.utils.toChecksumAddress(this.ourAddress)) ? flagWallet = true : null;
          });

          if (!flagWallet) {
            Swal.fire({
              icon: 'error',
              title: 'Sin coincidencia de wallet',
              text: 'Debes seleccionar una wallet en tu MetaMask que coincida con alguna incluida en tu perfil'
            });

            return;
          }

          Swal.fire({
            title: 'Procesando...',
            html: 'No cierres esta pantalla!!',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            }
          });

          const contractStableCoin: any = new this.web3.eth.Contract(this.abiStableCoin.abi, addressStableCoin);
          const contractSeller: any = new this.web3.eth.Contract(this.abiSeller.abi, addressSeller);

          let amountTokensSelect;
          if (this.selectedBox > 0 && this.showCrypto === false) {
            amountTokensSelect = this.formatToWei(this.selectedBox);
          } else {
            amountTokensSelect = this.formatToWei(this.amountBuy?.getRawValue());
          }
          const amountTokens: number = amountTokensSelect;

          const data = await contractSeller.methods.priceAmountToken(amountTokens).call();
          const amountToPay = data[1];

          const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => {
            return Number(block.baseFeePerGas) + 100000000000;
          });
       
          await contractStableCoin.methods.approve(addressSeller, amountToPay).send({ from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei')) })
            .then(async () => {
              await contractSeller.methods.buy(amountTokens, this.ourAddress).send({ from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei')) })
                .then(async () => {
                  Swal.close();
                  Swal.fire({
                    icon: 'success',
                    text: `Inversión realizada con éxito`
                  }).then(() => {
                    this.crf.detectChanges();
                    this.clean();
                  });

                  this.formBuy.reset();
                })
                .catch(() => {
                  Swal.close();
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo ha ido mal!'
                  });
                }, { timeout: 300000 });
            })
            .catch(() => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Algo ha ido mal!'
              });
            }, { timeout: 300000 });

        }
      });
    }

  }

  formatearNumeroConPunto(numero: any) {
    return new Intl.NumberFormat("es-MX", { useGrouping: true }).format(numero).replace(/,/g, '.');
  }

 

  async selectBox(boxNumber: number) {
    if (this.selectedBox === boxNumber) {
      this.selectedBox = 0; // Desactivar la selección
    } else {
      this.selectedBox = boxNumber;
      this.showCrypto = false
    }
    const contractSeller: any = new this.web3.eth.Contract(this.abiSeller.abi, this.addressSellerSelected);
    const amount = this.formatToWei(this.selectedBox);
    const data = await contractSeller.methods.priceAmountToken(amount).call();
    let valueETHFormatedEur = this.formatEtherTo2Decimals(String(data[0] / 100));
    let valueETHFormatedStableCoin = await this.formatToEther(data[1]);
    this.euroShowBlockchain = this.formatearNumeroConPunto(Number(valueETHFormatedEur).toString())
    this.euroAmount = await Number(valueETHFormatedEur);
    this.daiAmount = await Number(valueETHFormatedStableCoin);
    this.calcularRentabilidadBlockchain()
  }

  convertToAmount(number: any, factor: any) {
    return number * factor;
  }

  showInputTransferencia() {
    this.showTransferencia = true;
    this.selectedBoxTransferencia = 0;
    this.euroShowTransferencia = '0'
    this.rentabilidadTransferencia = '0'
    this.euroAmountTransferencia = 0;
    this.formBuyTransferencia.get('amountBuyTransferencia')?.setValue('');
  }

  showInputTarjeta() {
    this.showTarjeta = true;
    this.selectedBoxTarjeta = 0
    this.euroShowTarjeta = '0'
    this.rentabilidadTarjeta = '0'
  }

  showInput() {
    this.showCrypto = true;
    this.selectedBox = 0
    this.euroShowBlockchain = '0'
    this.rentabilidadBlockchain = '0'

    if(this.addressUser === null && this.acreditado && !this.hideBlockchain){
      Swal.fire({
        icon: 'error',
        title: 'Wallet no conectada',
        text: 'Es necesario conectar una wallet para completar esta acción'
      });
      this.showCrypto = false;
      return;
    }
  }

  closeAllInputs(){
    this.showTransferencia = false;
    this.showTarjeta = false;
    this.showCrypto = false;
  }

  selectBoxTransferencia(boxNumber: number) {
    if (this.info?.onlyInversors && !this.userInversor && (this.percentageSoldNumber < this.percentageToPrivateSale) && (this.percentageToPrivateSale > 0)) {
      Swal.fire('Venta privada', 'Esta oportunidad se encuentra en venta privada, espera que esta termine y el equipo domoblock la haga publica', 'info');
      return;
    }
    if(this.info?.onlyInversors && this.percentageSoldNumber >= this.percentageToPrivateSale && this.percentageToPrivateSale > 0){
      Swal.fire('Venta privada finalizada', 'La venta privada está cerrada. Para poder invertir deberás esperar a la apertura de la venta pública', 'info');
      return;
    }

    if (this.selectedBoxTransferencia === boxNumber) {
      this.selectedBoxTransferencia = 0; // Desactivar la selección
    } else {
      this.selectedBoxTransferencia = boxNumber;
      this.showTransferencia = false
    }
    const data = this.convertToAmount(this.selectedBoxTransferencia, 100);
    const priceToken = data * this.info.priceToken / 100;
    let valueETHFormatedEur = this.formatEtherTo2Decimals(String(priceToken / 100));
    this.euroShowTransferencia = this.formatearNumeroConPunto(Number(valueETHFormatedEur).toString())
    this.euroAmountTransferencia = Number(valueETHFormatedEur);
    this.calcularRentabilidadTransferencia()
  }

  async selectBoxTarjeta(boxNumber: number) {
    if (this.selectedBoxTarjeta === boxNumber) {
      this.selectedBoxTarjeta = 0; // Desactivar la selección
    } else {
      this.selectedBoxTarjeta = boxNumber;
      this.showTarjeta = false
    }
    const data = this.convertToAmount(this.selectedBoxTarjeta, 100);
    const priceToken = data * this.info.priceToken / 100;
    let valueETHFormatedEur = this.formatEtherTo2Decimals(String(priceToken / 100));
    this.euroShowTarjeta = this.formatearNumeroConPunto(Number(valueETHFormatedEur).toString())
    this.euroAmountTarjeta = Number(valueETHFormatedEur);
    this.calcularRentabilidadTarjeta()
  }

  isInvalidCheckedOkLegalTransferencia() {
    return !this.checkedOkLegalTransferencia && (this.info.urlInfoTokenPdf !== undefined && this.info.urlInfoTokenPdf !== null);
  }

  isInvalidCheckedOkLegal() {
    return !this.checkedOkLegal && (this.info.urlInfoTokenPdf !== undefined && this.info.urlInfoTokenPdf !== null);
  }

  isInvalidCheckedOkLegalTarjeta() {
    return !this.checkedOkLegalTarjeta && (this.info.urlInfoTokenPdf !== undefined && this.info.urlInfoTokenPdf !== null);
  }

  calcularRentabilidadTransferencia() {
    if (this.opcionSeleccionada === 'anual') {
      const rentabilidadAnualInfo = this.info.annualReturn / 100
      const cantidadInversion = this.euroAmountTransferencia
      const rentabilidadAnual = cantidadInversion * (1 + rentabilidadAnualInfo);
      this.rentabilidadTransferencia = this.formatearNumeroConPunto(rentabilidadAnual.toFixed(0)) + '€'
    } else if (this.opcionSeleccionada === 'mensual') {
      const rentabilidadAnual = this.info.annualReturn / 100;
      const estimatedDeliveryTime = this.info.estimatedDeliveryTime;
      const numberPattern = /\d+/;
      const number = parseInt(estimatedDeliveryTime.match(numberPattern)[0], 10);
      const duracionProyecto = number
      const inversion = this.euroAmountTransferencia
      const rentabilidadEnMeses = ((Math.pow((1 + rentabilidadAnual), (duracionProyecto / 12)) - 1) * inversion) + inversion;
      this.rentabilidadTransferencia = this.formatearNumeroConPunto(rentabilidadEnMeses.toFixed(0)) + '€'
    }
  }

  calcularRentabilidadBlockchain() {
    if (this.opcionSeleccionada === 'anual') {
      const rentabilidadAnualInfo = this.info.annualReturn / 100
      const cantidadInversion = this.euroAmount
      const rentabilidadAnual = cantidadInversion * (1 + rentabilidadAnualInfo);
      this.rentabilidadBlockchain = this.formatearNumeroConPunto(rentabilidadAnual.toFixed(0)) + '€'
    } else if (this.opcionSeleccionada === 'mensual') {
      const rentabilidadAnual = this.info.annualReturn / 100;
      const estimatedDeliveryTime = this.info.estimatedDeliveryTime;
      const numberPattern = /\d+/;
      const number = parseInt(estimatedDeliveryTime.match(numberPattern)[0], 10);
      const duracionProyecto = number
      const inversion = this.euroAmount
      const rentabilidadEnMeses = ((Math.pow((1 + rentabilidadAnual), (duracionProyecto / 12)) - 1) * inversion) + inversion;
      this.rentabilidadBlockchain = this.formatearNumeroConPunto(rentabilidadEnMeses.toFixed(0)) + '€'
    }
  }

  calcularRentabilidadTarjeta() {
    if (this.opcionSeleccionada === 'anual') {
      const rentabilidadAnualInfo = this.info.annualReturn / 100
      const cantidadInversion = this.euroAmountTarjeta
      const rentabilidadAnual = cantidadInversion * (1 + rentabilidadAnualInfo);
      this.rentabilidadTarjeta = this.formatearNumeroConPunto(rentabilidadAnual.toFixed(0)) + '€'
    } else if (this.opcionSeleccionada === 'mensual') {
      const rentabilidadAnual = this.info.annualReturn / 100;
      const estimatedDeliveryTime = this.info.estimatedDeliveryTime;
      const numberPattern = /\d+/;
      const number = parseInt(estimatedDeliveryTime.match(numberPattern)[0], 10);
      const duracionProyecto = number
      const inversion = this.euroAmountTarjeta
      const rentabilidadEnMeses = ((Math.pow((1 + rentabilidadAnual), (duracionProyecto / 12)) - 1) * inversion) + inversion;
      this.rentabilidadTarjeta = this.formatearNumeroConPunto(rentabilidadEnMeses.toFixed(0)) + '€'
    }
  }


  async openModal(id: string) {

    Swal.fire({
      title: 'Proximamente...',
      text: 'La opción de pago por tarjeta de credito estará disponible proximamente.',
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Cerrar',
    }).then((result) => {
      return;
    });

    if(!this.acreditado){
      this.kycNoCompleted();
      return;
    }


    // await this.obtenerPagoTarjeta()
    // const amountBuy = this.amountBuyTarjeta?.value || this.selectedBoxTarjeta;
    // const maximumAmount = this.maximumAmount;

    // if (this.verificarCantidadTokensDisponibles(amountBuy, maximumAmount)) {
    //   const modalId = this.dinamicModalCardId(id);
    //   const modal = document.getElementById(modalId);
    //   if (modal) {
    //     modal.style.display = 'block';
    //   }
    // }
  }




  onSubmitTarjeta() {

    // Validación del nombre
    if (!this.formTarjeta.value.username) {
      Swal.fire('Error', 'Por favor, ingresa un nombre válido', 'error');
      return;
    }


    if (!this.formTarjeta.value.pan) {
      Swal.fire('Error', 'Por favor, ingresa un número de tarjeta válido', 'error');
      return;
    }

    // Validación del número de tarjeta
    const cardNumber = this.formTarjeta.value.pan.replace(/\s/g, ''); // Elimina los espacios en blanco
    if (!this.isValidCardNumber(cardNumber)) {
      Swal.fire('Error', 'Por favor, ingresa un número de tarjeta válido', 'error');
      return;
    }

    // Validación de la fecha de expiración
    const month = this.formTarjeta.value.month;
    const year = this.formTarjeta.value.year;

    const currentDate = new Date();
    const expirationDate = new Date(2000 + year, month - 1); // Resta 1 al mes ya que en JavaScript los meses van de 0 a 11
    console.log(currentDate)
    console.log(expirationDate)
    if (expirationDate < currentDate) {
      Swal.fire('Error', 'Por favor, ingresa una fecha de expiración válida', 'error');
      return;
    }

    // Validación del CVV
    let cvv = this.formTarjeta.value.cvv;
    if (!this.isValidCVV(cvv)) {
      Swal.fire('Error', 'Por favor, ingresa un CVV válido', 'error');
      return;
    }

    cvv.toString();


    this.datos = {
      name: this.formTarjeta.value.username,
      project: this.info.title,
      amount: this.euroAmountTarjeta.toString(),
      pan: cardNumber.toString(),
      expiry_date: year + month.toString(),
      ccv2: cvv,
      email: this.authSrv.userAuth.getValue().email,
      tokenAddress: this.info.tokensAddress,
      contractAddress: this.info.sellerAddress,
    };

    if (this.selectedBoxTarjeta > 0 && this.showTarjeta === false) {
      console.log(this.selectedBoxTarjeta)
      this.datos.quantity = this.selectedBoxTarjeta
    } else {
      this.datos.quantity = this.amountBuyTarjeta?.value
    }

    

    this.realizarPago()
  }

 

  // Función para validar el número de tarjeta utilizando el algoritmo de Luhn
  isValidCardNumber(cardNumber: string): boolean {
    let sum = 0;
    let shouldDouble = false;

    for (let i = cardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cardNumber.charAt(i), 10);

      if (shouldDouble) {
        if ((digit *= 2) > 9) {
          digit -= 9;
        }
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
  }

  // Función para validar el CVV (3 dígitos) utilizando una expresión regular
  isValidCVV(cvv: string): boolean {
    const cvvRegex = /^[0-9]{3}$/;
    return cvvRegex.test(cvv);
  }

  public isButtonDisabledTarjeta(): boolean {
    return this.condicionesTarjetaInput()

  }

  condicionesTarjetaBox() {
    return (this.selectedBoxTarjeta > 0 ? false : true) || (!this.checkedOkLegalTarjeta && (this.info.urlInfoTokenPdf !== undefined && this.info.urlInfoTokenPdf !== null))
  }

  condicionesTarjetaInput() {
    return (this.euroAmountTarjeta >= 500 ? false : true) || (!this.checkedOkLegalTarjeta && (this.info.urlInfoTokenPdf !== undefined && this.info.urlInfoTokenPdf !== null))
  }


  public isButtonDisabledTransferencia(): boolean {
    return this.condicionesTransferenciaInput()

  }

  condicionesTransferenciaBox() {
    return (this.selectedBoxTransferencia > 0 ? false : true) || (!this.checkedOkLegalTransferencia && (this.info.urlInfoTokenPdf !== undefined && this.info.urlInfoTokenPdf !== null))
  }

  condicionesTransferenciaInput() {
    return (this.euroAmountTransferencia > 0 ? false : true) || (!this.checkedOkLegalTransferencia && (this.info.urlInfoTokenPdf !== undefined && this.info.urlInfoTokenPdf !== null)) 
    //return (this.euroAmountTransferencia > 0 ? false : true) || (!this.checkedOkLegalTransferencia && (this.info.urlInfoTokenPdf !== undefined && this.info.urlInfoTokenPdf !== null)) 
  }

  public isButtonDisabledBlockchain(): boolean {
    return this.condicionesBlockchainInput()

  }

  condicionesBlockchainBox() {
    return (this.selectedBox > 0 ? false : true) || (!this.checkedOkLegal && (this.info.urlInfoTokenPdf !== undefined && this.info.urlInfoTokenPdf !== null))
  }

  condicionesBlockchainInput() {
    return (this.euroAmount > 0 ? false : true) || (!this.checkedOkLegal && (this.info.urlInfoTokenPdf !== undefined && this.info.urlInfoTokenPdf !== null))
  }

  verificarCantidadTokensDisponibles(amountBuy: number, maximumAmount: number): boolean {
    const tokensDisponibles: number = maximumAmount; // Obtener la cantidad de tokens disponibles en tu proyecto
    
    
    if (amountBuy > tokensDisponibles) {
      Swal.fire('Error', 'No hay suficientes tokens disponibles.', 'error');
      return false;
    } 

    
    if (amountBuy > maximumAmount) {
      Swal.fire('Error', 'El monto excede el límite permitido.', 'error');
      return false;
    } 
 
    return true;
  }

closeModalInvest() {
  const modalElement = this.renderer.selectRootElement(`#modalDetail2${this.info.id}`);
  modalElement.style.display = 'none';
  document.body.classList.remove('modal-open');
  const modalBackdrops = document.getElementsByClassName('modal-backdrop');
  if (modalBackdrops.length > 0) {
    document.body.removeChild(modalBackdrops[0]);
  }
}

async realizarPago() {
  (this.paymentService.enviarDatos(this.datos))
    .subscribe(
      async response => {
        console.log(response);
        Swal.fire({
          icon: 'success',
          title: 'Compra exitosa',
          html: `<p><strong>¡Felicidades, la transacción se ha completado con éxito!</strong></p><p><em>Tu inversión quedará registrada entre las próximas 24h.</em></p>`
        });
        this.closeModal(this.info.id);

        delete this.datos.ccv2

        const uid = await this.authSrv.userAuth.getValue().uid;
        this.afs.collection('tarjetas-compras').add(this.datos)
          .then((docRef) => {
            console.log('Datos de compra guardados en Firestore con ID:', docRef.id);
            // Resto del código después de guardar los datos
          })
          .catch((error) => {
            console.error('Error al guardar los datos de compra en Firestore:', error);
          });


      },
      error => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      }
    );
}

async selectWalletModal(id: string) {
  const data = await firstValueFrom(await this.afs.collection("users-walletsByCompany").doc(this.authSrv.userAuth.getValue().uid).get())
  let userData:any;
  console.log(this.userWallets)
  if(data.exists){
    userData = data.data();
    if(this.userWallets.length > 0)
    this.userWallets = this.userWallets.map((wallet:string )=> wallet.toLowerCase() != userData.address.toLowerCase() ? wallet : 'wallet'  );
  }  
  // if (this.userWallets?.length === 1)  {
  //     this.walletSeleted = this.userWallets[0];
  //     this.invertirTransferencia()
  //     return;
  //   }   
  console.log(this.userWallets)
    if(this.userWallets?.length > 0){
      if(this.userWallets.length == 1 && this.userWallets.includes('wallet')){
       this.invertirTransferencia();
        return;
      }
      if(!this.userWallets.includes('wallet') && this.userWallets.length <3)
        this.userWallets.push('wallet');
      const modalId = this.dinamicModalWallet(id);
      const modal = document.getElementById(modalId);
      if (modal) {
       modal.style.display = 'block';
      }
      return;
    }
    this.invertirTransferencia()
}

closeModalWallet(id: string){
  const modalId = this.dinamicModalWallet(id);
  const modal = document.getElementById(modalId);
  if (modal) {
    modal.style.display = 'none';
  }
}

 // obetner ID dinamico para cada modal de pago con tarjeta
 dinamicModalWallet(id: string) {
  return `modalWallet${id}`
}

seletedWallet(){
  console.log(this.walletSeleted);
  this.closeModalWallet(this.info.id)
  this.invertirTransferencia()
}

  async invertirTransferencia() {

    if(!this.acreditado){
      this.kycNoCompleted();
      return;
    }

    if (this.info?.onlyInversors && !this.userInversor) {
      Swal.fire('Error', 'Debes ser inversor para realizar esta operación.', 'error');
      return;
    } 

    const res = await (await firstValueFrom(this.afs.collection('users').doc(this.authSrv.userAuth.getValue().uid).get())).data() as any
    if(!(res?.particular && res?.particular?.pais_de_residencia) && (!res?.Empresa?.Representante?.pais_de_residencia)){
      Swal.fire({
        icon: 'error',
        title: 'Error Irregular',
        text: 'Su usuario presenta un error en la verificación, por favor póngase en contacto con soporte en info@domoblock.io o llamando al número 690 84 26 48.'
      });

      return;
    }

    if(this.info?.onlyInversors && this.percentageSoldNumber >= this.percentageToPrivateSale && this.percentageToPrivateSale > 0){
      Swal.fire('Venta privada finalizada', 'La venta privada está cerrada. Para poder invertir deberás esperar a la apertura de la venta pública', 'info');
      return;
    }

    const encryptedData = {
      userEmail: this.authSrv.userAuth.getValue().email,
      projectName: this.info.nameTokens,
      tokenQuantity:  this.euroAmountTransferencia / (this.info.priceToken / 100),
      tokenAddress: this.info.tokensAddress,
      sellerAddress: this.info.sellerAddress,
      uid: this.authSrv.userAuth.getValue().uid,
      displayName: this.nameUser ?? '',
      wallet: (this.walletSeleted == 'wallet'  ? '' : this.walletSeleted) || ''
    }

    const encryptedJson = Buffer.from(JSON.stringify(encryptedData)).toString('base64');

    try {
      const deserializedJson = Buffer.from(encryptedJson, 'base64').toString('utf-8');
      const deserializedData = JSON.parse(deserializedJson);
      if (JSON.stringify(deserializedData) !== JSON.stringify(encryptedData)) {
        Swal.fire('Error', 'Ha ocurrido un error al procesar la solicitud', 'error');
        return;
      }
    } catch{
      Swal.fire('Error', 'Ha ocurrido un error al procesar la solicitud', 'error');
      return;
    }

    const transferInformation = {
      description: this.info.nameTokens,
      amount: this.euroAmountTransferencia * 100,
      reference: new Date().getTime().toString(),
      notifUrl: "https://sendmail.domoblock.io/bank/confirm-payment",
      customData: encryptedJson
    } 

    if (encryptedData.tokenQuantity > this.maximumAmount) {
      Swal.fire('Error', 'No hay suficientes tokens disponibles.', 'error');
      return;
    }
    
    if (this.euroAmountTransferencia < 500 && this.maximumAmount > 5) {
      Swal.fire('Error', 'La cantidad mínima de compra por tranferencia bancaria es de 500€', 'error');
      return;
    } 

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    this.paymentService.transferenciaBancaria(transferInformation).subscribe((res: any) => { 
      Swal.hideLoading()	
      if (res.singlePayinLink) {
        //window.open(res.singlePayinLink, '_blank')
        Swal.fire({
          icon: 'success',
          title: 'Enlace generado con éxito',
          text: 'Haz click en el botón de abajo para ir a la pasarela de pago',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCancelButton: false,
          confirmButtonText: 'Ir a la pasarela de pago'
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(res.singlePayinLink);
          }
        });
      }
      else Swal.fire('Error', 'Ha ocurrido un error al procesar la solicitud', 'error');
    }) 

  }


  // obetner ID dinamico para cada modal de pago con tarjeta
  dinamicModalCardId(id: string) {
    return `modalTarjeta${id}`
  }

  closeModal(id: string) {
    this.invertirTarjeta = false;
    const modalId = this.dinamicModalCardId(id);
    const modal = document.getElementById(modalId);
    if (modal) {
      modal.style.display = 'none';
    }
  }

  //obetner valores para generar el enlace de pago con tarjeta de credito
  obtenerPagoTarjeta() {

    this.datos = {
      name: this.nameUser,
      project: this.info.title,
      email: this.authSrv.userAuth.getValue().email,
      amount: (this.euroAmountTarjeta * 100).toString(),
      quantity: this.euroAmountTarjeta / (this.info.priceToken / 100),
      contractAddress: this.info.sellerAddress,
      tokenAddress: this.info.tokensAddress,
    }

    this.paymentService.enviarDatos(this.datos).subscribe((res: any) => {
      if (res.body) {
        this.invertirTarjeta = true; 
        const response = res.body
        this.Ds_MerchantParameters = response.Ds_MerchantParameters
        this.Ds_Signature = response.Ds_Signature
      }
      else Swal.fire('Error', 'Ha ocurrido un error al procesar la solicitud', 'error');
    }) 
  }

  @ViewChild('paymentCardForm') paymentCardForm!: ElementRef;

  //generar enlace de pago para tarjeta de credito
  pagoTarjeta() {
      this.paymentCardForm.nativeElement.submit();
      this.closeModal(this.info.id);
      Swal.fire('Éxito', 'Se ha generado el enlace de pago con exito!', 'info');
      this.invertirTarjeta = false;
  } 

  // Método para ir a la ruta anterior
  goBack() {
    this.location.back();
  }

  async getWalletsUser() {
    const uid = await this.authSrv.userAuth.getValue().uid;
    const result = await this.afs.collection('users').doc(uid).get();
    const userData: any  = (await firstValueFrom(result)).data();
    this.userWallets =  userData.walletsWhitelist ?? [];
  }

  openFullScreenModal() {
    const dialogRef = this.dialog.open(DetailRealEstateComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'custom-full-screen-modal',
      data: {
        info: this.info,
        cantInvest: (!this.logged || !this.info.blockchain || this.info?.proyectStatus != 'Activo' || this.eurosSold >= this.info.amountToSell * (this.info.priceToken / 100) || this.toFloor(this.info.endOfSale) == 'Tiempo agotado')
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('El modal se ha cerrado');
    });
  }

  async investDigitalWallet(){

    const paymentData = {
      userId :this.authSrv.userAuth.getValue().uid,
      userWallet:'',
      projectId: this.info.id,
      totalAmount: this.euroAmountTransferencia * 100,
      totalTokens: this.amountBuyTransferencia?.value ?? 0,
  
    }
  
    console.log(paymentData)
  
    // this.loaderService.showSpinner('Procesando pago...');
    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    this.paymentService.investDigitalWallet(paymentData).subscribe(
      (res: any) => {
      console.log(res)
      Swal.hideLoading()
      Swal.fire('Enhorabuena!','Pago realizado con éxito','success');
    }, (error : any) => {
      console.log(error)
      Swal.hideLoading()
      Swal.fire('Error', 'Ha ocurrido un error al procesar la solicitud', 'error');
    });
  }
  

  
}
