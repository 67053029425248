<div [ngClass]="{'white-background': isWhiteBackground}" class="main-container" *ngIf="!isInvestPage">
    <div class="menu-movil-container">
        <app-menu-movil></app-menu-movil>
    </div>

    <div class="menu-desktop-container">
        <app-menu-desktop></app-menu-desktop>
    </div>

    <div [ngClass]="{'white-background': isWhiteBackground}" class="navbar-router-container">
        <app-navbar-user></app-navbar-user>
        <router-outlet></router-outlet>
    </div>  
</div>
<div *ngIf="isInvestPage">
    <router-outlet></router-outlet>
</div>
<app-spinner *ngIf="isLoading && !intialLoading" [loadingText]="loadingMessage"></app-spinner>
