import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router, Event, NavigationEnd} from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import { KycService } from 'src/app/services/kyc.service';
import truncateEthAddress from 'truncate-eth-address';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalLoginComponent } from '../modal-login/modal-login.component';
import { ModalRegisterComponent } from '../modal-register/modal-register.component';

@Component({
  selector: 'app-navbar-user',
  templateUrl: './navbar-user.component.html',
  styleUrls: ['./navbar-user.component.css']
})
export class NavbarUserComponent implements OnInit {
  path: string | null = null;
  userAuth: any = null;
  loggedIn: boolean = false;
  acreditado: boolean = false; 
  revision: boolean = false;
  addressUser: string | null = null;
  addressUserTruncate: string | null = null;
  nameUser: string = '';
  userGender: string | null = null; // Propiedad para el género del usuario
  hideBlockchain: boolean = true;

  constructor(
    private authSrv: AuthService, 
    private afsSrv: AngularFirestore,
    private connectionsWeb3: ConnectionsService, 
    private router: Router, 
    private kycSrv: KycService,
    private crf: ChangeDetectorRef,
    private modalService: NgbModal
  ) {}

  connect() {
    this.connectionsWeb3.connect();
  }

  openLoginForm() {
    console.log('Abriendo modal');
    const modalRef = this.modalService.open(ModalLoginComponent); 
    modalRef.closed.subscribe(() => {
      console.log('Modal cerrado');
    });
  }

  openRegisterForm() {
    const modalRef = this.modalService.open(ModalRegisterComponent); 
    modalRef.closed.subscribe(() => {
      console.log('Modal cerrado');
    });
  }

  ngOnInit(): void {
    this.authSrv.userAuth.subscribe(async (res: any) => {
      this.userAuth = res;

      if (this.userAuth === null || this.nameUser !== '') { return; }
      this.afsSrv.collection('users').doc(this.userAuth.uid).valueChanges().subscribe((res: any) => {
        (res !== undefined && res.displayName !== undefined) ? this.nameUser = res.displayName : this.nameUser = '';
        this.hideBlockchain = res.hideBlockchain;

        // Obtener el género del usuario si existe en Firestore
        if (res !== undefined && res.gender !== undefined) {
          this.userGender = res.gender; // Asignar género si está disponible
        } else {
          this.userGender = '@'; // Si no hay género, asignar "@"
        }

        if(this.hideBlockchain === false){
          console.log(`value: ${this.hideBlockchain}`);
          this.connectionsWeb3.addressUser.subscribe((res: any) => {
            (res !== '') ? this.addressUser = res :  this.addressUser = null;
      
            if (this.addressUser !== null) {
              this.addressUserTruncate = truncateEthAddress(this.addressUser);
            } else {
              this.addressUserTruncate = null;
            }
      
            this.crf.detectChanges();
          });
        }
      });
    });

    this.authSrv.loggedIn.subscribe((res: any) => {
      this.loggedIn = res;
    });

    this.kycSrv.kycSuccess.subscribe((res:{success:boolean, revision:boolean, aproved:boolean}) => {
      this.acreditado = res.success;
      this.revision = res.revision;
      this.crf.detectChanges();
    });

    this.router.events
      .subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.path = event.url;
          console.log(this.path)
        }
      });
  }
}
