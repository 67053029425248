<div>
  <div class="container mt-4">
    <div class="row mb-2">
      <div class="col-12">
        <select [(ngModel)]="selectedStatus" class="form-select" (change)="getWithdrawalRequests(this.selectedStatus)">
          <option value="approved">Aprobado</option>
          <option value="pending">Pendiente</option>
          <option value="denied">Rechazado</option>
        </select>
      </div>
    </div>

    <div class="row mb-2 column-tittle" style="color: white;">
      <div class="col-3 fw-bold text-muted">Email</div>
      <div class="col-2 fw-bold text-muted">Monto
        <i [class]="'bi ' + getSortIcon('amount')" class="domo-color" style="cursor: pointer;"></i> <!-- (click)="sortBy('amount')" -->
      </div>
      <div class="col-2 fw-bold text-muted">
        Fecha
        <i [class]="'bi ' + getSortIcon('date')" style="cursor: pointer;"></i> <!--(click)="sortBy('date')"-->
      </div>
      <div class="col-2 fw-bold text-muted">Estado</div>
      <div class="col-3 fw-bold text-muted">{{selectedStatus == 'pending' ? 'Accion' : 'Fecha de Actualizacion'}}</div>
    </div>

    <div class="list-group">
      <div *ngFor="let data of dataSource" class="list-group-item list-group-item-action">
        <div class="row align-items-center">
          <div class="col-3">{{data?.userEmail}}</div>
          <div class="col-2">{{data?.amount / 100 | customCurrency}}</div>
          <div class="col-2">{{transformDate(data.date.seconds)}}</div>
          <div class="col-2">{{data?.status}}</div>
          <div class="col-3 d-flex gap-3">
            <ng-container *ngIf="selectedStatus === 'approved' || selectedStatus === 'denied'; else actionButtons"> {{transformDate(data.updatedAt?.seconds || data.date.seconds)}} </ng-container>
             <ng-template #actionButtons> <button type="button" class="btn btn-success text-center" (click)="changeStatus(data.withdrawId, true)">Aprobar</button> 
              <button type="button" class="btn btn-outline-success" (click)="changeStatus(data.withdrawId, false)">Denegar</button> 
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
