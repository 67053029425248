import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-investment-method',
  templateUrl: './investment-method.component.html',
  styleUrls: ['./investment-method.component.css']
})
export class InvestmentMethodComponent implements OnInit {

  typeOfInvestment: string = '';
  bankTransferSelected = true;
  blockchainSelected = false;
  hasMetamask?: boolean;
  userId: string = '';
  constructor(private readonly afs: AngularFirestore, private readonly authService: AuthService) {
    this.authService.userAuth.subscribe((userData:any )=> {
      this.userId = userData?.uid;
      console.log(this.userId);
      this.afs.collection('users').doc(this.userId).get().subscribe((userData: any) => {
        console.log(userData?.data()?.hideBlockchain);
        this.bankTransferSelected = userData?.data()?.hideBlockchain;
        this.blockchainSelected = !userData?.data()?.hideBlockchain;
        console.log(this.bankTransferSelected);
        console.log(this.blockchainSelected);
      });
    });
   }

  ngOnInit(): void {
  }


  async selectInvestmentType(type: string = 'bankTransfer') {
    Swal.showLoading()
    this.blockchainSelected = type == 'blockchain';
    this.bankTransferSelected = type == 'bankTransfer';
  await this.afs.collection('users').doc(this.userId).set({
      hideBlockchain: type === 'bankTransfer'
    },{
      merge: true
    }).then(()=>{
      Swal.close()
      Swal.fire({
        icon:'success',
        text: `Tipo de inversion Blockchain: ${type == 'bankTransfer' ? 'Desactivado' : 'Activado'} `
      })
    }).catch(()=>{
      Swal.close()
      Swal.fire({
        icon:'error',
        text: 'Error al cambiar el tipo de inversion'
      })
    })
  }

}
