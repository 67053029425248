<div class="pagination">
    <button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1">Anterior</button>
    
    <span>Página {{ currentPage }} de {{ totalPages }}</span>
  
    <button (click)="changePage(currentPage + 1)" [disabled]="currentPage === totalPages">Siguiente</button>
  
    <select [(ngModel)]="itemsPerPage" (change)="onItemsPerPageChange()">
        <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
      </select>
</div>
