
import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalConnector implements OnInit {
    private uid: string = '';
    constructor(private afs: AngularFirestore, private authSrv: AuthService){

    }
    async ngOnInit(): Promise<void> {
        this.uid = await this.authSrv.userAuth.getValue().uid;
    }

    async saveCurrentData(data:any,step?:number,stage?:number, stepsPercentage:any = null){
        if(!this.uid)
            this.uid = await this.authSrv.userAuth.getValue().uid;
        console.log(this.uid)
        console.log(data,step,stage);
        const insert = {userdata:data,step:step,stage:stage} as any
        if(stepsPercentage)
            insert.stepsPercentage = stepsPercentage;
        await this.afs.collection('pre-registerdata').doc(this.uid).set(insert,{merge:true});
    }

    async getRegisterUserData(){
        if(!this.uid)
            this.uid = await this.authSrv.userAuth.getValue().uid;
        const data = await this.afs.collection('pre-registerdata').doc(this.uid).get();
        return await firstValueFrom(data);
    }

    getUserUid(){
        return this.uid
    }

    async getUserData(){
    const uid = await this.authSrv.userAuth.getValue().uid;
    console.log(uid); 
    const result = await this.afs.collection('users').doc(uid).get();
    const userData: any  = (await firstValueFrom(result)).data();
    return userData;
    }

    async isAuth(){
        this.authSrv.isLoggedIn().then(async (logged: boolean) => {
        return logged;
    });
    }
}