import { ChangeDetectorRef, Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectionsService } from '../../../services/connectionsWeb3/connections.service';
import Swal from 'sweetalert2';
import * as jsonManager from '../../../abis/Manager.json';
import * as jsonToken from '../../../abis/TokenHoldings.json';
import * as jsonSeller from '../../../abis/Seller.json';
import * as jsonErc20 from '../../../abis/ERC20.json';
import { environment } from '../../../../environments/environment';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-dividends',
  templateUrl: './add-dividends.component.html',
  styleUrls: ['./add-dividends.component.css']
})
export class AddDividendsComponent {
  ourAddress: string | undefined;
  web3: any | undefined;
  abiManager: any = jsonManager;
  abiToken: any = jsonToken;
  abiSeller: any = jsonSeller;
  abiErc20: any = jsonErc20;
  contractManager: any | undefined;
  formAddDividends: FormGroup = this.createFormDividends();
  formAddDividendsFiat: FormGroup = this.createFormDividendsFiat();
  retentionsTokens: number = 0;
  retentionsTokensCompany: number = 0;
  selectedForm: string = 'blockchain';
  contractAddress: string = '';
  existsProject: boolean = false;
  // imageURL:string = "https://firebasestorage.googleapis.com/v0/b/domoblock-devnew.appspot.com/o/investments%2F1705674582557%2FAjustada.jpg?alt=media&token=af53ba29-b974-442d-b1ae-ea0cf31b5a52"
  imageURL:string = ''
  amountSold:number = 0;
  annualReturn:string = ""
  title:string = ""
  description:string = ""
  totalAmountMangopay:number = 0;
  usersRetentionData:any;
  totalInvested: number = 0;
  retentionMangopay: boolean = false;
  totalRaw: number = 0;
  dividendSelected: string = 'Renta';
  dividendTypes = ['Renta','Venta'];
  // projectId: any;
  constructor(private http: HttpClient,private connSrv: ConnectionsService, private afs: AngularFirestore, private fns: AngularFireFunctions, private crf: ChangeDetectorRef) {
    this.web3 = this.connSrv.web3Instance;

    this.connSrv.addressUser.subscribe((res: string) => { 
      this.ourAddress = res;
    });

    this.contractManager = new this.web3.eth.Contract(this.abiManager.abi, environment.config.ADDRESS_MANAGER); 
  }

  createFormDividends() {
    return new FormGroup({
      tokenAddress: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, [Validators.required]),
      retentionFlag: new FormControl(null, [Validators.required]),
      priceStableCoin: new FormControl(null, [Validators.required]),
      finishFlag: new FormControl(null, [Validators.required])
    })
  }
  createFormDividendsFiat() {
    return new FormGroup({
      projectId:new FormControl(null, [Validators.required]),
    })
  }

  get tokenAddress() { return this.formAddDividends?.get('tokenAddress'); }
  get amount() { return this.formAddDividends?.get('amount'); }
  get retentionFlag() { return this.formAddDividends?.get('retentionFlag'); }
  get priceStableCoin() { return this.formAddDividends?.get('priceStableCoin'); }
  get finishFlag() { return this.formAddDividends?.get('finishFlag'); }
  get projectId() { return this.formAddDividendsFiat?.get('projectId'); }


  async addDividends() {
    if (this.formAddDividends.invalid) { 
      this.formAddDividends.markAllAsTouched();
      return; 
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    this.afs.collection('investments', ref => ref.where('tokensAddress', '==', this.tokenAddress?.getRawValue()).limit(1)).get().subscribe(async (snapDocs: any) => {
      const data: any = snapDocs.docs[0].data();

      const idProyect: string = data.id;
      const stableCoinAddress: string = data.stableCoinAddress;
      const dividendsAddress: string = data.dividendsAddress;

      const contractToken: any = new this.web3.eth.Contract(this.abiToken.abi, this.tokenAddress?.getRawValue());       

      const balanceSeller: number = await contractToken.methods.balanceOf(data.sellerAddress).call();
      if (balanceSeller > 0) {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Erro en contrato',
          text: 'No se han vendido todos los tokens'
        });

        return;
      }

      const _currentIdSnapshot: number = Number(await contractToken.methods.getCurrentSnapshotId().call());
      const currentIdSnapshot: string = String(_currentIdSnapshot + 1); 

      this.fns.httpsCallable('newSnapshot')({token: this.tokenAddress?.getRawValue(), idSnapshot: _currentIdSnapshot + 1}).subscribe((res:any) =>{
        if (!res.success) {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Fallo en la base de datos'
          });
  
          return;
        } else {
          this.afs.collection(`w-invest`).doc(this.tokenAddress?.getRawValue()).collection(currentIdSnapshot).get().subscribe(async (snapDocs: any) => {
            this.retentionsTokens = 0;
            this.retentionsTokensCompany = 0;
    
            for (let i = 0; i < snapDocs.docs.length; i++) {
              const doc = snapDocs.docs[i];
    
              if (doc.exists) {
                const values: any = Object.values(doc.data());
    
                for (let i = 0; i < values.length; i++) {
                  const v = values[i];
    
                  if (v.walletByCompany === false && this.retentionFlag?.getRawValue() === 'true' && v.percentRetentionTo > 0) {
                    this.retentionsTokens += this.formatToEther(v.balance) * (v.percentRetentionTo / 100);
                  }
    
                  if (v.walletByCompany === true && this.retentionFlag?.getRawValue() === 'true' && v.percentRetentionTo > 0) {
                    this.retentionsTokensCompany += this.formatToEther(v.balance) * (v.percentRetentionTo / 100);
                  }
                }
              }
            }
    
            const totalSupply: number = this.formatToEther(await contractToken.methods.totalSupply().call());
            const totalByCompany: number = this.formatToEther(await contractToken.methods.amountBuyWithFiat().call());
            const tokensSC: number = (totalSupply - totalByCompany) - this.retentionsTokens;
            const tokensCompany: number = totalByCompany - this.retentionsTokensCompany;
    
            const percentSC: number = (tokensSC * 100) / totalSupply;
            const amountToTransfer: number = this.amount?.getRawValue() * (percentSC / 100);
            const amountWeisToTransfer: number = this.formatToWei(amountToTransfer);
    
            const percentCompany: number = (tokensCompany * 100) / totalSupply;
            const amountToTransferCompany: number = this.amount?.getRawValue() * (percentCompany / 100);
    
            const contractStableCoin: any = new this.web3.eth.Contract(this.abiErc20.abi, stableCoinAddress); 

            const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
              return Number(block.baseFeePerGas) + 100000000000; 
            });
    
            await contractStableCoin.methods.approve(dividendsAddress, amountWeisToTransfer).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))})
              .then(async () => {
                await this.contractManager.methods.addDividends(
                  this.ourAddress,
                  this.tokenAddress?.getRawValue(),
                  amountWeisToTransfer,
                  new Date().getFullYear(),
                  Boolean(this.retentionFlag?.getRawValue())
                ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))})
                  .then(() => {
                    this.afs.collection('snapshots').doc(this.tokenAddress?.getRawValue()).set({
                      [currentIdSnapshot]: {
                        snapShotId: currentIdSnapshot,
                        totalAmount: this.amount?.getRawValue(),
                        amountTransfered: amountToTransfer,
                        amountToTransferByCompany: amountToTransferCompany,
                        retentions: this.amount?.getRawValue() - (amountToTransfer + amountToTransferCompany),
                        addressStableCoin: stableCoinAddress,
                        priceStableCoin: this.priceStableCoin?.getRawValue(),
                        timeStamp: Date.now()
                      }
                    }, {merge: true});
        
                    if (this.finishFlag?.getRawValue() !== true) {
                      this.afs.collection('investments').doc(idProyect).set({
                        proyectStatus: 'Repartiendo dividendos'
                      }, {merge: true});

                      Swal.close();
                      Swal.fire({
                        icon: 'success',
                        text: 'Ingreso realizado con éxito' 
                      });
                    } else {
                      this.afs.collection('investments').doc(idProyect).set({
                        proyectStatus: 'Vendido'
                      }, {merge: true}).then(() => {
                        Swal.close();
                        Swal.fire({
                          icon: 'info',
                          title: 'IMPORTANTE',
                          text: 'Ingreso realizado con éxito, pero tienes que pausar este contrato en la administración'
                        });
                      });
                    }
    
                    this.formAddDividends.reset();
                  })
                  .catch(() => {
                    Swal.close();
                    Swal.fire({
                      icon: 'error',
                      title: 'Transacción errónea',
                      text: 'Algo ha ido mal, la transacción de para insertar los divididendos NO se ha realizado'
                    });
                  });
              })
              .catch(() => {
                Swal.close();
                Swal.fire({
                  icon: 'error',
                  title: 'Transacción errónea',
                  text: 'Algo ha ido mal, la transacción de approve NO se ha realizado'
                });
              });
          });
        }
      });
    });
  }

  formatToEther(num: number) {
    const BN = this.web3.utils.BN;

    try {
      return this.web3.utils.fromWei(new BN(num).toString(), 'ether');
    } catch (error) {
      return this.web3.utils.fromWei(num.toString(), 'ether');
    }
  }

  showForm(formType: string) {
    this.selectedForm = formType;
}

  formatToWei(num: number) {
    return this.web3.utils.toWei(num.toString(), 'ether');
  }

  getProjectData(){
    console.log(this.contractAddress)
    if(!this.contractAddress){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No se ha seleccionado un proyecto'
      });
      this.existsProject = false;
      return;
    }
    this.http.get(`https://redsys.api.devtop.online/projects/${this.contractAddress}`).subscribe({
      next:(data:any)=>{
        this.existsProject = true;
        this.title = data?.title as string;
        this.imageURL = data?.image as string;
        this.amountSold = (data?.balance)/100 as number;
        this.description = data?.description as string;
        this.totalAmountMangopay = this.amountSold;
        this.totalInvested = data?.totalInvested/100;
        this.usersRetentionData = data?.amountCollectedData;
    },
    error:(error)=>{
      console.log(error.message)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No hay proyectos con este contrato'
      });
      this.existsProject = false;
    }
    }
   )
  }

  createDividends(){
    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    this.http.post(`https://redsys.api.devtop.online/projects/dividends/create`,{
      address: this.contractAddress,
      amount: this.totalRaw * 100,
      retention: this.retentionMangopay,
      totalMangopay: this.amountSold,
      totalTransfer: Number(this.totalAmountMangopay),
      lastDividend:this.dividendSelected == 'Venta'
    }).subscribe({
      next:()=>{
        Swal.close()
        Swal.fire({
          icon:'success',
          title: 'Dividendos generados con éxito',
          text: 'Los dividendos han sido generados correctamente'
        })
      },
      error:(e)=>{
        console.log(e)
        Swal.close()
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error al crear los dividendos, verifique el saldo introducido'
        })
      }
    })
  }

  sumTotal(event: Event): void {
    const value = (event.target as HTMLInputElement).value
    console.log(value)
    const regex = new RegExp(/\d+/)
    if(!regex.test(value))
      Swal.fire({
    icon: 'error',
    title: 'Error',
    text: 'Solo se permiten números'})
    const mangopayPercentage = (Number(this.amountSold)) / this.totalInvested;
    console.log(this.selectedForm)
    const profit = (Number(value) - (this.dividendSelected == 'Venta' ? Number(this.totalInvested) : 0)) * mangopayPercentage;
    if(isNaN(profit)){
      this.totalAmountMangopay = 0
      this.totalRaw = 0
    }
    else{
      this.totalRaw = profit;
      if(!this.retentionMangopay){
        this.totalAmountMangopay = this.dividendSelected == 'Venta' ? this.amountSold + profit : profit;
        console.log(profit)
        console.log(this.amountSold);
        console.log(this.totalAmountMangopay);
      }else{
          this.totalAmountMangopay = this.usersRetentionData.reduce((x,y)=>{
          const participation = (y.amount/100)/this.amountSold;
          const interest = profit * participation
          return x+= this.dividendSelected == 'Venta' ? (y.amount/100) + (interest* (1-(y.retention / 100))) : (interest* (1-(y.retention / 100))) 
        },0)
      }
    }
  }

}
