import {Component, AfterViewInit, OnInit, ViewChild} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { LoaderService } from 'src/app/services/loader.service';
import { PaymentService } from 'src/app/services/payment.service';
import Swal from 'sweetalert2';

export interface WithdrawRequest {
  amount: number;
  account: string;
  userEmail: string;
  date: { seconds: number, nanoseconds: number },
  updatedAt?: { seconds: number, nanoseconds: number}
  status: string,
  userId: string,
  withdrawId: string
}

@Component({
  selector: 'app-withdrawal-requests',
  templateUrl: './withdrawal-requests.component.html',
  styleUrls: ['./withdrawal-requests.component.css']
})

export class WithdrawalRequestsComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol','status', 'action'];
  dataSource: WithdrawRequest[] = [];
  sortColumn: 'amount' | 'date' = 'date';
  sortDirection: 'asc' | 'desc' = 'desc';
  selectedStatus: string = 'pending';
  constructor(
    private afs: AngularFirestore,
    private loaderService: LoaderService,
    private paymentService: PaymentService
  ) { }

  ngOnInit(): void {
    this.getWithdrawalRequests();
  }

  approve(element: any) {
    const data = {
      withdrawId: element.withdrawId,
      approved: true,
    }
    this.paymentService.claimDividends(data)
    console.log('Aprobado:', element);
  }

  changeStatus(withdrawId: string, approved: boolean) {
    this.loaderService.showSpinner('Actualizando estado de la solicitud');
    this.paymentService.changeWithdrawalStatus({withdrawId, approved}).subscribe(
      (res: any) => {
        console.log(res);
        if (approved) {
          Swal.fire('Solicitud aprobada', 'La solicitud de retiro ha sido aprobada', 'success');
          this.dataSource = this.dataSource.filter((request: WithdrawRequest) => request.withdrawId !== withdrawId);
        } else {
          Swal.fire('Solicitud denegada', 'La solicitud de retiro ha sido denegada', 'success');
        }
        this.loaderService.hideSpinner();
      }, (error: any) => {
        console.log(error);
        Swal.fire('Error', 'Ocurrió un error al actualizar el estado de la solicitud', 'error');
        this.loaderService.hideSpinner();
      }
    )
  }

  filterByStatus() { 
  }

  getWithdrawalRequests(status:string = 'pending') {
    this.loaderService.showSpinner('Cargando solicitudes de retiro');
    this.afs.collection('withdraws-mangopay').get().subscribe(
      (res: any) => {
        this.dataSource = res.docs
          .map((doc: any) => doc.data() as WithdrawRequest)
          .filter((request: WithdrawRequest) => request.status === status);
        console.log(this.dataSource);
        this.loaderService.hideSpinner();
      }, 
      (error: any) => {
        console.log(error);
        this.loaderService.hideSpinner();
      }
    );
  }

  transformDate(date: any) {
    return new Date(date * 1000).toLocaleDateString();
  }

  denyWithdrawal(element: any) {
    console.log('Denegado:', element);
  }

  // sortBy(column: 'amount' | 'date'): void {
  //   if (this.sortColumn === column) {
  //     this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  //   } else {
  //     this.sortColumn = column;
  //     this.sortDirection = 'desc';
  //   }

  //   this.dataSource.sort((a, b) => {
  //     const valueA = column === 'amount' ? a.amount : new Date(a.date * 1000).getTime();
  //     const valueB = column === 'amount' ? b.amount : new Date(b.date).getTime();

  //     if (valueA < valueB) return this.sortDirection === 'asc' ? -1 : 1;
  //     if (valueA > valueB) return this.sortDirection === 'asc' ? 1 : -1;
  //     return 0;
  //   });
  // }

  getSortIcon(column: 'amount' | 'date'): string {
    if (this.sortColumn !== column) return 'bi-arrow-down-up';
    return this.sortDirection === 'asc' ? 'bi-arrow-up' : 'bi-arrow-down';
  }

}
