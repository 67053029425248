import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  transform(value: number | string, currencySymbol: string = '€', decimalLength: number = 2, chunkDelimiter: string = '.', decimalDelimiter: string = ',', chunkLength: number = 3): string {
    // Convertir el valor a string si es un número
    let stringValue = value.toString();

    // Reemplazar comas con puntos para manejar decimales
    stringValue = stringValue.replace(',', '.');

    // Convertir el valor a número
    const numericValue = parseFloat(stringValue);

    if (isNaN(numericValue)) {
      return '';
    }

    const regex = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    const num = numericValue.toFixed(Math.max(0, ~~decimalLength));

    return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(regex, 'g'), '$&' + chunkDelimiter) + currencySymbol;
  }
}
