<div style="margin-top: 40px;">
    <h3>Elige tu modo de inversión</h3>

    <div class="d-flex align-items-center gap-2">
        <img src="assets/img/modal-kyc/warnings.svg" alt="coins" style="width: 28px; height: 28px;">
        <p class="m-0">Recuerda que la inversión mínima es de 200€</p>
    </div>

    <div class="options">
        <div class="option">
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" 
                id="flexRadioDefault1" value="banktTransfer" [(ngModel)]="selectedOption" (change)="toggleCollapse('transferencia')">
                <label class="form-check-label" for="flexRadioDefault1">
                    Transferencia bancaria
                </label>
            </div>
            <span class="option-subtitle">Tardará dos días laborables en aparecer el dinero en tu cartera digital</span>

            <div [ngbCollapse]="isTransferenciaCollapsed">

                <div style="position: relative;">
                    <div class="bank-image" >
                        <div class="d-flex gap-2">
                            <img src="assets/img/bank.svg" alt="bank">
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <h5>
                        <i class="fa fa-university" aria-hidden="true"></i>
                        1. Accede a tu banco
                    </h5>
                    <p>Accede a una cuenta bancaria asociada a tu nombre</p>
                </div>

                <div class="mt-4">
                    <h5>
                        <i class="bi bi-file-text"></i>
                        2. Completa los datos
                    </h5>
                    <p>Los datos que necesitarás para hacer la transferencia son los siguientes:</p>

                    <div class="data-bank">

                        <div>
                            <span>Destinatario</span>
                            <p>Domoblock</p>
                        </div>

                        <div>
                            <span>Titular de la cuenta</span>
                            <p>{{bankData?.OwnerName}}</p>
                        </div>

                        <div>
                            <span>Código BIC o SWIFT</span>
                            <p>{{bankData?.BIC}}</p>
                        </div>
                        
                        <div>
                            <span>IBAN</span>
                            <div class="d-flex gap-2">
                                <p>{{bankAccount}}</p>
                                <i class="fa fa-clone fa-lg copy-bank" aria-hidden="true" (click)="copyText()"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <h5>
                        <i class="bi bi-arrow-left-right"></i>
                        3. Realiza la transferencia
                    </h5>
                    <p>Espera un par de días laborales para recibir tus fondos en Domoblock</p>
                </div>
                
                
            </div>

        </div>

        

        <div class="divider"></div>

        <div class="option">
            <div style="position: relative;">
                <div class="credit-cards" >
                    <div class="d-flex gap-2" *ngIf="isTarjetaCollapsed">
                        <img src="assets/img/visa.svg" alt="visa" style=" height: 19px;">
                        <img src="assets/img/mastercard.svg" alt="mastercard" style=" height: 19px;">
                    </div>
                </div>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" 
                id="flexRadioDefault2" value="creditCard" [(ngModel)]="selectedOption" (change)="toggleCollapse('tarjeta')">
                <label class="form-check-label" for="flexRadioDefault2">
                    Tarjeta de crédito
                </label>
            </div>
            <span class="option-subtitle">Tardará un mínimo de 24h en mostrar el dinero en tu cartera digital</span>
            <div [ngbCollapse]="isTarjetaCollapsed">

                <div style="position: relative;">
                    <div class="bank-image" >
                        <div class="d-flex gap-2">
                            <img src="assets/img/credit-cards.svg" alt="credit-cards">
                        </div>
                    </div>
                </div>

                <div class="pay-credit-card">
                    <div class="mt-4">
                        <p>Cantidad a ingresar</p>
                        <input type="number" class="form-control" placeholder="200€" [(ngModel)]="amount" style="width: 250px;" min="200">
                    </div>
    
                    <div class="mt-4">
                        <p class="m-0">Comisión aplicable: 0,5%</p>
                        <span>{{ getFee() | customCurrency }}</span>
                    </div>
    
                    <div class="mt-4">
                        <p class="m-0">Total operación:</p>
                        <h4>{{ getAmountWithFee() | customCurrency }}</h4>
                    </div>

                    <div class="mt-4">
                        <button class="btn btn-success text-center" style="border-radius: 50px;" (click)="getPaymentLink()">Realizar operación</button>
                    </div>
                </div>

            </div>
        </div>

        <div class="divider"></div> 
        
    </div>
</div>