<div class="px-1">
    <div>
        <h3>Cambiar contraseña</h3>
    </div>

    <div>
        <div class="mt-5">
            <p>Crea una nueva contraseña, cumpliendo con los siguientes criterios:</p>
            <br>
            <p>- Debe tener mínimo 6 caracteres. </p>
            <p>- Debe tener mínimo una letra mayúscula y una minúscula.</p>
            <p>- Debe tener al menos un número.</p>
            <p>- Debe tener al menos un carácter especial.</p>
        </div>

        <div class="w-50 pr-3 mt-5">

            <p>Cambio de contraseña</p>

            <div *ngIf="capsLockActivated" class="text-danger">
                <span> *Las mayúsculas están activadas.
                </span>
            </div>

            <form class="form-group pbc password-div" [formGroup]="forgotForm">

                <div class="form-group pb-0">
                    <input formControlName="otp" class="form-control" id="Otp"
                        placeholder="Escribe el codigo enviado a tu correo" (input)="convertToUppercase($event)">
                </div>
                <div class="text-end">
                    <label mat-button (click)="getOtp()" class="changecolor" *ngIf="!isDisabled"
                        [class.disabled]="isDisabled" [attr.disabled]="isDisabled ? true : null">
                        {{ sentEmail ? 'Reenviar código de verificación ' : 'Solicitar código de verificación' }}
                    </label>
                    <label *ngIf="isDisabled" class="countdown">Reenviar código en {{ countdown }}s</label>
                </div>

                <div>
                    <!-- <label for="Contraseña" class="labeltext">Nueva contraseña</label> -->
                    <input formControlName="password" type="{{showPassword ? 'text' : 'password' }}"
                           class="form-control form-control-sm" placeholder="Ingresa tu nueva contraseña"
                           (focus)="onFocus('password')" [maxlength]="20">
                    
                    <a (click)="togglePassword('password')" class="eye-b">
                      <i class="{{showPassword ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
                    </a>
                    
                    <div *ngIf="forgotForm.get('password').touched && passwordErrors.length > 0" class="warning-messages">
                        <span class="text-danger">
                            <span *ngFor="let error of passwordErrors; let i = index">
                                <span [ngClass]="{'error-message': true}">
                                    {{ error }}<span *ngIf="i < passwordErrors.length - 1">, 
                                </span></span>
                              </span>
                              .
                        </span>
                    </div>
                </div>

                <div class="mt-3">
                    <!-- <label for="Contraseña" class="labeltext">Confirmar nueva contraseña</label> -->
                    <input formControlName="confirmPassword" type="{{showConfirmPassword ? 'text' : 'password' }}"
                        class="form-control form-control-sm" placeholder="Confrimar tu nueva contraseña" [maxlength]="20">

                    <a (click)="togglePassword('confirmPassword')" class="eye-b" style="top: 170px;">
                        <i class="{{showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
                    </a>
                </div>
            </form>

            <button type="button" class="btn" (click)="changePassword()">Cambiar contraseña</button>
        </div>
    </div>
</div>
