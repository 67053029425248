import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { PaymentService } from 'src/app/services/payment.service';
import Swal from 'sweetalert2';

interface Transaction {
  id: string;
  type: string;
  status: string;
  date: any;
  amount: number; 
  excutionDate: string;
  creditedFunds: number;
  fee?: number;
  account?: string;
}

@Component({
  selector: 'app-transactions-history',
  templateUrl: './transactions-history.component.html',
  styleUrls: ['./transactions-history.component.css']
})

export class TransactionsHistoryComponent implements OnInit {
  transactions: Transaction[] = []
  isLoading = true;
  typeMovementSelected = 'PAYIN';
  
  // [
  //   { totalAmount: 1002.4, fee: '0.24%', amount: 1000, method: 'Tarjeta de crédito', date: '2024-07-14' },
  //   { totalAmount: 1000, fee: '0%', amount: 1000, method: 'Transferencia bancaria', date: '2023-09-21' },
  //   { totalAmount: 2000, fee: '0%', amount: 200, method: 'Transferencia bancaria', date: '2023-07-21' }
  // ];

  sortColumn: 'amount' | 'date' = 'date';
  sortDirection: 'asc' | 'desc' = 'desc';

  constructor(
    private paymentSrv: PaymentService, 
    private authSrv: AuthService,
    private loaderSrv: LoaderService
  ) {

   }

  ngOnInit(): void {
    this.getMovements();
  }


  sortBy(column: 'amount' | 'date'): void {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'desc';
    }

    this.transactions.sort((a, b) => {
      const valueA = column === 'amount' ? a.amount : new Date(a.date).getTime();
      const valueB = column === 'amount' ? b.amount : new Date(b.date).getTime();

      if (valueA < valueB) return this.sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return this.sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }

  getSortIcon(column: 'amount' | 'date'): string {
    if (this.sortColumn !== column) return 'bi-arrow-down-up';
    return this.sortDirection === 'asc' ? 'bi-arrow-up' : 'bi-arrow-down';
  }


  getMovements(typeMovement: string = 'PAYIN'){
    this.transactions = [];
    this.typeMovementSelected = typeMovement;
    const uid = this.authSrv.userAuth.getValue().uid;
    const params = `${uid}?type=${typeMovement}`;

    this.loaderSrv.showSpinner('Cargando movimientos');
    this.isLoading = true;
    this.paymentSrv.getMovements(params).subscribe((res: any)  => {
      console.log(res);

      if (typeMovement === 'PAYIN') {
        const tempArray = res.filter((transaction : Transaction) => transaction.status == 'SUCCEEDED');
        console.log(tempArray);
        this.transactions = tempArray.map((transaction: Transaction) => {
          return {
            ...transaction,
            fee: (1 - (transaction.creditedFunds / transaction.amount)) * 100,
            date: new Date(transaction.date * 1000).toLocaleDateString()
          }
        });
    }

    if (typeMovement === 'PAYOUT') {
      this.transactions = res.map((transaction: Transaction) => {
        return {
          ...transaction,
          date: new Date(transaction.date)
        }
      });
    }

    //new Date(transaction.date) | date:'dd-MM-yyyy'

      console.log(this.transactions);
      this.loaderSrv.hideSpinner();
      this.isLoading = false;
    }, (err) => {
      console.log(err);
      Swal.fire('Error', 'Ha ocurrido un error al cargar los movimientos', 'error');
      this.loaderSrv.hideSpinner();
      this.isLoading = false;
    });
  }

  getDate(date: number): string {
    return new Date(date * 1000).toLocaleDateString();
  }

}
