<div class=" px-1">
    <div>
        <h3>Usuario Domoblock</h3>
    </div>

    <div class="user-status mt-4">
        <div class="user-info">
          <div class="avatar">
            <span>S</span>
          </div>
          <div class="d-flex gap-3">
            <div class="details">
              <p class="name">Sergio Navarro</p>
              <p class="email">s.navarro@outlook.com</p>
            </div>
            <!-- <span class="kyc-badge">KYC pending</span> -->
          </div>
          
        </div>
        <div class="status">
          <p class="created-date">Creado el 02/07/2024</p>
        </div>
    </div>

    <div class="row mt-5">
      <div class="col-lg-9 col-8" style="padding-right: 6rem;">
        <h3>Información personal</h3>
        <p>Aquí te mostramos tus datos personales</p>

        <div class="row">

          <div class="col-6 mt-4">
            <label for="name">Nombre</label>
            <input
                  type="text"
                  class="border-0 no-focus-border w-100"
                  placeholder="Sergio"
                  [(ngModel)]="firstName"
                  disabled
                />
                <div class="border-top thick-border"></div>
          </div>

          <div class="col-6 mt-4">
            <label for="name">Apellidos</label>
            <input
                  type="text"
                  class="border-0 no-focus-border w-100"
                  placeholder="Navarro"
                  [(ngModel)]="lastName"
                  disabled
            />
                <div class="border-top thick-border"></div>
          </div>

          <div class="col-6 mt-4">
            <label for="name">Teléfono</label>
            <input
                  type="text"
                  class="border-0 no-focus-border w-100"
                  placeholder="+34 600 600 600"
                  [(ngModel)]="phoneNumber"
                  disabled
                />
                <div class="border-top thick-border"></div>
          </div>

          <div class="col-6 mt-4">
            <label for="name">Fecha de nacimiento</label>
            <input
                  type="text"
                  class="border-0 no-focus-border w-100"
                  [(ngModel)]="this.birthDate"
                  disabled
                />
                <div class="border-top thick-border"></div>
          </div>
          
          <div class="col-6 mt-4">
            <label for="name">Documento de identidad</label>
            <input
                  type="text"
                  class="border-0 no-focus-border w-100"
                  placeholder="49000000N"
                  [(ngModel)]="idNumber"
                  disabled
                />
                <div class="border-top thick-border"></div>
          </div>

          <div class="col-6 mt-4">
            <label for="name">País de residencia</label>
            <input
                  type="text"
                  class="border-0 no-focus-border w-100"
                  placeholder="España"
                  [(ngModel)]="country"
                  disabled
                />
                <div class="border-top thick-border"></div>
          </div>

          <div class="col-12 mt-4">
            <label for="name">Dirección</label>
            <input
                  type="text"
                  class="border-0 no-focus-border w-100"
                  placeholder="Av. de Concha Espina, 1, Chamartín, 28036 Madrid"
                  [(ngModel)]="address"
                  disabled
                />
                <div class="border-top thick-border"></div>
          </div>

          <div class="col-6 mt-4">
            <label for="name">Ciudad</label>
            <input
                  type="text"
                  class="border-0 no-focus-border w-100"
                  placeholder="Madrid"
                  [(ngModel)]="city"
                  disabled
                />
                <div class="border-top thick-border"></div>
          </div>

          <div class="col-6 mt-4">
            <label for="name">Código postal</label>
            <input
                  type="text"
                  class="border-0 no-focus-border w-100"
                  placeholder="28036"
                  [(ngModel)]="postalCode"
                  disabled
                />
                <div class="border-top thick-border"></div>
          </div>

          <div class="col-12 mt-5">
              <p>Si tu domicilio o país de residencia ha cambiado haz clic en el siguiente botón</p>
              <button type="button" class="btn btn-success text-center rounded-pill px-4">Solicitar cambio de residencia</button>
          </div>

        </div>
      </div>

      <div class="col-lg-3 col-4 d-flex justify-content-center align-items-center flex-column">
        <div class="downloadables-box">
          <h5 class="text-center">Consulta nuestros textos legales</h5>
          <div class="mt-5 d-flex flex-column">
            <a href="#" class="text-decoration-none"><i class="fa fa-bars" aria-hidden="true"></i> Términos y condiciones</a>
            <br>
            <br>
            <a href="#" class="text-decoration-none"><i class="fa fa-bars" aria-hidden="true"></i> Aviso legal</a>
            <br>
            <br>
            <a href="#" class="text-decoration-none"><i class="fa fa-bars" aria-hidden="true"></i> Política de privacidad</a>
            <br>
            <br>
            <a href="#" class="text-decoration-none"><i class="fa fa-bars" aria-hidden="true"></i> Política de cookies</a>
          </div>
        </div>
        <p class="mt-3 logout-text" (click)="logout()">Cerrar sesión</p>
      </div>
        
    </div>
</div>