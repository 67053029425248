import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class KycService {
  kycSuccess: any = new BehaviorSubject<any>({success:false,revision:false,aproved:true});
  kycReview: any = new BehaviorSubject<boolean>(false);
  kycInfo: any = new BehaviorSubject<any>(null);
  kycDuplicated: any = new BehaviorSubject<boolean>(false);
  kycNotApproved: any = new BehaviorSubject<boolean>(false);

  constructor(private authSrv: AuthService, private afs: AngularFirestore) {
    this.authSrv.loggedIn.subscribe((res: any) => {
      if (res === true) {
        this.authSrv.userAuth.subscribe((res: any) => {
          if (res !== null) {
              this.afs.collection('users').doc(res.uid).valueChanges().subscribe((res: any) => {
              (res !== undefined && res.kyc !== undefined) ? this.kycInfo.next(res.kyc) : this.kycInfo.next(null);
              
              if (res !== undefined && res.kyc !== undefined && res.kyc.result !== undefined) {
                this.kycDuplicated.next(!!res.duplicated);
                if(res.kyc.result === 0)  this.kycSuccess.next({success:false, revision:false, aproved:true});
                if(res.kyc.result === 1)  this.kycSuccess.next({success:true, revision:false, aproved:false});
                if(res.kyc.result === 2)  this.kycSuccess.next({success:false, revision:true, aproved:false});
              } else { 
                this.kycSuccess.next({success:false,revision:false,aproved:true}); 
              }
            });
          } else { this.cleanAll(); }
        });
      } else { this.cleanAll(); }
    });
  }

  cleanAll() {
    this.kycInfo.next(null);
    this.kycSuccess.next({success:false,revision:false,aproved:false});
  }
}
