import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as jsonManager from '../../../abis/Manager.json';
import { environment } from '../../../../environments/environment';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app'; 

@Component({
  selector: 'app-buy-without-pay',
  templateUrl: './buy-without-pay.component.html',
  styleUrls: ['./buy-without-pay.component.css']
})
export class BuyWithoutPayComponent {
  formBuyThroughCompany: FormGroup = this.createFormBuyThroughCompany(); 

  ourAddress: string | undefined;
  web3: any | undefined;
  abiManager: any = jsonManager;
  contractManager: any | undefined;

  constructor(private connSrv: ConnectionsService, private fns: AngularFireFunctions, private afs: AngularFirestore) {
    this.web3 = this.connSrv.web3Instance;

    this.connSrv.addressUser.subscribe((res: string) => { 
      this.ourAddress = res;
    });

    this.contractManager = new this.web3.eth.Contract(this.abiManager.abi, environment.config.ADDRESS_MANAGER); 
  }
  
  createFormBuyThroughCompany() {
    return new FormGroup({
      amountTokens: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      wallet: new FormControl(null, [Validators.required]),
      addressContractSeller: new FormControl(null, [Validators.required])
    })
  }

  get amountTokens() { return this.formBuyThroughCompany?.get('amountTokens'); }
  get email() { return this.formBuyThroughCompany?.get('email'); }
  get wallet() { return this.formBuyThroughCompany?.get('wallet'); }
  get addressContractSeller() { return this.formBuyThroughCompany?.get('addressContractSeller'); }

  formatToWei(num: number) {
    return this.web3.utils.toWei(num.toString(), 'ether');
  }

  async buy() {
    if (this.formBuyThroughCompany.invalid) { 
      this.formBuyThroughCompany.markAllAsTouched();
      return; 
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    
    this.afs.collection('users', ref => ref.where('email', '==', this.email?.getRawValue()).limit(1)).get().subscribe(async (res: any) => {
      if (!res.docs[0].exists) {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Usuario no encontrado'
        });

        return;
      }

      const uid = res.docs[0].data().uid;
      const userData = res.docs[0].data();
      if(userData?.kyc?.result != 1){
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Usuario no tiene KYC completado'
        });
        return;
      }
      if((!userData?.particular && !userData?.particular.pais_de_residencia) && !userData?.Empresa?.Representante?.pais_de_residencia){
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Usuario con irregularidades'
        });
        return;
      }

      this.afs.collection('walletTraceability').doc(this.wallet?.getRawValue()).get().subscribe((res: any) => {
        if (res.exists && res.data().uid !== uid) {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'La wallet pertenece a otro usuario'
          });

          return;
        }
      });

      const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
        return Number(block.baseFeePerGas) + 100000000000; 
      });

      this.contractManager.methods.buyWithoutPay( 
        this.formatToWei(this.amountTokens?.getRawValue()),
        this.wallet?.getRawValue(),
        this.addressContractSeller?.getRawValue()
      ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
        .then(async () => {
          await this.afs.collection('users').doc(uid).set({walletsWhitelist: firebase.firestore.FieldValue.arrayUnion(this.wallet?.getRawValue())}, {merge: true});
    
          await this.afs.collection('walletTraceability').doc(this.wallet?.getRawValue()).set({
            uid: uid,
            timeStamp: await firebase.firestore.FieldValue.serverTimestamp()
          });
          
         await this.afs.collection('investments', ref => ref.where('sellerAddress', '==', this.addressContractSeller?.getRawValue()).limit(1)).get().subscribe(async(res: any) => {
            try {
              await this.afs.collection('user-investments').doc(uid).set({ [res.docs[0].data().id]: true }, {merge: true});
            } catch (error) {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `ha ocurrido un error actualizando la coleccion user-investments: ${(error as Error).message}`
              });
            }
            
          });

          Swal.close();
          Swal.fire({
            icon: 'success',
            text: 'Inversión realizada con éxito'
          });
  
          this.formBuyThroughCompany.reset();
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Transacción errónea',
            text: 'Algo ha ido mal, la transacción NO se ha realizado'
          });
        });
    });
  } 
}
