import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  firstName: string = '';
  lastName: string = '';
  email: string = '';
  status: string = '';
  phoneNumber: string = '';
  birthDate: string = '';
  idNumber: string = '';
  country: string = '';
  address: string = '';
  postalCode: string = '';
  user = {
    name: 'Sergio Navarro',
    email: 's.navarro@outlook.com',
    status: 'KYC pending',
    createdDate: '02/07/2024',
    personalInfo: {
      firstName: 'Sergio',
      lastName: 'Navarro',
      phone: '+34 600 600 600',
      birthDate: '01/01/2000',
      idNumber: '49000000N',
      country: 'España',
      address: 'Calle del ...',
      city: 'Ciudad',
      postalCode: 'Código postal'
    }
  };
  uid: string = '';
  city: any;
  
  constructor(
    private readonly afs: AngularFirestore,
    private readonly authService: AuthService,
    private authSrv:  AuthService,
    private router: Router
  ) { 
  }

  async ngOnInit() {
    this.uid = await this.authService.userAuth.getValue().uid;
    this.afs.collection('users').doc(this.uid).get().subscribe((userdata: any) => {
      const userData = userdata.data()
      console.log(userdata.data(),userData.exists)
      if (userdata.exists) {
        this.firstName = userData.particular?.nombre  || 'sin completar';
        this.lastName = userData.particular?.apellido1 || 'sin completar';;
        this.email = userData.email || 'sin completar';;
        this.status = userData.kyc?.result == 1 ? 'Aprobado' : 'Pendiente';
        this.phoneNumber = userData.particular?.telefono || 'sin completar';;
        this.birthDate = userData.particular?.fecha_de_nacimiento || 'sin completar';;
        this.idNumber = userData.kyc?.documentIdentification || 'sin completar';;
        this.country = userData.particular?.pais_de_residencia || 'sin completar';
        this.address = userData.particular?.domicilio || 'sin completar';;
        this.postalCode = userData.particular?.codigo_postal || 'sin completar';;
        this.city = userData.kyc?.technicalData?.city || 'sin completar';
      } else {
        console.log('No such document!');
      }
    });
  }

    logout() {
      Swal.fire({
        icon: 'info',
        title: '¿Quieres salir de la aplicación?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Cancelar',
        cancelButtonText: `Ok`,
      }).then(async (result: any) => {
        if (!result.isConfirmed) {
          await this.authSrv.logout();
          this.router.navigateByUrl('/login');
          this.authSrv.reload();
        }
      });
    }
}
