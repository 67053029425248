export interface Countries {
    code: string
    code3: string
    name: string
    number: string,
    flag?: string;  // Add a flag property to store the flag URL
}

export var countries: Countries[] = [
    { code: "AF", code3: "AFG", name: "Afghanistan", number: "004", flag: "https://flagcdn.com/w320/af.png" },
    { code: "AL", code3: "ALB", name: "Albania", number: "008", flag: "https://flagcdn.com/w320/al.png" },
    { code: "DZ", code3: "DZA", name: "Algeria", number: "012", flag: "https://flagcdn.com/w320/dz.png" },
    { code: "AS", code3: "ASM", name: "American Samoa", number: "016", flag: "https://flagcdn.com/w320/as.png" },
    { code: "AD", code3: "AND", name: "Andorra", number: "020", flag: "https://flagcdn.com/w320/ad.png" },
    { code: "AO", code3: "AGO", name: "Angola", number: "024", flag: "https://flagcdn.com/w320/ao.png" },
    { code: "AI", code3: "AIA", name: "Anguilla", number: "660", flag: "https://flagcdn.com/w320/ai.png" },
    { code: "AQ", code3: "ATA", name: "Antarctica", number: "010", flag: "https://flagcdn.com/w320/aq.png" },
    { code: "AG", code3: "ATG", name: "Antigua and Barbuda", number: "028", flag: "https://flagcdn.com/w320/ag.png" },
    { code: "AR", code3: "ARG", name: "Argentina", number: "032", flag: "https://flagcdn.com/w320/ar.png" },
    { code: "AM", code3: "ARM", name: "Armenia", number: "051", flag: "https://flagcdn.com/w320/am.png" },
    { code: "AW", code3: "ABW", name: "Aruba", number: "533", flag: "https://flagcdn.com/w320/aw.png" },
    { code: "AU", code3: "AUS", name: "Australia", number: "036", flag: "https://flagcdn.com/w320/au.png" },
    { code: "AT", code3: "AUT", name: "Austria", number: "040", flag: "https://flagcdn.com/w320/at.png" },
    { code: "AZ", code3: "AZE", name: "Azerbaijan", number: "031", flag: "https://flagcdn.com/w320/az.png" },
    { code: "BS", code3: "BHS", name: "Bahamas", number: "044", flag: "https://flagcdn.com/w320/bs.png" },
    { code: "BH", code3: "BHR", name: "Bahrain", number: "048", flag: "https://flagcdn.com/w320/bh.png" },
    { code: "BD", code3: "BGD", name: "Bangladesh", number: "050", flag: "https://flagcdn.com/w320/bd.png" },
    { code: "BB", code3: "BRB", name: "Barbados", number: "052", flag: "https://flagcdn.com/w320/bb.png" },
    { code: "BY", code3: "BLR", name: "Belarus", number: "112", flag: "https://flagcdn.com/w320/by.png" },
    { code: "BE", code3: "BEL", name: "Belgium", number: "056", flag: "https://flagcdn.com/w320/be.png" },
    { code: "BZ", code3: "BLZ", name: "Belize", number: "084", flag: "https://flagcdn.com/w320/bz.png" },
    { code: "BJ", code3: "BEN", name: "Benin", number: "204", flag: "https://flagcdn.com/w320/bj.png" },
    { code: "BM", code3: "BMU", name: "Bermuda", number: "060", flag: "https://flagcdn.com/w320/bm.png" },
    { code: "BT", code3: "BTN", name: "Bhutan", number: "064", flag: "https://flagcdn.com/w320/bt.png" },
    { code: "BO", code3: "BOL", name: "Bolivia", number: "068", flag: "https://flagcdn.com/w320/bo.png" },
    { code: "BQ", code3: "BES", name: "Bonaire, Sint Eustatius and Saba", number: "535", flag: "https://flagcdn.com/w320/bq.png" },
    { code: "BA", code3: "BIH", name: "Bosnia and Herzegovina", number: "070", flag: "https://flagcdn.com/w320/ba.png" },
    { code: "BW", code3: "BWA", name: "Botswana", number: "072", flag: "https://flagcdn.com/w320/bw.png" },
    { code: "BV", code3: "BVT", name: "Bouvet Island", number: "074", flag: "https://flagcdn.com/w320/bv.png" },
    { code: "BR", code3: "BRA", name: "Brazil", number: "076", flag: "https://flagcdn.com/w320/br.png" },
    { code: "IO", code3: "IOT", name: "British Indian Ocean Territory", number: "086", flag: "https://flagcdn.com/w320/io.png" },
    { code: "BN", code3: "BRN", name: "Brunei Darussalam", number: "096", flag: "https://flagcdn.com/w320/bn.png" },
    { code: "BG", code3: "BGR", name: "Bulgaria", number: "100", flag: "https://flagcdn.com/w320/bg.png" },
    { code: "BF", code3: "BFA", name: "Burkina Faso", number: "854", flag: "https://flagcdn.com/w320/bf.png" },
    { code: "BI", code3: "BDI", name: "Burundi", number: "108", flag: "https://flagcdn.com/w320/bi.png" },
    { code: "CV", code3: "CPV", name: "Cabo Verde", number: "132", flag: "https://flagcdn.com/w320/cv.png" },
    { code: "KH", code3: "KHM", name: "Cambodia", number: "116", flag: "https://flagcdn.com/w320/kh.png" },
    { code: "CM", code3: "CMR", name: "Cameroon", number: "120", flag: "https://flagcdn.com/w320/cm.png" },
    { code: "CA", code3: "CAN", name: "Canada", number: "124", flag: "https://flagcdn.com/w320/ca.png" },
    { code: "KY", code3: "CYM", name: "Cayman Islands", number: "136", flag: "https://flagcdn.com/w320/ky.png" },
    { code: "CF", code3: "CAF", name: "Central African Republic", number: "140", flag: "https://flagcdn.com/w320/cf.png" },
    { code: "TD", code3: "TCD", name: "Chad", number: "148", flag: "https://flagcdn.com/w320/td.png" },
    { code: "CL", code3: "CHL", name: "Chile", number: "152", flag: "https://flagcdn.com/w320/cl.png" },
    { code: "CN", code3: "CHN", name: "China", number: "156", flag: "https://flagcdn.com/w320/cn.png" },
    { code: "CX", code3: "CXR", name: "Christmas Island", number: "162", flag: "https://flagcdn.com/w320/cx.png" },
    { code: "CC", code3: "CCK", name: "Cocos (Keeling) Islands", number: "166", flag: "https://flagcdn.com/w320/cc.png" },
    { code: "CO", code3: "COL", name: "Colombia", number: "170", flag: "https://flagcdn.com/w320/co.png" },
    { code: "KM", code3: "COM", name: "Comoros", number: "174", flag: "https://flagcdn.com/w320/km.png" },
    { code: "CD", code3: "COD", name: "Congo (the Democratic Republic of the)", number: "180", flag: "https://flagcdn.com/w320/cd.png" },
    { code: "CG", code3: "COG", name: "Congo", number: "178", flag: "https://flagcdn.com/w320/cg.png" },
    { code: "CK", code3: "COK", name: "Cook Islands", number: "184", flag: "https://flagcdn.com/w320/ck.png" },
    { code: "CR", code3: "CRI", name: "Costa Rica", number: "188", flag: "https://flagcdn.com/w320/cr.png" },
    { code: "HR", code3: "HRV", name: "Croatia", number: "191", flag: "https://flagcdn.com/w320/hr.png" },
    { code: "CU", code3: "CUB", name: "Cuba", number: "192", flag: "https://flagcdn.com/w320/cu.png" },
    { code: "CW", code3: "CUW", name: "Curaçao", number: "531", flag: "https://flagcdn.com/w320/cw.png" },
    { code: "CY", code3: "CYP", name: "Cyprus", number: "196", flag: "https://flagcdn.com/w320/cy.png" },
    { code: "CZ", code3: "CZE", name: "Czechia", number: "203", flag: "https://flagcdn.com/w320/cz.png" },
    { code: "CI", code3: "CIV", name: "Côte d'Ivoire", number: "384", flag: "https://flagcdn.com/w320/ci.png" },
    { code: "DK", code3: "DNK", name: "Denmark", number: "208", flag: "https://flagcdn.com/w320/dk.png" },
    { code: "DJ", code3: "DJI", name: "Djibouti", number: "262", flag: "https://flagcdn.com/w320/dj.png" },
    { code: "DM", code3: "DMA", name: "Dominica", number: "212", flag: "https://flagcdn.com/w320/dm.png" },
    { code: "DO", code3: "DOM", name: "Dominican Republic", number: "214", flag: "https://flagcdn.com/w320/do.png" },
    { code: "EC", code3: "ECU", name: "Ecuador", number: "218", flag: "https://flagcdn.com/w320/ec.png" },
    { code: "EG", code3: "EGY", name: "Egypt", number: "818", flag: "https://flagcdn.com/w320/eg.png" },
    { code: "SV", code3: "SLV", name: "El Salvador", number: "222", flag: "https://flagcdn.com/w320/sv.png" },
    { code: "GQ", code3: "GNQ", name: "Equatorial Guinea", number: "226", flag: "https://flagcdn.com/w320/gq.png" },
    { code: "ER", code3: "ERI", name: "Eritrea", number: "232", flag: "https://flagcdn.com/w320/er.png" },
    { code: "EE", code3: "EST", name: "Estonia", number: "233", flag: "https://flagcdn.com/w320/ee.png" },
    { code: "SZ", code3: "SWZ", name: "Eswatini", number: "748", flag: "https://flagcdn.com/w320/sz.png" },
    { code: "ET", code3: "ETH", name: "Ethiopia", number: "231", flag: "https://flagcdn.com/w320/et.png" },
    { code: "FK", code3: "FLK", name: "Falkland Islands [Malvinas]", number: "238", flag: "https://flagcdn.com/w320/fk.png" },
    { code: "FO", code3: "FRO", name: "Faroe Islands", number: "234", flag: "https://flagcdn.com/w320/fo.png" },
    { code: "FJ", code3: "FJI", name: "Fiji", number: "242", flag: "https://flagcdn.com/w320/fj.png" },
    { code: "FI", code3: "FIN", name: "Finland", number: "246", flag: "https://flagcdn.com/w320/fi.png" },
    { code: "FR", code3: "FRA", name: "France", number: "250", flag: "https://flagcdn.com/w320/fr.png" },
    { code: "GF", code3: "GUF", name: "French Guiana", number: "254", flag: "https://flagcdn.com/w320/gf.png" },
    { code: "PF", code3: "PYF", name: "French Polynesia", number: "258", flag: "https://flagcdn.com/w320/pf.png" },
    { code: "TF", code3: "ATF", name: "French Southern Territories", number: "260", flag: "https://flagcdn.com/w320/tf.png" },
    { code: "GA", code3: "GAB", name: "Gabon", number: "266", flag: "https://flagcdn.com/w320/ga.png" },
    { code: "GM", code3: "GMB", name: "Gambia", number: "270", flag: "https://flagcdn.com/w320/gm.png" },
    { code: "GE", code3: "GEO", name: "Georgia", number: "268", flag: "https://flagcdn.com/w320/ge.png" },
    { code: "DE", code3: "DEU", name: "Germany", number: "276", flag: "https://flagcdn.com/w320/de.png" },
    { code: "GH", code3: "GHA", name: "Ghana", number: "288", flag: "https://flagcdn.com/w320/gh.png" },
    { code: "GI", code3: "GIB", name: "Gibraltar", number: "292", flag: "https://flagcdn.com/w320/gi.png" },
    { code: "GR", code3: "GRC", name: "Greece", number: "300", flag: "https://flagcdn.com/w320/gr.png" },
    { code: "GL", code3: "GRL", name: "Greenland", number: "304", flag: "https://flagcdn.com/w320/gl.png" },
    { code: "GD", code3: "GRD", name: "Grenada", number: "308", flag: "https://flagcdn.com/w320/gd.png" },
    { code: "GP", code3: "GLP", name: "Guadeloupe", number: "312", flag: "https://flagcdn.com/w320/gp.png" },
    { code: "GU", code3: "GUM", name: "Guam", number: "316", flag: "https://flagcdn.com/w320/gu.png" },
    { code: "GT", code3: "GTM", name: "Guatemala", number: "320", flag: "https://flagcdn.com/w320/gt.png" },
    { code: "GG", code3: "GGY", name: "Guernsey", number: "831", flag: "https://flagcdn.com/w320/gg.png" },
    { code: "GN", code3: "GIN", name: "Guinea", number: "324", flag: "https://flagcdn.com/w320/gn.png" },
    { code: "GW", code3: "GNB", name: "Guinea-Bissau", number: "624", flag: "https://flagcdn.com/w320/gw.png" },
    { code: "GY", code3: "GUY", name: "Guyana", number: "328", flag: "https://flagcdn.com/w320/gy.png" },
    { code: "HT", code3: "HTI", name: "Haiti", number: "332", flag: "https://flagcdn.com/w320/ht.png" },
    { code: "HM", code3: "HMD", name: "Heard Island and McDonald Islands", number: "334", flag: "https://flagcdn.com/w320/hm.png" },
    { code: "VA", code3: "VAT", name: "Holy See", number: "336", flag: "https://flagcdn.com/w320/va.png" },
    { code: "HN", code3: "HND", name: "Honduras", number: "340", flag: "https://flagcdn.com/w320/hn.png" },
    { code: "HK", code3: "HKG", name: "Hong Kong", number: "344", flag: "https://flagcdn.com/w320/hk.png" },
    { code: "HU", code3: "HUN", name: "Hungary", number: "348", flag: "https://flagcdn.com/w320/hu.png" },
    { code: "IS", code3: "ISL", name: "Iceland", number: "352", flag: "https://flagcdn.com/w320/is.png" },
    { code: "IN", code3: "IND", name: "India", number: "356", flag: "https://flagcdn.com/w320/in.png" },
    { code: "ID", code3: "IDN", name: "Indonesia", number: "360", flag: "https://flagcdn.com/w320/id.png" },
    { code: "IR", code3: "IRN", name: "Iran", number: "364", flag: "https://flagcdn.com/w320/ir.png" },
    { code: "IQ", code3: "IRQ", name: "Iraq", number: "368", flag: "https://flagcdn.com/w320/iq.png" },
    { code: "IE", code3: "IRL", name: "Ireland", number: "372", flag: "https://flagcdn.com/w320/ie.png" },
    { code: "IM", code3: "IMN", name: "Isle of Man", number: "833", flag: "https://flagcdn.com/w320/im.png" },
    { code: "IL", code3: "ISR", name: "Israel", number: "376", flag: "https://flagcdn.com/w320/il.png" },
    { code: "IT", code3: "ITA", name: "Italy", number: "380", flag: "https://flagcdn.com/w320/it.png" },
    { code: "JM", code3: "JAM", name: "Jamaica", number: "388", flag: "https://flagcdn.com/w320/jm.png" },
    { code: "JP", code3: "JPN", name: "Japan", number: "392", flag: "https://flagcdn.com/w320/jp.png" },
    { code: "JE", code3: "JEY", name: "Jersey", number: "832", flag: "https://flagcdn.com/w320/je.png" },
    { code: "JO", code3: "JOR", name: "Jordan", number: "400", flag: "https://flagcdn.com/w320/jo.png" },
    { code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398", flag: "https://flagcdn.com/w320/kz.png" },
    { code: "KE", code3: "KEN", name: "Kenya", number: "404", flag: "https://flagcdn.com/w320/ke.png" },
    { code: "KI", code3: "KIR", name: "Kiribati", number: "296", flag: "https://flagcdn.com/w320/ki.png" },
    { code: "KP", code3: "PRK", name: "Korea (Democratic People's Republic of)", number: "408", flag: "https://flagcdn.com/w320/kp.png" },
    { code: "KR", code3: "KOR", name: "Korea (Republic of)", number: "410", flag: "https://flagcdn.com/w320/kr.png" },
    { code: "KW", code3: "KWT", name: "Kuwait", number: "414", flag: "https://flagcdn.com/w320/kw.png" },
    { code: "KG", code3: "KGZ", name: "Kyrgyzstan", number: "417", flag: "https://flagcdn.com/w320/kg.png" },
    { code: "LA", code3: "LAO", name: "Lao People's Democratic Republic", number: "418", flag: "https://flagcdn.com/w320/la.png" },
    { code: "LV", code3: "LVA", name: "Latvia", number: "428", flag: "https://flagcdn.com/w320/lv.png" },
    { code: "LB", code3: "LBN", name: "Lebanon", number: "422", flag: "https://flagcdn.com/w320/lb.png" },
    { code: "LS", code3: "LSO", name: "Lesotho", number: "426", flag: "https://flagcdn.com/w320/ls.png" },
    { code: "LR", code3: "LBR", name: "Liberia", number: "430", flag: "https://flagcdn.com/w320/lr.png" },
    { code: "LY", code3: "LBY", name: "Libya", number: "434", flag: "https://flagcdn.com/w320/ly.png" },
    { code: "LI", code3: "LIE", name: "Liechtenstein", number: "438", flag: "https://flagcdn.com/w320/li.png" },
    { code: "LT", code3: "LTU", name: "Lithuania", number: "440", flag: "https://flagcdn.com/w320/lt.png" },
    { code: "LU", code3: "LUX", name: "Luxembourg", number: "442", flag: "https://flagcdn.com/w320/lu.png" },
    { code: "MO", code3: "MAC", name: "Macao", number: "446", flag: "https://flagcdn.com/w320/mo.png" },
    { code: "MG", code3: "MDG", name: "Madagascar", number: "450", flag: "https://flagcdn.com/w320/mg.png" },
    { code: "MW", code3: "MWI", name: "Malawi", number: "454", flag: "https://flagcdn.com/w320/mw.png" },
    { code: "MY", code3: "MYS", name: "Malaysia", number: "458", flag: "https://flagcdn.com/w320/my.png" },
    { code: "MV", code3: "MDV", name: "Maldives", number: "462", flag: "https://flagcdn.com/w320/mv.png" },
    { code: "ML", code3: "MLI", name: "Mali", number: "466", flag: "https://flagcdn.com/w320/ml.png" },
    { code: "MT", code3: "MLT", name: "Malta", number: "470", flag: "https://flagcdn.com/w320/mt.png" },
    { code: "MH", code3: "MHL", name: "Marshall Islands", number: "584", flag: "https://flagcdn.com/w320/mh.png" },
    { code: "MQ", code3: "MTQ", name: "Martinique", number: "474", flag: "https://flagcdn.com/w320/mq.png" },
    { code: "MR", code3: "MRT", name: "Mauritania", number: "478", flag: "https://flagcdn.com/w320/mr.png" },
    { code: "MU", code3: "MUS", name: "Mauritius", number: "480", flag: "https://flagcdn.com/w320/mu.png" },
    { code: "YT", code3: "MYT", name: "Mayotte", number: "175", flag: "https://flagcdn.com/w320/yt.png" },
    { code: "MX", code3: "MEX", name: "Mexico", number: "484", flag: "https://flagcdn.com/w320/mx.png" },
    { code: "FM", code3: "FSM", name: "Micronesia", number: "583", flag: "https://flagcdn.com/w320/fm.png" },
    { code: "MD", code3: "MDA", name: "Moldova", number: "498", flag: "https://flagcdn.com/w320/md.png" },
    { code: "MC", code3: "MCO", name: "Monaco", number: "492", flag: "https://flagcdn.com/w320/mc.png" },
    { code: "MN", code3: "MNG", name: "Mongolia", number: "496", flag: "https://flagcdn.com/w320/mn.png" },
    { code: "ME", code3: "MNE", name: "Montenegro", number: "499", flag: "https://flagcdn.com/w320/me.png" },
    { code: "MS", code3: "MSR", name: "Montserrat", number: "500", flag: "https://flagcdn.com/w320/ms.png" },
    { code: "MA", code3: "MAR", name: "Morocco", number: "504", flag: "https://flagcdn.com/w320/ma.png" },
    { code: "MZ", code3: "MOZ", name: "Mozambique", number: "508", flag: "https://flagcdn.com/w320/mz.png" },
    { code: "MM", code3: "MMR", name: "Myanmar", number: "104", flag: "https://flagcdn.com/w320/mm.png" },
    { code: "NA", code3: "NAM", name: "Namibia", number: "516", flag: "https://flagcdn.com/w320/na.png" },
    { code: "NR", code3: "NRU", name: "Nauru", number: "520", flag: "https://flagcdn.com/w320/nr.png" },
    { code: "NP", code3: "NPL", name: "Nepal", number: "524", flag: "https://flagcdn.com/w320/np.png" },
    { code: "NL", code3: "NLD", name: "Netherlands", number: "528", flag: "https://flagcdn.com/w320/nl.png" },
    { code: "NC", code3: "NCL", name: "New Caledonia", number: "540", flag: "https://flagcdn.com/w320/nc.png" },
    { code: "NZ", code3: "NZL", name: "New Zealand", number: "554", flag: "https://flagcdn.com/w320/nz.png" },
    { code: "NI", code3: "NIC", name: "Nicaragua", number: "558", flag: "https://flagcdn.com/w320/ni.png" },
    { code: "NE", code3: "NER", name: "Niger", number: "562", flag: "https://flagcdn.com/w320/ne.png" },
    { code: "NG", code3: "NGA", name: "Nigeria", number: "566", flag: "https://flagcdn.com/w320/ng.png" },
    { code: "NU", code3: "NIU", name: "Niue", number: "570", flag: "https://flagcdn.com/w320/nu.png" },
    { code: "NF", code3: "NFK", name: "Norfolk Island", number: "574", flag: "https://flagcdn.com/w320/nf.png" },
    { code: "MP", code3: "MNP", name: "Northern Mariana Islands", number: "580", flag: "https://flagcdn.com/w320/mp.png" },
    { code: "NO", code3: "NOR", name: "Norway", number: "578", flag: "https://flagcdn.com/w320/no.png" },
    { code: "OM", code3: "OMN", name: "Oman", number: "512", flag: "https://flagcdn.com/w320/om.png" },
    { code: "PK", code3: "PAK", name: "Pakistan", number: "586", flag: "https://flagcdn.com/w320/pk.png" },
    { code: "PW", code3: "PLW", name: "Palau", number: "585", flag: "https://flagcdn.com/w320/pw.png" },
    { code: "PS", code3: "PSE", name: "Palestine", number: "275", flag: "https://flagcdn.com/w320/ps.png" },
    { code: "PA", code3: "PAN", name: "Panama", number: "591", flag: "https://flagcdn.com/w320/pa.png" },
    { code: "PG", code3: "PNG", name: "Papua New Guinea", number: "598", flag: "https://flagcdn.com/w320/pg.png" },
    { code: "PY", code3: "PRY", name: "Paraguay", number: "600", flag: "https://flagcdn.com/w320/py.png" },
    { code: "PE", code3: "PER", name: "Peru", number: "604", flag: "https://flagcdn.com/w320/pe.png" },
    { code: "PH", code3: "PHL", name: "Philippines", number: "608", flag: "https://flagcdn.com/w320/ph.png" },
    { code: "PN", code3: "PCN", name: "Pitcairn", number: "612", flag: "https://flagcdn.com/w320/pn.png" },
    { code: "PL", code3: "POL", name: "Poland", number: "616", flag: "https://flagcdn.com/w320/pl.png" },
    { code: "PT", code3: "PRT", name: "Portugal", number: "620", flag: "https://flagcdn.com/w320/pt.png" },
    { code: "PR", code3: "PRI", name: "Puerto Rico", number: "630", flag: "https://flagcdn.com/w320/pr.png" },
    { code: "QA", code3: "QAT", name: "Qatar", number: "634", flag: "https://flagcdn.com/w320/qa.png" },
    { code: "MK", code3: "MKD", name: "Republic of North Macedonia", number: "807", flag: "https://flagcdn.com/w320/mk.png" },
    { code: "RO", code3: "ROU", name: "Romania", number: "642", flag: "https://flagcdn.com/w320/ro.png" },
    { code: "RU", code3: "RUS", name: "Russian Federation", number: "643", flag: "https://flagcdn.com/w320/ru.png" },
    { code: "RW", code3: "RWA", name: "Rwanda", number: "646", flag: "https://flagcdn.com/w320/rw.png" },
    { code: "RE", code3: "REU", name: "Réunion", number: "638", flag: "https://flagcdn.com/w320/re.png" },
    { code: "BL", code3: "BLM", name: "Saint Barthélemy", number: "652", flag: "https://flagcdn.com/w320/bl.png" },
    { code: "SH", code3: "SHN", name: "Saint Helena, Ascension and Tristan da Cunha", number: "654", flag: "https://flagcdn.com/w320/sh.png" },
    { code: "KN", code3: "KNA", name: "Saint Kitts and Nevis", number: "659", flag: "https://flagcdn.com/w320/kn.png" },
    { code: "LC", code3: "LCA", name: "Saint Lucia", number: "662", flag: "https://flagcdn.com/w320/lc.png" },
    { code: "MF", code3: "MAF", name: "Saint Martin (French part)", number: "663", flag: "https://flagcdn.com/w320/mf.png" },
    { code: "PM", code3: "SPM", name: "Saint Pierre and Miquelon", number: "666", flag: "https://flagcdn.com/w320/pm.png" },
    { code: "VC", code3: "VCT", name: "Saint Vincent and the Grenadines", number: "670", flag: "https://flagcdn.com/w320/vc.png" },
    { code: "WS", code3: "WSM", name: "Samoa", number: "882", flag: "https://flagcdn.com/w320/ws.png" },
    { code: "SM", code3: "SMR", name: "San Marino", number: "674", flag: "https://flagcdn.com/w320/sm.png" },
    { code: "ST", code3: "STP", name: "Sao Tome and Principe", number: "678", flag: "https://flagcdn.com/w320/st.png" },
    { code: "SA", code3: "SAU", name: "Saudi Arabia", number: "682", flag: "https://flagcdn.com/w320/sa.png" },
    { code: "SN", code3: "SEN", name: "Senegal", number: "686", flag: "https://flagcdn.com/w320/sn.png" },
    { code: "RS", code3: "SRB", name: "Serbia", number: "688", flag: "https://flagcdn.com/w320/rs.png" },
    { code: "SC", code3: "SYC", name: "Seychelles", number: "690", flag: "https://flagcdn.com/w320/sc.png" },
    { code: "SL", code3: "SLE", name: "Sierra Leone", number: "694", flag: "https://flagcdn.com/w320/sl.png" },
    { code: "SG", code3: "SGP", name: "Singapore", number: "702", flag: "https://flagcdn.com/w320/sg.png" },
    { code: "SX", code3: "SXM", name: "Sint Maarten (Dutch part)", number: "534", flag: "https://flagcdn.com/w320/sx.png" },
    { code: "SK", code3: "SVK", name: "Slovakia", number: "703", flag: "https://flagcdn.com/w320/sk.png" },
    { code: "SI", code3: "SVN", name: "Slovenia", number: "705", flag: "https://flagcdn.com/w320/si.png" },
    { code: "SB", code3: "SLB", name: "Solomon Islands", number: "090", flag: "https://flagcdn.com/w320/sb.png" },
    { code: "SO", code3: "SOM", name: "Somalia", number: "706", flag: "https://flagcdn.com/w320/so.png" },
    { code: "ZA", code3: "ZAF", name: "South Africa", number: "710", flag: "https://flagcdn.com/w320/za.png" },
    { code: "GS", code3: "SGS", name: "South Georgia and the South Sandwich Islands", number: "239", flag: "https://flagcdn.com/w320/gs.png" },
    { code: "SS", code3: "SSD", name: "South Sudan", number: "728", flag: "https://flagcdn.com/w320/ss.png" },
    { code: "ES", code3: "ESP", name: "Spain", number: "724", flag: "https://flagcdn.com/w320/es.png" },
    { code: "LK", code3: "LKA", name: "Sri Lanka", number: "144", flag: "https://flagcdn.com/w320/lk.png" },
    { code: "SD", code3: "SDN", name: "Sudan", number: "729", flag: "https://flagcdn.com/w320/sd.png" },
    { code: "SR", code3: "SUR", name: "Suriname", number: "740", flag: "https://flagcdn.com/w320/sr.png" },
    { code: "SJ", code3: "SJM", name: "Svalbard and Jan Mayen", number: "744", flag: "https://flagcdn.com/w320/sj.png" },
    { code: "SE", code3: "SWE", name: "Sweden", number: "752", flag: "https://flagcdn.com/w320/se.png" },
    { code: "CH", code3: "CHE", name: "Switzerland", number: "756", flag: "https://flagcdn.com/w320/ch.png" },
    { code: "SY", code3: "SYR", name: "Syrian Arab Republic", number: "760", flag: "https://flagcdn.com/w320/sy.png" },
    { code: "TW", code3: "TWN", name: "Taiwan", number: "158", flag: "https://flagcdn.com/w320/tw.png" },
    { code: "TJ", code3: "TJK", name: "Tajikistan", number: "762", flag: "https://flagcdn.com/w320/tj.png" },
    { code: "TZ", code3: "TZA", name: "Tanzania", number: "834", flag: "https://flagcdn.com/w320/tz.png" },
    { code: "TH", code3: "THA", name: "Thailand", number: "764", flag: "https://flagcdn.com/w320/th.png" },
    { code: "TL", code3: "TLS", name: "Timor-Leste", number: "626", flag: "https://flagcdn.com/w320/tl.png" },
    { code: "TG", code3: "TGO", name: "Togo", number: "768", flag: "https://flagcdn.com/w320/tg.png" },
    { code: "TK", code3: "TKL", name: "Tokelau", number: "772", flag: "https://flagcdn.com/w320/tk.png" },
    { code: "TO", code3: "TON", name: "Tonga", number: "776", flag: "https://flagcdn.com/w320/to.png" },
    { code: "TT", code3: "TTO", name: "Trinidad and Tobago", number: "780", flag: "https://flagcdn.com/w320/tt.png" },
    { code: "TN", code3: "TUN", name: "Tunisia", number: "788", flag: "https://flagcdn.com/w320/tn.png" },
    { code: "TR", code3: "TUR", name: "Turkey", number: "792", flag: "https://flagcdn.com/w320/tr.png" },
    { code: "TM", code3: "TKM", name: "Turkmenistan", number: "795", flag: "https://flagcdn.com/w320/tm.png" },
    { code: "TC", code3: "TCA", name: "Turks and Caicos Islands", number: "796", flag: "https://flagcdn.com/w320/tc.png" },
    { code: "TV", code3: "TUV", name: "Tuvalu", number: "798", flag: "https://flagcdn.com/w320/tv.png" },
    { code: "UG", code3: "UGA", name: "Uganda", number: "800", flag: "https://flagcdn.com/w320/ug.png" },
    { code: "UA", code3: "UKR", name: "Ukraine", number: "804", flag: "https://flagcdn.com/w320/ua.png" },
    { code: "AE", code3: "ARE", name: "United Arab Emirates", number: "784", flag: "https://flagcdn.com/w320/ae.png" },
    { code: "GB", code3: "GBR", name: "United Kingdom", number: "826", flag: "https://flagcdn.com/w320/gb.png" },
    { code: "UM", code3: "UMI", name: "United States Minor Outlying Islands", number: "581", flag: "https://flagcdn.com/w320/um.png" },
    { code: "US", code3: "USA", name: "United States of America", number: "840", flag: "https://flagcdn.com/w320/us.png" },
    { code: "UY", code3: "URY", name: "Uruguay", number: "858", flag: "https://flagcdn.com/w320/uy.png" },
    { code: "UZ", code3: "UZB", name: "Uzbekistan", number: "860", flag: "https://flagcdn.com/w320/uz.png" },
    { code: "VU", code3: "VUT", name: "Vanuatu", number: "548", flag: "https://flagcdn.com/w320/vu.png" },
    { code: "VE", code3: "VEN", name: "Venezuela", number: "862", flag: "https://flagcdn.com/w320/ve.png" },
    { code: "VN", code3: "VNM", name: "Viet Nam", number: "704", flag: "https://flagcdn.com/w320/vn.png" },
    { code: "VG", code3: "VGB", name: "Virgin Islands (British)", number: "092", flag: "https://flagcdn.com/w320/vg.png" },
    { code: "VI", code3: "VIR", name: "Virgin Islands (U.S.)", number: "850", flag: "https://flagcdn.com/w320/vi.png" },
    { code: "WF", code3: "WLF", name: "Wallis and Futuna", number: "876", flag: "https://flagcdn.com/w320/wf.png" },
    { code: "EH", code3: "ESH", name: "Western Sahara", number: "732", flag: "https://flagcdn.com/w320/eh.png" },
    { code: "YE", code3: "YEM", name: "Yemen", number: "887", flag: "https://flagcdn.com/w320/ye.png" },
    { code: "ZM", code3: "ZMB", name: "Zambia", number: "894", flag: "https://flagcdn.com/w320/zm.png" },
    { code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716", flag: "https://flagcdn.com/w320/zw.png" },
    { code: "AX", code3: "ALA", name: "Åland Islands", number: "248", flag: "https://flagcdn.com/w320/ax.png" }
  ];


  export const excludedCountries = [
    "AF", "AS", "AI", "AQ", "AG", "AR", "AM", "AW", "AU", "BS", "BH", "BD", "BB", "BY", "BZ", "BM", 
    "BT", "BO", "BQ", "BW", "BV", "IO", "BN", "CV", "KH", "CA", "KY", "CF", "CL", "CN", "CX", "CC", 
    "CO", "CD", "CU", "CW", "DJ", "DM", "EC", "SV", "GQ", "ER", "ET", "FK", "FJ", "GF", "PF", "TF", 
    "GH", "GD", "GP", "GU", "GG", "GN", "GW", "GY", "HT", "HM", "VA", "HK", "IN", "ID", "IM", "JM", 
    "JP", "JO", "KZ", "KE", "KI", "KP", "KR", "KG", "LA", "LV", "LS", "LR", "LY", "MO", "MW", "MY", 
    "MV", "MH", "MQ", "YT", "MX", "FM", "MD", "MN", "MS", "MM", "NA", "NR", "NP", "NC", "NZ", "NG", 
    "NU", "NF", "MP", "OM", "PW", "PS", "PA", "PG", "PY", "PE", "PH", "PN", "PR", "MK", "RU", "RW", 
    "RE", "BL", "SH", "KN", "LC", "MF", "PM", "VC", "WS", "SM", "ST", "SA", "RS", "SC", "SG", "SX", 
    "SB", "ZA", "GS", "SS", "SD", "LK", "SR", "SJ", "SY", "TW", "TJ", "TZ", "TH", "TK", "TO", "TT", 
    "TN", "TR", "TM", "TC", "TV", "UG", "UM", "US", "UY", "UZ", "VU", "VE", "VN", "VG", "VI", "WF", 
    "EH", "YE"
  ];
  