import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-investment',
  templateUrl: './resume-investment.component.html',
  styleUrls: ['./resume-investment.component.css']
})
export class ResumeInvestmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
