<div class="menu-desktop bradius">
    <div class="menu-desktop-1">
        <a [routerLink]="['/oportunidades']">
            <div>
                <img src="../../../assets/img/logo.png" alt="Domoblock logotipo" style="height: 5.3rem; width: auto; margin-left: 1.1rem;">
            </div>
        </a>

        <hr> 

        <div class="px-3 mb-4" *ngIf="loggedIn">
            <h5 style="margin: 0 !important;">Cartera digital</h5>
            <p class="m-0 no-hover">SALDO DISPONIBLE</p>
            <div class="d-flex my-2 justify-content-between align-items-center">
                <h5 style="margin: 0 !important;" *ngIf="isLoadingBalance" class="loading-text">Consultando balance</h5>

                <h3 style="margin: 0 !important;" *ngIf="!balanceError && !isLoadingBalance">{{balance | customCurrency}}</h3>
                <img src="../../../assets/img/plus-circle.svg" alt="add-funds"
                style="padding: 0; width: 45px; cursor: pointer;"
                [routerLink]="['/cartera-digital/euros']" *ngIf="!balanceError && !isLoadingBalance">

                <h5 style="margin: 0 !important;" *ngIf="balanceError && !isLoadingBalance">Error al consultar el balance</h5>
                <i class="fa fa-refresh fa-2x" aria-hidden="true" style="cursor: pointer; color: #F09502;"
                 *ngIf="!isLoadingBalance" (click)="checkFunds()"></i>
            </div>
        </div>

        <p *ngIf="loggedIn && adminFlag" [routerLink]="['/administracion']" routerLinkActive="activo"><i class="bi bi-gear"></i> Administración</p>
        <p [routerLink]="['/oportunidades']" routerLinkActive="activo"><i class="bi bi-lightning-charge-fill"></i> Oportunidades</p>
        <p *ngIf="loggedIn" [routerLink]="['/mis-inversiones']" routerLinkActive="activo"><i class="bi bi-piggy-bank"></i> Mis inversiones</p>
        <!-- <p [routerLink]="['/documentacion']" routerLinkActive="activo"><i class="bi bi-file-pdf-fill"></i> Documentos</p> -->
        <!-- <p *ngIf="loggedIn && !kycFlag" (click)="openModalKyc()" routerLinkActive="activo"><i class="bi bi-person-bounding-box"></i>Continuar KYC</p> -->
        <p *ngIf="loggedIn" (click)="digitalWalletCollapsed = !digitalWalletCollapsed" [ngClass]="{'padding-p-collapse': digitalWalletCollapsed}">
            <i class="bi bi-credit-card"></i>
            Cartera digital
            <i class="bi bi-chevron-down chevron-icon" style="margin-left: 0.4vw;" 
            [class.rotate]="digitalWalletCollapsed"></i>
        </p>
        <div class="card-body" [ngbCollapse]="!digitalWalletCollapsed" style="padding-left: 1.1vw;">
            <p [routerLink]="['/cartera-digital/blockchain']" class="padding-p-collapse" routerLinkActive="activo">En blockchain</p>
            <p [routerLink]="['/cartera-digital/euros']" routerLinkActive="activo">En euros</p>
        </div>
        <p *ngIf="loggedIn" (click)="helpCollapsed = !helpCollapsed" [ngClass]="{'padding-p-collapse': helpCollapsed}">
            <i class="bi bi-question-lg"></i>
            Ayuda
            <i class="bi bi-chevron-down chevron-icon" style="margin-left: 0.4vw;" 
            [class.rotate]="helpCollapsed"></i>
        </p>
        <div class="card-body" [ngbCollapse]="!helpCollapsed" style="padding-left: 1.1vw;">
            <p class="padding-p-collapse">Tutorial de inversión</p>
            <p class="padding-p-collapse">Guía de inversión</p>
            <p [routerLink]="['/contacto']" >Contáctanos</p>
        </div>
        
        <p *ngIf="loggedIn" [routerLink]="['/perfil']" routerLinkActive="activo"><i class="bi bi-person-circle"></i> Mi perfil</p>
        <!-- <p *ngIf="loggedIn" (click)="logout()"><i class="bi bi-x-octagon-fill" style="color:brown;"></i> Cerrar sesión</p> -->
    </div>
</div>

<!-- <div class="card noticia bradius" style="width: 18rem;">
    <div class="card-body" style="background-color:#07070a;">
        <p class="card-title text-white mb-2">Tutorial de Inversión</p>
        <iframe id="iframeYoutube" *ngIf="urlYoutube !== null" width="100%" [src]="urlYoutube | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</div> -->

<div class="rrss">
    <a [href]="urlLinkedIn" target="_blank">
        <img src="../../../assets/img/linkedin.png" alt="Linkedin Domoblock">
    </a>
    <a [href]="urlTwitter" target="_blank">
        <img src="../../../assets/img/twitter.png" alt="Twitter Domoblock">
    </a>
    <a [href]="urlInstagram" target="_blank">
        <img src="../../../assets/img/instagram.png" alt="Instagram Domoblock">
    </a>
    <a [href]="urlTelegram" target="_blank">
        <img src="../../../assets/img/telegram.png" alt="Telegram Domoblock">
    </a>
</div>

