import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { PaymentService } from 'src/app/services/payment.service';
import Swal from 'sweetalert2';

interface Transaction {
  id: string;
  type: string;
  status: string;
  date: any;
  amount: number; 
  excutionDate: string;
  creditedFunds: number;
  fee?: number;
  account?: string;
}

@Component({
  selector: 'app-investments-transactions',
  templateUrl: './investments-transactions.component.html',
  styleUrls: ['./investments-transactions.component.css']
})
export class InvestmentsTransactionsComponent implements OnInit {

  transactions: any[] = []
  isLoading = true;
  typeMovementSelected = 'TRANSFER';

  constructor(
    private paymentSrv: PaymentService, 
    private authSrv: AuthService,
    private loaderSrv: LoaderService
  ) { }

  ngOnInit(): void {
    this.getMovements();
  }

  getMovements(typeMovement: string = 'TRANSFER'){
    this.transactions = [];
    this.typeMovementSelected = typeMovement;
    const uid = this.authSrv.userAuth.getValue().uid;
    const params = `${uid}?type=${typeMovement}`;

    this.loaderSrv.showSpinner('Cargando movimientos');
    this.isLoading = true;
    this.paymentSrv.getMovements(params).subscribe((res: any)  => {
    this.transactions = res.filter((transaction: any) => transaction.project && transaction.project.title);
    this.transactions = this.transactions.map((transaction: any) => {
      return {
        ...transaction,
        date: this.getDate(transaction.timestamp),
        amount: transaction.amount / 100,
      }
    });
    //   if (typeMovement === 'PAYIN') {
    //     const tempArray = res.filter((transaction : Transaction) => transaction.status == 'SUCCEEDED');
    //     console.log(tempArray);
    //     this.transactions = tempArray.map((transaction: Transaction) => {
    //       console.log((1 - (transaction.creditedFunds / transaction.amount)) * 100)
    //       const data = {
    //         ...transaction,
    //         fee: (1 - (transaction.creditedFunds / transaction.amount)) * 100,
    //         date: new Date(transaction.date * 1000).toLocaleDateString()
    //       }
    //       data.amount = data.amount / 100
    //       return data;
    //     });
    // }

    // if (typeMovement === 'PAYOUT') {
    //   this.transactions = res.map((transaction: Transaction) => {
    //     const data = {
    //       ...transaction,
    //       date: new Date(transaction.date)
    //     }
    //     data.amount = data.amount / 100
    //     return data;
    //   });
    // }

    //new Date(transaction.date) | date:'dd-MM-yyyy'

      console.log(this.transactions);
      this.loaderSrv.hideSpinner();
      this.isLoading = false;
    }, (err) => {
      console.log(err);
      Swal.fire('Error', 'Ha ocurrido un error al cargar los movimientos', 'error');
      this.loaderSrv.hideSpinner();
      this.isLoading = false;
    });
  }

  getDate(date: any): string {
    return new Date(+date).toLocaleDateString();
  }
}
