import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

  selectedOption: string = 'user';
  selectedWidth: string = '0px';
  selectedLeft: string = '0px';

  @ViewChild('user') user!: ElementRef;
  @ViewChild('changePassword') changePassword!: ElementRef;
  @ViewChild('investmentMethod') investmentMethod!: ElementRef;
  @ViewChild('alertSettings') alertSettings!: ElementRef;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.updateIndicator();
  }

  ngAfterViewInit(): void {
    this.updateIndicator();
    this.cdr.detectChanges();
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.updateIndicator();
  }

  updateIndicator() {
    let element: HTMLElement | undefined;
    switch (this.selectedOption) {
      case 'user':
        element = this.user?.nativeElement;
        break;
      case 'changePassword':
        element = this.changePassword?.nativeElement;
        break;
      case 'investmentMethod':
        element = this.investmentMethod?.nativeElement;
        break;
      case 'alertSettings':
        element = this.alertSettings?.nativeElement;
        break;
      default:
        return;
    }

    if (element) {
      const originalWidth = element.offsetWidth;
      const increasedWidth = originalWidth; //this.selectedOption == 'user' ? originalWidth * 1.65 : originalWidth * 1.4;
      const offsetLeft = element.offsetLeft - (increasedWidth - originalWidth) / 2;

      this.selectedWidth = `${increasedWidth}px`;
      this.selectedLeft = `${offsetLeft}px`;
    }
  }

}
