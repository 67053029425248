import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexLegend
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  legend: ApexLegend; 
};

@Component({
  selector: 'app-my-investments',
  templateUrl: './my-investments.component.html',
  styleUrls: ['./my-investments.component.css']
})
export class MyInvestmentsComponent implements OnInit {

  resumenFlex: number = 1;
  movimientosFlex: number = 1;
  inversionesFlex: number = 1;

  @ViewChild('resume') resume!: ElementRef;
  @ViewChild('transactions') transactions!: ElementRef;
  @ViewChild('investments') investments!: ElementRef;
  @ViewChild('documents') documents!: ElementRef;
  selectedOption: string = 'resume';
  selectedWidth: string = '0px';
  selectedLeft: string = '0px';

  @ViewChild('chart') chart: any;
  public chartOptions: Partial<ChartOptions>;

  constructor(
    private cdr: ChangeDetectorRef,
  ) 
  
  {
    this.chartOptions = {
      series: [40, 30, 30],
      chart: {
        type: 'donut'
      },
      labels: ['test1', 'test2', 'test3'],
      legend: {
        show: false // Ocultar la leyenda
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            chart: {
              height: 150
            },
            legend: {
              show: false,
              position: 'bottom'
            }
          }
        }
      ]
    };
  }

  ngOnInit(): void {
    this.updateIndicator();
  }

  ngAfterViewInit(): void {
    this.updateIndicator();
    this.cdr.detectChanges();
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.updateIndicator();
  }



  updateIndicator() {
    let element: HTMLElement | undefined;
    switch (this.selectedOption) {
      case 'resume':
        element = this.resume?.nativeElement;
        break;
      case 'transactions':
        element = this.transactions?.nativeElement;
        break;
      case 'investments':
        element = this.investments?.nativeElement;
        break;
      case 'documents':
        element = this.documents?.nativeElement;
        break;
      default:
        return;
    }

    if (element) {
      const originalWidth = element.offsetWidth;
      const increasedWidth = originalWidth * 1.4;
      const offsetLeft = element.offsetLeft - (increasedWidth - originalWidth) / 2;

      this.selectedWidth = `${increasedWidth}px`;
      this.selectedLeft = `${offsetLeft}px`;
    }
  }

}
