import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import Swal from 'sweetalert2';
import * as jsonManager from '../../abis/Manager.json';
import { environment } from '../../../environments/environment';
const EthersUtils = require('ethers-utils');

@Component({
  selector: 'app-set-rol',
  templateUrl: './set-rol.component.html',
  styleUrls: ['./set-rol.component.css']
})
export class SetRolComponent {
  administrators: any[] = [];
  formNewRol: FormGroup = this.createFormNewRol();
  ourAddress: string | undefined;
  web3: any | undefined;
  abiManager: any = jsonManager;
  contractManager: any | undefined;

  constructor(private afs: AngularFirestore, private connSrv: ConnectionsService) {
    this.afs.collection('administrators').valueChanges().subscribe((res: any) => {
      if (res !== undefined && res !== null) {
        this.administrators = res;
      }
    });

    this.web3 = this.connSrv.web3Instance;

    this.contractManager = new this.web3.eth.Contract(this.abiManager.abi, environment.config.ADDRESS_MANAGER);

    this.connSrv.addressUser.subscribe((res: string) => {
      this.ourAddress = res;
    });

  }

  createFormNewRol() {
    return new FormGroup({
      email: new FormControl(null, [Validators.required]),
      walletAddress: new FormControl(null, [Validators.required]),
      role: new FormControl(null, [Validators.required])
    })
  }

  get email() { return this.formNewRol?.get('email'); }
  get walletAddress() { return this.formNewRol?.get('walletAddress'); }
  get role() { return this.formNewRol?.get('role'); }

  newRol() {
    if (this.formNewRol.invalid) { 
      this.formNewRol.markAllAsTouched();
      return; 
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    this.afs.collection('administrators', ref => ref.where('email', '==', this.email?.getRawValue())).get()
      .subscribe((res: any) => {
        if (res.docs.length > 0) {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Este usuario ya tiene un rol'
          });

          return;
        } else {
          this.afs.collection('users', ref => ref.where('email', '==', this.email?.getRawValue())).get().subscribe(async (res: any) => {
            debugger;
            if(res?.empty){
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Usuario no encontrado'
              });
            }
            const uid = res.docs[0].data().uid;
            const email = res.docs[0].data().email;
            const displayName = res.docs[0].data().displayName;

            const doc: any = {
              uid: uid,
              email: email,
              displayName: displayName,
              admin: true,
              walletAddress: this.walletAddress?.getRawValue(),
              role: this.role?.getRawValue()
            } 

            const rol = EthersUtils.keccak256(EthersUtils.toUtf8Bytes(this.role?.getRawValue()));

            const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
              return Number(block.baseFeePerGas) + 100000000000; 
            });

            this.contractManager.methods.addRol(
              rol, 
              this.walletAddress?.getRawValue()
            ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
              .then(() => {
                this.afs.collection('administrators').doc(uid).set(doc, {merge: true})
                  .then(() => {
                    this.formNewRol.reset();
                    
                    Swal.close();
                    Swal.fire({
                      icon: 'success',
                      text: `El rol del usuario se ha sido subido correctamente`
                    });
                  })
                  .catch(() => { 
                    Swal.close();
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'Algo ha ido mal!'
                    });
                  });
              })
              .catch(() => {
                Swal.close();
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Algo ha ido mal!'
                });
              })
          });
        }
      });
  }

  async deleteRol(doc: any) {
    const rol = EthersUtils.keccak256(EthersUtils.toUtf8Bytes(doc.role));

    const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
      return Number(block.baseFeePerGas) + 100000000000; 
    });

    this.contractManager.methods.revokeRol(
      rol,
      doc.walletAddress
    ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
      .then(() => {
        this.afs.collection('administrators').doc(doc.uid).delete();
      });
  }
}