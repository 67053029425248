<div >

    <div *ngIf="(currentStep < 6 && isCompany) || (currentStep >= 7 && isCompany && currentStage == 2) || (currentStep < 7 && isPerson && currentStage == 1) || (currentStep > 7 && currentStage == 2 && isPerson)"
         class="d-flex align-items-center" style="gap: 5px; cursor: pointer;" (click)="backStep()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        <span>ANTERIOR</span>
    </div>

    <div style="padding: 25px 10px;">
    <!-- header -->
    <div class="register-header">

        <!-- paso 1 -->
        <div style="position: relative;">
            <div class="backgroundProgress">
                <mat-progress-spinner color="accent"  value="100" [diameter]="spinnerDiameter"></mat-progress-spinner>
            </div>
            <div class="frontProgress">
                <mat-progress-spinner mode="determinate" [value]="stage1Progress" [diameter]="spinnerDiameter"></mat-progress-spinner>
            </div>
            <div class="iconProgress">
                <i *ngIf="isPerson" class="bi bi-person-fill fs-1" style="width: calc(1.375rem + 1.5vw)"></i>
                <img *ngIf="isCompany" src="assets/img/modal-kyc/company.svg" alt="cash"  style="width: calc(1.375rem + 1.5vw)"/>
            </div>
        </div>

        <!-- paso 2 -->
        <div style="position: relative;">
            <div class="backgroundProgress">
                <mat-progress-spinner color="accent"  value="100" [diameter]="spinnerDiameter"></mat-progress-spinner>
            </div>
            <div class="frontProgress">
                <mat-progress-spinner mode="determinate" [value]="stage2Progress" [diameter]="spinnerDiameter"></mat-progress-spinner>
            </div>
            <div class="iconProgress">
                <img *ngIf="isPerson" src="assets/img/cash.svg" alt="cash"  style="width: 40px;"/>
                <img *ngIf="isCompany" src="assets/img/modal-kyc/addperson.svg" alt="cash"  style="width: 40px;"/>
            </div>
        </div>

        <!-- paso 3 -->
        <div style="position: relative;">
            <div class="backgroundProgress">
                <mat-progress-spinner color="accent"  value="100" [diameter]="spinnerDiameter"></mat-progress-spinner>
            </div>
            <div class="frontProgress">
                <mat-progress-spinner mode="determinate" [value]="stage3Progress" [diameter]="spinnerDiameter"></mat-progress-spinner>
            </div>
            <div class="iconProgress">
                <i class="bi bi-play-btn fs-1"></i>
            </div>
        </div>
    </div>

    <!-- content -->
    <div class="mt-5" *ngIf="isPerson">
        <div *ngIf="currentStage == 1">
        <div class="text-center">
            <h2>Información personal</h2>
            <p>Completa con tu información personal básica</p>
        </div>

        <div class="row mt-5" *ngIf="currentStep == 1">
            <div class="col-md-6">
                <label for="name">Nombre</label>
                <input type="text" class="form-control" placeholder="Nombre" [(ngModel)]="userName">
            </div>
            <div class="col-md-6 mt-4 mt-md-0">
                <label for="name">Apellidos</label>
                <input type="text" class="form-control" placeholder="Apellidos" [(ngModel)]="userLastname">
            </div>
            <div class="col-md-12 mt-4">
                <label for="birdthdate">Fecha de nacimiento</label>
                <input type="date" class="form-control" placeholder="Fecha de nacimiento" [(ngModel)]="userBirthdate">
            </div>
        </div>

        <div class="row mt-5" *ngIf="currentStep == 2">
            <div class="col-md-6">
                <label for="nacionalidad">Nacionalidad</label>
                <!-- <select name="nacionalidad" id="nacionalidad" type="text" class="form-control" [(ngModel)] = "nationality" >
                    <ng-container *ngFor="let invest of countries">
                      <option [value]="invest.value">{{invest.viewValue}}</option>
                    </ng-container>
                </select> -->

                <ng-select [(ngModel)]="nationality" name="nacionalidad" id="nacionalidad" [clearable]="false">
                    <ng-option *ngFor="let country of countries" [value]="country.value">{{country.viewValue}}</ng-option>
                </ng-select>
            </div>
            <div class="col-md-6 mt-4 mt-md-0">
                <label for="nacionalidad">País de residencia</label>
                <!-- <select name="nacionalidad" id="nacionalidad" type="text" class="form-control" [(ngModel)] = "residence">
                    <ng-container *ngFor="let invest of countries">
                      <option [value]="invest.value">{{invest.viewValue}}</option>
                    </ng-container>
                </select> -->

                <ng-select [(ngModel)]="residence" name="nacionalidad" id="nacionalidad" [clearable]="false">
                    <ng-option *ngFor="let country of countries" [value]="country.value">{{country.viewValue}}</ng-option>
                </ng-select>
            </div>
        </div>

        <div class="row mt-5" *ngIf="currentStep == 3">
            <div class="col-md-12">
                <label for="nacionalidad">Dirección</label>
                <input type="text" class="form-control" placeholder="Escriba aquí su dirección" [(ngModel)] = "address">
            </div>
            <div class="col-md-6  mt-4">
                <label for="nacionalidad">Ciudad</label>
                <input type="text" class="form-control" placeholder="Ciudad" [(ngModel)] = "city">
            </div>
            <div class="col-md-6  mt-4">
                <label for="nacionalidad">Código postal</label>
                <input type="text" class="form-control" placeholder="Escriba su código postal" [(ngModel)]="mailAddress">
            </div>
        </div>

        <div class="row my-5 pb-4" *ngIf="currentStep == 4">
            <div class="col-md-2 col-4">
                <label for="nacionalidad" class="mb-1">Prefijo</label>
                <!-- <input type="text" class="form-control" placeholder="+34" [(ngModel)]="phonePrefix"> -->
                <ng-select [items]="phonePrefixes" bindLabel="dialCode" bindValue="dialCode"
                    [(ngModel)]="phonePrefix" [clearable]="false" >
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <img [src]="item.flag" width="20" height="20"> {{item.dialCode}}
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-10 col-8">
                <label for="nacionalidad" class="mb-1">Número de teléfono</label>
                <input type="text" class="form-control" placeholder="Escriba aquí su teléfono" [(ngModel)]="phoneNumber">
            </div>
        </div>

        <div class="row mt-5" *ngIf="currentStep == 5">
            <div class="col-md-6 d-flex justify-content-center align-items-center flex-column">
                <img src="../../../../assets/img/modal-kyc/usa-flag.svg" alt="usa flag"/>
                <p>¿Ciudadano Estadounidense?</p>
                <div class="d-flex justify-content-center align-items-center" style="gap: 10px;">
                    <button class="btn" [ngClass]="{'btn-outline-dark': isUsaCitizen !== 'Si', 'btn-dark': isUsaCitizen === 'Si'}" (click)="isUsaCitizen = 'Si'">
                        SI
                    </button>
                    <button class="btn" [ngClass]="{'btn-outline-dark': isUsaCitizen !== 'No', 'btn-dark': isUsaCitizen === 'No'}" (click)="isUsaCitizen = 'No'">
                        NO
                    </button>
                </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center align-items-center flex-column mt-4 mt-md-0">
                <img src="../../../../assets/img/modal-kyc/special-person.svg" alt="usa flag"/>
                <p>¿Persona políticamente expuesta?</p>
                <div class="d-flex justify-content-center align-items-center" style="gap: 10px;">
                    <button class="btn" [ngClass]="{'btn-outline-dark': isPoliticallyExposed !== 'Si', 'btn-dark': isPoliticallyExposed === 'Si'}" (click)="isPoliticallyExposed = 'Si'">
                        SI
                    </button>
                    <button class="btn" [ngClass]="{'btn-outline-dark': isPoliticallyExposed !== 'No', 'btn-dark': isPoliticallyExposed === 'No'}" (click)="isPoliticallyExposed = 'No'">
                        NO
                    </button>
                </div>
            </div>
        </div>

        </div>

        <div *ngIf="currentStage == 2">
            <div class="text-center">
                <h2>Información financiera</h2>
                <p>Completa con tu información y conocimientos financieros.</p>
            </div>


            <div class="row mt-5" *ngIf="currentStep == 7">
                <div class="col-md-12">
                    <label for="name">Profesión</label>
                    <input type="text" class="form-control" placeholder="Escriba aquí su profesión" [(ngModel)]="profession">
                </div>
                <div class="col-md-6 mt-4">
                    <label for="estudios">Nivel de estudios</label>
                    <!-- <select name="estudios" id="estudios"  type="text" class="form-control" value="" [(ngModel)]="studyLvl">
                        <option disabled selected>Estudios</option>
                        <option value="No tengo estudios">No tengo estudios</option>
                        <option value="Enseñanza obligatoria">Enseñanza obligatoria</option>
                        <option value="Formación profesional/grado">Formación profesional/grado</option>
                        <option value="Educación universitaria o superior">Educación universitaria o superior</option>
                    </select> -->

                    <ng-select [(ngModel)]="studyLvl" name="estudios" id="estudios" [clearable]="false">
                        <ng-option value="No tengo estudios">No tengo estudios</ng-option>
                        <ng-option value="Enseñanza obligatoria">Enseñanza obligatoria</ng-option>
                        <ng-option value="Formación profesional/grado">Formación profesional/grado</ng-option>
                        <ng-option value="Educación universitaria o superior">Educación universitaria o superior</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-6 mt-4">
                    <label for="origen_fondos">Mayor fuente de ingresos periódicos</label>
                    <!-- <select  name="origen_fondos" id="origen_fondos" class="form-control" [(ngModel)]="mainSourceFunds">
                        <option disabled selected>Ingresos</option>
                        <option value="Actividad laboral">Actividad laboral</option>
                        <option value="Herencia o donación">Herencia o donación</option>
                        <option value="Inversiones en negocios">Inversiones en negocios</option>
                        <option value="Recolocar inversiones financieras">Recolocar inversiones financieras</option>
                        <option value="Otros">Otros</option>
                    </select> -->

                    <ng-select [(ngModel)]="mainSourceFunds" name="origen_fondos" id="origen_fondos" [clearable]="false">
                        <ng-option value="Actividad laboral">Actividad laboral</ng-option>
                        <ng-option value="Herencia o donación">Herencia o donación</ng-option>
                        <ng-option value="Inversiones en negocios">Inversiones en negocios</ng-option>
                        <ng-option value="Recolocar inversiones financieras">Recolocar inversiones financieras</ng-option>
                        <ng-option value="Otros">Otros</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 8">
                <div class="col-md-6 mt-4">
                    <label for="name">¿Cuáles son tus ingresos periódicos?</label>
                    <!-- <select name="fuente_ingresos" id="fuente_ingresos" type="text" class="form-control" value="" [(ngModel)]="sourceFunds">
                        <option disabled selected>Ingresos periódicos</option>
                        <option value="No tengo fuentes de ingresos">No tengo fuentes de ingresos</option>
                        <option value="Actividad laboral">Actividad laboral</option>
                        <option value="Prestación por discapacidad o jubilación">Prestación por discapacidad o jubilación</option>
                        <option value="Rendimientos de inversiones financieras">Rendimientos de inversiones financieras</option>
                    </select> -->

                    <ng-select [(ngModel)]="sourceFunds" name="nacionalidad" id="nacionalidad" [clearable]="false">
                        <ng-option value="Menos de 25.000€/año">Menos de 25.000€/año</ng-option>
                        <ng-option value="Entre 25.001 €/año y 50.000 €/año">Entre 25.001 €/año y 50.000 €/año</ng-option>
                        <ng-option value="Entre 50.001 €/año y 100.000 €/año">Entre 50.001 €/año y 100.000 €/año</ng-option>
                        <ng-option value="Más de 100,000 €/año">Más de 100,000 €/año</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-6 mt-4">
                    <label for="birdthdate">Origen de los fondos a invertir</label>
                    <!-- <select name="origen_fondos" id="origen_fondos" class="form-control" [(ngModel)]="sourceFundsOrigin">
                        <option disabled selected>Origen</option>
                        <option value="Actividad laboral">Actividad laboral</option>
                        <option value="Herencia o donación">Herencia o donación</option>
                        <option value="Inversiones en negocios">Inversiones en negocios</option>
                        <option value="Recolocar inversiones financieras">Recolocar inversiones financieras</option>
                        <option value="Otros">Otros</option>
                      </select> -->

                    <ng-select [(ngModel)]="sourceFundsOrigin" name="nacionalidad" id="nacionalidad" [clearable]="false">
                        <ng-option value="Recolocar inversiones financieras">Recolocar inversiones financieras</ng-option>
                        <ng-option value="Inversiones por negocios">Inversiones por negocios</ng-option>
                        <ng-option value="Herencia o donación">Herencia o donación</ng-option>
                        <ng-option value="Otro origen">Otro origen</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-12 mt-4">
                    <label for="birdthdate">¿Qué porcentaje representa la cantidad que quieres invertir en Domoblock?</label>
                    <!-- <select name="target_invest_patri" id="target_invest_patri" type="text" class="form-control" value="" [(ngModel)]="fundsPercentage">
                        <option disabled selected>Escoja el porcentaje</option>
                        <option value="menos el 25%">Menos el 25%</option>
                        <option value="Entre el 25% y 50%">Entre el 25% y 50%</option>
                        <option value="Entre el 50% y 75%">Entre el 50% y 75%</option>
                        <option value="Mas del 75%">Mas del 75%</option>
                    </select> -->

                    <ng-select [(ngModel)]="fundsPercentage" name="nacionalidad" id="nacionalidad" [clearable]="false">
                        <ng-option value="menos el 25%">Menos el 25%</ng-option>
                        <ng-option value="Entre el 25% y 50%">Entre el 25% y 50%</ng-option>
                        <ng-option value="Entre el 50% y 75%">Entre el 50% y 75%</ng-option>
                        <ng-option value="Mas del 75%">Mas del 75%</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 9">
                <div class="col-md-6 mt-4">
                    <label for="name">¿Qué fluctuaciones asumiría?</label>
                    <!-- <select name="negative_support" id="negative_support" type="text" class="form-control" value="" [(ngModel)]="negative_support">
                        <option disabled selected>Fluctuaciones</option>
                        <option value="Mi objetivo es preservar el capital y no estoy dispuesto a sumir rentabilidades negativas">Mi objetivo es preservar el capital y no estoy dispuesto a sumir rentabilidades negativas</option>
                        <option value="Hasta el -7% de mi capital">Hasta el -7% de mi capital</option>
                        <option value="Hasta el -14% de mi capital">Hasta el -14% de mi capital</option>
                        <option value="Hasta el -20% de mi capital">Hasta el -20% de mi capital</option>
                    </select> -->

                    <ng-select [(ngModel)]="negative_support" name="nacionalidad" id="nacionalidad" [clearable]="false">
                        <ng-option value="Mi objetivo es preservar el capital y no estoy dispuesto a sumir rentabilidades negativas">Mi objetivo es preservar el capital y no estoy dispuesto a sumir rentabilidades negativas</ng-option>
                        <ng-option value="Hasta el -7% de mi capital">Hasta el -7% de mi capital</ng-option>
                        <ng-option value="Hasta el -14% de mi capital">Hasta el -14% de mi capital</ng-option>
                        <ng-option value="Hasta el -20% de mi capital">Hasta el -20% de mi capital</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-6 mt-4">
                    <label for="birdthdate">¿Qué objetivos persigue al invertir?</label>
                    <!-- <select name="target_invest" id="target_invest" type="text" class="form-control" [(ngModel)]="target_invest">
                        <option disabled selected>Objetivos</option>
                        <option value="Preservar el poder adquisitivo">Preservar el poder adquisitivo</option>
                        <option value="Equilibrio entre rentabilidad y estabilidad, asumiendo un riesgo moderado">Equilibrio entre rentabilidad y estabilidad, asumiendo un riesgo moderado</option>
                        <option value="Obtener altas rentabilidades asumiendo fuertes riesgos">Obtener altas rentabilidades asumiendo fuertes riesgos</option>
                    </select> -->

                    <ng-select [(ngModel)]="target_invest" name="nacionalidad" id="nacionalidad" [clearable]="false">
                        <ng-option value="Preservar el poder adquisitivo">Preservar el poder adquisitivo</ng-option>
                        <ng-option value="Equilibrio entre rentabilidad y estabilidad, asumiendo un riesgo moderado">
                            Equilibrio entre rentabilidad y estabilidad, asumiendo un riesgo moderado
                        </ng-option>
                        <ng-option value="Obtener altas rentabilidades asumiendo fuertes riesgos">
                            Obtener altas rentabilidades asumiendo fuertes riesgos
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-md-12 mt-4">
                    <label for="birdthdate">¿Cuál es el horizonte temporal de su inversión?</label>
                    <!-- <select name="target_invest" id="target_invest" type="text" class="form-control" [(ngModel)]="invest_goal">
                        <option disabled selected>Escoja el porcentaje</option>
                        <option value="Preservar el poder adquisitivo">Preservar el poder adquisitivo</option>
                        <option value="Equilibrio entre rentabilidad y estabilidad, asumiendo un riesgo moderado">Equilibrio entre rentabilidad y estabilidad, asumiendo un riesgo moderado</option>
                        <option value="Obtener altas rentabilidades asumiendo fuertes riesgos">Obtener altas rentabilidades asumiendo fuertes riesgos</option>
                    </select> -->

                    <ng-select [(ngModel)]="invest_goal" name="nacionalidad" id="nacionalidad" [clearable]="false">
                        <ng-option value="Menos de 6 meses">Menos de 6 meses</ng-option>
                        <ng-option value="Entre 6 meses y 2 años">Entre 6 meses y 2 años</ng-option>
                        <ng-option value="Entre 2 y 5 años">Entre 2 y 5 años</ng-option>
                        <ng-option value="Más de 5 años">Más de 5 años</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="mt-5 d-flex flex-column justify-content-center align-items-center" *ngIf="currentStep == 10">
                <div class="row">
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center text-center">
                        <p>¿Has invertido en empresas no cotizadas gestionadas por ti o por otros?</p>
                        <div class="d-flex justify-content-center align-items-center" style="gap: 15px;">
                            <button class="btn" [ngClass]="{'btn-outline-dark': businessManagement !== 'Si', 'btn-dark': businessManagement === 'Si'}" (click)="businessManagement = 'Si'">
                                NO
                            </button>
                            <button class="btn" [ngClass]="{'btn-outline-dark': businessManagement !== 'No', 'btn-dark': businessManagement === 'No'}" (click)="businessManagement = 'No'">
                                SI
                            </button>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex flex-column justify-content-center align-items-center text-center mt-4 mt-md-0">
                        <p>¿Conoces los instrumentos financieros que ofrece Domoblock y los riesgos que la inversión en los mismos conlleva?</p>
                        <div class="d-flex justify-content-center align-items-center" style="gap: 15px;">
                            <button class="btn" [ngClass]="{'btn-outline-dark': knowledge_quiebra !== 'No', 'btn-dark': knowledge_quiebra === 'No'}" (click)="clickKnowledge_quiebra()">
                                NO
                            </button>
                            <button class="btn" [ngClass]="{'btn-outline-dark': knowledge_quiebra !== 'Si', 'btn-dark': knowledge_quiebra === 'Si'}" (click)="knowledge_quiebra = 'Si'">
                                SI
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-5 d-flex flex-column justify-content-center align-items-center" *ngIf="currentStep == 11">
                <p>¿Deseas ser tratado cómo un inversor profesional? (Opcional)</p>
                <div class="d-flex justify-content-center align-items-center" style="gap: 15px;">
                    <button class="btn" [ngClass]="{'btn-outline-dark': isProfessionalInvestor !== 'Si', 'btn-dark': isProfessionalInvestor === 'Si'}" (click)="isProfessionalInvestor = 'Si'">
                        SI
                    </button>
                    <button class="btn" [ngClass]="{'btn-outline-dark': isProfessionalInvestor !== 'No', 'btn-dark': isProfessionalInvestor === 'No'}" (click)="isProfessionalInvestor = 'No'">
                        NO
                    </button>
                </div>
                <div class="d-flex mt-4 align-items-center" style="gap: 20px;">
                    <img src="../../../../assets/img/modal-kyc/warnings.svg" />
                    <p>
                        Complete este paso, solo si su actividad habitual es invertir en valores u otros
                        instrumentos financieros o si inviertes <strong>más de 10.000€ por operación.</strong>
                    </p>
                </div>
            </div>

            <div class="mt-5 d-flex flex-column justify-content-center align-items-center" *ngIf="currentStep == 12">
                <div class="d-flex justify-content-center align-items-center" style="gap: 15px;">
                    <mat-checkbox class="example-margin custom-checkbox" [(ngModel)]="requiredField"></mat-checkbox>
                    Solicito que con carácter general se me considere “Cliente Profesional” a los
                    efectos de la prestación de todos los servicios de inversión y de los servicios
                    auxiliares sobre todos los instrumentos recogidos en su programa de actividades.
                </div>
                <div class="d-flex justify-content-center flex-column mt-4" style="gap: 0.4rem;">
                    <h3 style="margin: 0;">Marca la casilla correspondiente si cumple con lo siguiente;</h3>
                    <div class="d-flex justify-content-center align-items-center" style="gap: 15px;">
                        <mat-checkbox class="example-margin custom-checkbox" [(ngModel)]="lastoper"></mat-checkbox>
                        He realizado operaciones de volumen significativo en el mercado de valores,
                        con frecuencia media de más de diez operaciones por trimestre durante los
                        cuatro trimestres anteriores
                    </div>
                    <div class="d-flex align-items-center" style="gap: 15px;">
                        <mat-checkbox class="example-margin custom-checkbox" [(ngModel)]="sup500k"></mat-checkbox>
                        El valor del efectivo y de los valores que poseo es superior a 500.000€
                    </div>
                    <div class="d-flex justify-content-center align-items-center" style="gap: 15px;">
                        <mat-checkbox class="example-margin custom-checkbox" [(ngModel)]="oper_job"></mat-checkbox>
                        Ocupo, o he ocupado durante al menos un año, un cargo profesional en el sector
                        financiero que requiere conocimientos sobre las operaciones o servicios que me prestaran.
                    </div>
                </div>
            </div>

        </div>

    </div>

    <!-- steps and stages for company -->
    <div class="mt-5" *ngIf="isCompany">
        <div *ngIf="currentStage == 1">
            <div class="text-center">
                <h2>Información empresa</h2>
                <p>Completa con la información de tu empresa</p>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 1">
                <div class="col-md-12">
                    <label for="name">Nombre</label>
                    <input type="text" class="form-control" placeholder="Nombre de la empresa" [(ngModel)]="companyName">
                </div>
                <div class="col-md-6 mt-4">
                    <label for="name">CIF</label>
                    <input type="text" class="form-control" placeholder="Escriba el CIF" [(ngModel)]="cif">
                </div>
                <div class="col-md-6 mt-4">
                    <label for="birdthdate">Escoga la forma jurídica</label>
                    <ng-select [(ngModel)]="companyType" name="nacionalidad" id="nacionalidad"
                        [clearable]="false" placeholder="Sociedad limitada (S.L.)">
                        <ng-option value="Sociedad Anónima">Sociedad Anónima</ng-option>
                        <ng-option value="Sociedad Limitada">Sociedad Limitada</ng-option>
                        <ng-option value="Sociedad Colectiva">Sociedad Colectiva</ng-option>
                        <ng-option value="Sociedad Comanditaria">Sociedad Comanditaria</ng-option>
                        <ng-option value="Sociedad Comanditaria por acciones">Sociedad Comanditaria por acciones</ng-option>
                        <ng-option value="Sociedad de Responsabilidad Limitada">Sociedad de Responsabilidad Limitada</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 2">
                <div class="col-md-6">
                    <label for="nacionalidad">Domicilio social</label>
                    <input type="text" class="form-control" placeholder="Escriba aquí su dirección" [(ngModel)] = "companyAddress">
                </div>
                <div class="col-md-6 mt-4 mt-md-0">
                    <label for="name">Pais de la empresa</label>
                    <ng-select [(ngModel)]="companyResidence" name="nacionalidad" id="nacionalidad" [clearable]="false">
                        <ng-option *ngFor="let country of countries" [value]="country.value">{{country.viewValue}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-6  mt-4">
                    <label for="nacionalidad">Ciudad</label>
                    <input type="text" class="form-control" placeholder="Ciudad" [(ngModel)] = "companyCity">
                </div>
                <div class="col-md-6  mt-4">
                    <label for="nacionalidad">Código postal</label>
                    <input type="text" class="form-control" placeholder="Escriba su código postal" [(ngModel)]="companyMailAddress">
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 3">
                <div class="text-center">
                    <h2>Suba los siguentes archivos:</h2>
                    <p>1. Escritura de constitución</p>
                    <p>2. Acta de titularidad real </p>
                    <p>3. Otros documentos que creas que deban ser conocidos</p>
                    <div *ngIf="uploadedEscrituraFile" style="padding-top: 5px;">
                        <label for="file" class="btn btn-outline-dark" style="width: 200px;">
                            <span class="fw-bolder">
                                Subir Escritura
                            </span>
                        </label>
                        <input type="file" id="file" class="inputfile" (change)="uploadEscritura($event)" style="padding-top: 5px;"/>
                    </div>
                    <div *ngIf="uploadedActaFile" style="padding-top: 5px;">
                        <label for="file" class="btn btn-outline-dark" style="width: 200px;">
                            <span class="fw-bolder">
                                Subir Acta
                            </span>
                        </label>
                        <input type="file" id="file" class="inputfile" (change)="uploadActa($event)"/>
                    </div>
                    <div *ngIf="uploadedOtrosFile" style="padding-top: 5px;">
                        <label for="file" class="btn btn-outline-dark" style="width: 200px;">
                            <span class="fw-bolder">
                                Subir otros docs
                            </span>
                        </label>
                        <input type="file" id="file" class="inputfile" (change)="uploadDocs($event)"/>
                    </div>

                    <div class="d-flex flex-column justify-content-center align-items-center mt-2" style="gap: 5px">
                        <p *ngIf="files.length > 0" style="font-weight: bold; margin: 0 !important;">Archivos subidos:</p>
                        <div class="d-flex" style="gap: 10px">
                            <div *ngFor="let item of files; let i = index">
                                <p style="margin: 0 !important; font-weight: bold;">{{item.label}}</p>
                                <div class="card-file d-flex flex-column justify-content-center align-items-center">
                                    <img src="assets/img/modal-kyc/pdf.svg" />
                                    ...{{item.file.name.substr(-8)}}
                                    <div style="position: absolute; bottom: -10px;">
                                        <img src="assets/img/modal-kyc/delete.svg" (click)="removeFile(i)" style="cursor: pointer;" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <input type="file" id="file" class="inputfile" (change)="onFileChange($event)" #fileInput multiple />
                        <div *ngIf="files.length > 0" class="add-file" (click)="fileInput.click()">
                            <img src="assets/img/modal-kyc/add-file.svg" />
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="mt-5 d-flex flex-column justify-content-center align-items-center" *ngIf="currentStep == 4">
                <p>¿Deseas ser tratado cómo un inversor profesional? (Opcional)</p>
                <div class="d-flex justify-content-center align-items-center" style="gap: 15px;">
                    <button class="btn" [ngClass]="{'btn-outline-dark': companyIsProfessionalInvestor !== 'Si', 'btn-dark': companyIsProfessionalInvestor === 'Si'}" (click)="companyIsProfessionalInvestor = 'Si'">
                        SI
                    </button>
                    <button class="btn" [ngClass]="{'btn-outline-dark': companyIsProfessionalInvestor !== 'No', 'btn-dark': companyIsProfessionalInvestor === 'No'}" (click)="companyIsProfessionalInvestor = 'No'">
                        NO
                    </button>
                </div>
                <div class="d-flex mt-4 align-items-center" style="gap: 20px;">
                    <img src="../../../../assets/img/modal-kyc/warnings.svg" />
                    <p>
                        Complete este paso, solo si su actividad habitual es invertir en valores u otros
                        instrumentos financieros o si inviertes <strong>más de 10.000€ por operación.</strong>
                    </p>
                </div>
            </div>

            <div class="mt-5 d-flex flex-column justify-content-center align-items-center" *ngIf="currentStep == 5">
                <div class="d-flex justify-content-center align-items-center" style="gap: 15px;">
                    <mat-checkbox class="example-margin custom-checkbox" [(ngModel)]="requiredField"></mat-checkbox>
                    Solicito que con carácter general se me considere “Cliente Profesional” a los efectos
                    de la prestación de todos los servicios de inversión y de los servicios auxiliares sobre
                    todos los instrumentos recogidos en su programa de actividades.
                </div>
                <div class="d-flex justify-content-center flex-column mt-4" style="gap: 0.4rem;">
                    <h3 style="margin: 0;">Marca la casilla correspondiente si cumple con lo siguiente;</h3>
                    <div class="d-flex justify-content-center align-items-center" style="gap: 15px;">
                        <mat-checkbox class="example-margin custom-checkbox" [(ngModel)]="lastoper"></mat-checkbox>
                        El total de las partidas del activo de la Sociedad es igual o superior a 20 millones de euros.
                    </div>
                    <div class="d-flex align-items-center" style="gap: 15px;">
                        <mat-checkbox class="example-margin custom-checkbox" [(ngModel)]="sup40m"></mat-checkbox>
                        El importe de la cifra de negocios anual de la Sociedad es igual o superior a 40 millones de euros.
                    </div>
                    <div class="d-flex justify-content-center align-items-center" style="gap: 15px;">
                        <mat-checkbox class="example-margin custom-checkbox" [(ngModel)]="oper_job"></mat-checkbox>
                        Los recursos propios de la Sociedad son iguales o superiores a 2 millones de euros.
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="currentStage == 2">

            <div *ngIf="currentStep <= 12" class="text-center">
                <h2>Representante y titulares</h2>
                <div class="d-flex gap-2 justify-content-center">
                    <p *ngIf="currentStep == 6">Información del representante.</p>
                    <p *ngIf="currentStep > 6 && currentStep != 12 ">Completa con tu información personal básica.</p>
                    <p *ngIf="currentStep == 12"> Información de titulares</p>
                    <div class="position-relative" *ngIf="currentStep == 6">
                        <div class="position-absolute info-tooltip" 
                            matTooltip="El representante es alguien con poderes suficientes 
                            como para representar a la sociedad en sus operaciones."
                            aria-label="Button that displays a tooltip when focused or hovered over">
                            ?
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="currentStep > 12" class="text-center">
                <h2>Representante y titulares</h2>
                <div class="d-flex gap-2 justify-content-center">
                    <p *ngIf="currentStep == 13"> Información de titulares</p>
                    <p *ngIf="currentStep > 13">Titular N° {{index}} de {{realRepresentantsQuantity}}</p>
                    <div class="position-relative" *ngIf="currentStep == 13">
                        <div class="position-absolute info-tooltip" 
                            matTooltip="El titular real es aquella/s persona/s físicas que posean o controlen directa o 
                            indirectamente un porcentaje superior al 25% del capital o poder de voto de la sociedad."
                            aria-label="Button that displays a tooltip when focused or hovered over">
                            ?
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 6">
                <div class="col-md-12">
                    <label for="name">Título con el que actuas en la empresa</label>
                    <input type="text" class="form-control" placeholder="Escriba título" [(ngModel)]="companyRepresentTitle">
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 7">
                <div class="col-md-6">
                    <label for="name">Nombre</label>
                    <input type="text" class="form-control" placeholder="Escriba aquí su nombre" [(ngModel)]="companyRepresentName">
                </div>
                <div class="col-md-6 mt-4 mt-md-0">
                    <label for="name">Apellidos</label>
                    <input type="text" class="form-control" placeholder="Escriba aquí sus apellidos" [(ngModel)]="companyRepresentLastname">
                </div>
                <div class="col-md-12 mt-4">
                    <label for="birdthdate">Fecha de nacimiento</label>
                    <input type="date" class="form-control" placeholder="Fecha de nacimiento" [(ngModel)]="companyRepresentBirthday">
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 8">
                <div class="col-md-6">
                    <label for="nacionalidad">Nacionalidad</label>
                    <!-- <select name="nacionalidad" id="nacionalidad" type="text" class="form-control" [(ngModel)] = "companyRepresentNationality" >
                        <ng-container *ngFor="let invest of countries">
                          <option [value]="invest.value">{{invest.viewValue}}</option>
                         </ng-container>
                    </select> -->

                    <ng-select [(ngModel)]="companyRepresentNationality" name="nacionalidad" id="nacionalidad" [clearable]="false">
                        <ng-option *ngFor="let country of countries" [value]="country.value">{{country.viewValue}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-6 mt-4 mt-md-0 mb-2 mb-md-0">
                    <label for="name">País de residencia</label>
                    <!-- <select name="nacionalidad" id="nacionalidad" type="text" class="form-control" [(ngModel)] = "companyRepresentResidence">
                        <ng-container *ngFor="let invest of countries">
                          <option [value]="invest.value">{{invest.viewValue}}</option>
                         </ng-container>
                    </select> -->

                    <ng-select [(ngModel)]="companyRepresentResidence" name="nacionalidad" id="nacionalidad" [clearable]="false">
                        <ng-option *ngFor="let country of countries" [value]="country.value">{{country.viewValue}}</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 9">
                <div class="col-md-12">
                    <label for="nacionalidad">Dirección</label>
                    <input type="text" class="form-control" placeholder="Escriba aquí su dirección" [(ngModel)] = "companyRepresentAddress">
                </div>
                <div class="col-md-6  mt-4">
                    <label for="nacionalidad">Ciudad</label>
                    <input type="text" class="form-control" placeholder="Ciudad" [(ngModel)] = "companyRepresentCity">
                </div>
                <div class="col-md-6  mt-4">
                    <label for="nacionalidad">Código postal</label>
                    <input type="text" class="form-control" placeholder="Escriba su código postal" [(ngModel)]="companyRepresentMailAddress">
                </div>
            </div>

            <div class="row my-5 pb-4" *ngIf="currentStep == 10">
                <div class="col-md-2 col-4">
                    <label for="nacionalidad" class="mb-1">Prefijo</label>
                    <!-- <input type="text" class="form-control" placeholder="+34" [(ngModel)]="phonePrefix"> -->
                    <ng-select [items]="phonePrefixes" bindLabel="dialCode" bindValue="dialCode"
                        [(ngModel)]="phonePrefix" [clearable]="false" >
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <img [src]="item.flag" width="20" height="20"> {{item.dialCode}}
                        </ng-template>
                    </ng-select>
                </div>
                <div class="col-md-10 col-8">
                    <label for="nacionalidad" class="mb-1">Número de teléfono</label>
                    <input type="text" class="form-control" placeholder="Escriba aquí su teléfono" [(ngModel)]="companyPhoneNumber">
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 11">
                <div class="col-md-6 d-flex justify-content-center align-items-center flex-column">
                    <img src="../../../../assets/img/modal-kyc/usa-flag.svg" alt="usa flag"/>
                    <p>¿Ciudadano Estadounidense?</p>
                    <div class="d-flex justify-content-center align-items-center" style="gap: 10px;">
                        <button class="btn" [ngClass]="{'btn-outline-dark': companyRepresentantisUsaCitizen !== 'Si', 'btn-dark': companyRepresentantisUsaCitizen === 'Si'}" (click)="companyRepresentantisUsaCitizen = 'Si'">
                            SI
                        </button>
                        <button class="btn" [ngClass]="{'btn-outline-dark': companyRepresentantisUsaCitizen !== 'No', 'btn-dark': companyRepresentantisUsaCitizen === 'No'}" (click)="companyRepresentantisUsaCitizen = 'No'">
                            NO
                        </button>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-center align-items-center flex-column mt-4 mt-md-0">
                    <img src="../../../../assets/img/modal-kyc/special-person.svg" alt="usa flag"/>
                    <p>¿Persona políticamente expuesta?</p>
                    <div class="d-flex justify-content-center align-items-center" style="gap: 10px;">
                        <button class="btn" [ngClass]="{'btn-outline-dark': companyRepresentantisPoliticallyExposed !== 'Si', 'btn-dark': companyRepresentantisPoliticallyExposed === 'Si'}" (click)="companyRepresentantisPoliticallyExposed = 'Si'">
                            SI
                        </button>
                        <button class="btn" [ngClass]="{'btn-outline-dark': companyRepresentantisPoliticallyExposed !== 'No', 'btn-dark': companyRepresentantisPoliticallyExposed === 'No'}" (click)="companyRepresentantisPoliticallyExposed = 'No'">
                            NO
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 12">
                <div class="col-md-12 d-flex justify-content-center align-items-center flex-column">
                    <div class="d-flex gap-2">
                        <p>¿El titular real es único y coincide con el representante? </p>
                        <div class="position-relative">
                            <div class="position-absolute info-tooltip" 
                                matTooltip="El titular real es aquella/s persona/s físicas que posean o controlen directa o 
                                indirectamente un porcentaje superior al 25% del capital o poder de voto de la sociedad."
                                aria-label="Button that displays a tooltip when focused or hovered over">
                                ?
                            </div>
                        </div>
                    </div>
                        
                    <div class="d-flex justify-content-center align-items-center" style="gap: 10px;">
                        <button class="btn" [ngClass]="{'btn-outline-dark': isRealRepresentant !== 'Si', 'btn-dark': isRealRepresentant === 'Si'}" (click)="isRealRepresentant = 'Si'">
                            SI
                        </button>
                        <button class="btn" [ngClass]="{'btn-outline-dark': isRealRepresentant !== 'No', 'btn-dark': isRealRepresentant === 'No'}" (click)="isRealRepresentant = 'No'">
                            NO
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 13">
                <div class="col-md-12">
                    <label for="name">Indica el número de titulares reales</label>
                    <input type="number" class="form-control" placeholder="Número titulares" [(ngModel)]="realRepresentantsQuantity">
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 14">
                <div class="col-md-6">
                    <label for="name">Nombre</label>
                    <input type="text" class="form-control" placeholder="Escriba aquí su nombre" [(ngModel)]="companyRepresentName">
                </div>
                <div class="col-md-6">
                    <label for="name">Apellidos</label>
                    <input type="text" class="form-control" placeholder="Escriba aquí sus apellidos" [(ngModel)]="companyRepresentLastname">
                </div>
                <div class="col-md-12 mt-4">
                    <label for="birdthdate">Fecha de nacimiento</label>
                    <input type="date" class="form-control" placeholder="Fecha de nacimiento" [(ngModel)]="companyRepresentBirthday">
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 15">
                <div class="col-md-6">
                    <label for="nacionalidad">Nacionalidad</label>
                    <!-- <select name="nacionalidad" id="nacionalidad" type="text" class="form-control" [(ngModel)] = "companyRepresentNationality" >
                        <ng-container *ngFor="let invest of countries">
                          <option [value]="invest.value">{{invest.viewValue}}</option>
                         </ng-container>
                    </select> -->

                    <ng-select [(ngModel)]="companyRepresentNationality" name="nacionalidad" id="nacionalidad" [clearable]="false">
                        <ng-option *ngFor="let country of countries" [value]="country.value">{{country.viewValue}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-md-6">
                    <label for="name">País de residencia</label>
                    <!-- <select name="nacionalidad" id="nacionalidad" type="text" class="form-control" [(ngModel)] = "companyRepresentResidence">
                        <ng-container *ngFor="let invest of countries">
                          <option [value]="invest.value">{{invest.viewValue}}</option>
                         </ng-container>
                    </select> -->

                    <ng-select [(ngModel)]="companyRepresentResidence" name="nacionalidad" id="nacionalidad" [clearable]="false">
                        <ng-option *ngFor="let country of countries" [value]="country.value">{{country.viewValue}}</ng-option>
                    </ng-select>
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 16">
                <div class="col-md-12">
                    <label for="nacionalidad">Dirección</label>
                    <input type="text" class="form-control" placeholder="Escriba aquí su dirección" [(ngModel)] = "companyRepresentAddress">
                </div>
                <div class="col-md-6  mt-4">
                    <label for="nacionalidad">Ciudad</label>
                    <input type="text" class="form-control" placeholder="Ciudad" [(ngModel)] = "companyRepresentCity">
                </div>
                <div class="col-md-6  mt-4">
                    <label for="nacionalidad">Código postal</label>
                    <input type="text" class="form-control" placeholder="Escriba su código postal" [(ngModel)]="companyRepresentMailAddress">
                </div>
            </div>

            <div class="row my-5 pb-4" *ngIf="currentStep == 17">
                <div class="col-md-2 col-4">
                    <label for="nacionalidad" class="mb-1">Prefijo</label>
                    <!-- <input type="text" class="form-control" placeholder="+34" [(ngModel)]="phonePrefix"> -->
                    <ng-select [items]="phonePrefixes" bindLabel="dialCode" bindValue="dialCode"
                        [(ngModel)]="phonePrefix" [clearable]="false" >
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <img [src]="item.flag" width="20" height="20"> {{item.dialCode}}
                        </ng-template>
                    </ng-select>
                </div>
                <div class="col-md-10 col-8">
                    <label for="nacionalidad" class="mb-1">Número de teléfono</label>
                    <input type="text" class="form-control" placeholder="Escriba aquí su teléfono" [(ngModel)]="companyPhoneNumber">
                </div>
            </div>

            <div class="row mt-5" *ngIf="currentStep == 18">
                <div class="col-md-6 d-flex justify-content-center align-items-center flex-column">
                    <img src="../../../../assets/img/modal-kyc/usa-flag.svg" alt="usa flag"/>
                    <p>¿Ciudadano Estadounidense?</p>
                    <div class="d-flex justify-content-center align-items-center" style="gap: 10px;">
                        <button class="btn" [ngClass]="{'btn-outline-dark': companyRepresentantisUsaCitizen !== 'Si', 'btn-dark': companyRepresentantisUsaCitizen === 'Si'}" (click)="companyRepresentantisUsaCitizen = 'Si'">
                            SI
                        </button>
                        <button class="btn" [ngClass]="{'btn-outline-dark': companyRepresentantisUsaCitizen !== 'No', 'btn-dark': companyRepresentantisUsaCitizen === 'No'}" (click)="companyRepresentantisUsaCitizen = 'No'">
                            NO
                        </button>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-center align-items-center flex-column">
                    <img src="../../../../assets/img/modal-kyc/special-person.svg" alt="usa flag"/>
                    <p>¿Persona políticamente expuesta?</p>
                    <div class="d-flex justify-content-center align-items-center" style="gap: 10px;">
                        <button class="btn" [ngClass]="{'btn-outline-dark': companyRepresentantisPoliticallyExposed !== 'Si', 'btn-dark': companyRepresentantisPoliticallyExposed === 'Si'}" (click)="companyRepresentantisPoliticallyExposed = 'Si'">
                            SI
                        </button>
                        <button class="btn" [ngClass]="{'btn-outline-dark': companyRepresentantisPoliticallyExposed !== 'No', 'btn-dark': companyRepresentantisPoliticallyExposed === 'No'}" (click)="companyRepresentantisPoliticallyExposed = 'No'">
                            NO
                        </button>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <!-- neocheck -->
    <div *ngIf="currentStage == 3">
        <div class="mt-5 d-flex  flex-column justify-content-center align-items-center"
            *ngIf="(isPerson && currentStep == 14) || ( isCompany && currentStep == 19)">
            <div class="text-center">
                <h1 style="margin: 0;">Proceso de Vídeo Identificación</h1>
                <p style="font-size: .75rem;">Prepara tu documento de identidad</p>
            </div>
            <img src="../../../../assets/img/modal-kyc/dni-kyc.svg" alt="dni" style="width: 300px;"/>
        </div>

        <div class="mt-5 d-flex justify-content-center align-items-center"
        *ngIf="(isPerson && currentStep == 15) || ( isCompany && currentStep == 20)">
            <div class="iframe-container"  style="position:relative;">
                <iframe (load)="delayButton()" [src]="openlink" allow="microphone; camera" style="overflow: scroll !important;position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;"></iframe>
                <div *ngIf="disablebuton"> <h1> <strong>Cargando video verificación, por favor  espere....</strong></h1> </div>
                <!--Botones atras alante-->
                <!-- <div class="bottonsToNav mt-5">
                  <div class="kycstylebuttons">
                    <button class="btn btn-success" (click)="moveToNextSlide(1)">
                      <i class="bi bi-arrow-left-circle"></i>Anterior
                    </button>
                    <button [disabled]="!disablebuton" class="btn btn-success" (click)="moveToNextSlide(3)">
                      Siguiente<i class="bi bi-arrow-right-circle"></i>
                    </button>
                  </div>
                </div> -->
              </div>
        </div>
    </div>

    <mat-dialog-actions class="d-flex justify-content-center align-items-center mt-4">

        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column; gap: 15px;">
            <button [disabled]="disablebuton" class="btn btn-success text-center" style=" width: 150px; border-radius: 30px;" (click)="nextStep()">
                <span >{{ (isPerson && currentStep == 15) || ( isCompany && currentStep == 20) ? 'Finalizar Verificacion' : 'Continuar'}}</span>
            </button>
            <div class="d-flex justify-content-center align-items-center" style="gap: 8px;">
                <div class="modal-divider"></div>
                <span mat-button class="logout-txt" (click)="close()"
                 >Cerrar sesión</span> <!-- {{currentStage == 2  ? 'Completar más tarde' : 'Cerrar sesión'}} -->
                <div class="modal-divider"></div>
            </div>

        </div>
      </mat-dialog-actions>
  

   </div>
</div>

<app-spinner *ngIf="isLoading" [loadingText]="loadingMessage"></app-spinner>