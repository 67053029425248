<div class="px-1">
    <div>
        <h3>Configurar alertas</h3>
    </div>

    <div class="mt-5">
        
        <div>
            <h5><i class="fa fa-bullhorn" aria-hidden="true"></i> Proyectos de inversión</h5>

            <div class="toggle-container mt-4">
                <div class="toggle-header">
                  <p class="m-0">Apertura de proyecto</p>
                  <p>Un proyecto nuevo se ha abierto para su financiación en la plataforma</p>
                </div>
                <!-- <form [formGroup]="form"> -->
                  <div class="toggle-switch">
                    <input type="checkbox" id="projectToggle" > 
                    <!-- formControlName="projectToggle" (change)="onToggleChange()" -->
                    <label for="projectToggle" class="toggle-label">
                      <span class="toggle-button"></span>
                    </label>
                  </div>
                <!-- </form> -->
              </div>

              <div class="divider mt-4"></div>

              <div class="toggle-container mt-4">
                <div class="toggle-header">
                  <p class="m-0">Inversión realizada</p>
                  <p>Has realizado una inversión en un proyecto/p>
                </div>
                <!-- <form [formGroup]="form"> -->
                  <div class="toggle-switch">
                    <input type="checkbox" id="projectToggle2" > 
                    <!-- formControlName="projectToggle" (change)="onToggleChange()" -->
                    <label for="projectToggle2" class="toggle-label">
                      <span class="toggle-button"></span>
                    </label>
                  </div>
                <!-- </form> -->
              </div>

              <div class="divider mt-4"></div>

              <div class="toggle-container mt-4">
                <div class="toggle-header">
                  <p class="m-0">Proyecto financiado</p>
                  <p>Un proyecto donde has invertido ha sido financiado con éxito</p>
                </div>
                <!-- <form [formGroup]="form"> -->
                  <div class="toggle-switch">
                    <input type="checkbox" id="projectToggle3" > 
                    <!-- formControlName="projectToggle" (change)="onToggleChange()" -->
                    <label for="projectToggle3" class="toggle-label">
                      <span class="toggle-button"></span>
                    </label>
                  </div>
                <!-- </form> -->
              </div>

              <div class="divider mt-4"></div>

              <div class="toggle-container mt-4">
                <div class="toggle-header">
                  <p class="m-0">Nuevos documentos</p>
                  <p>Se ha generado un nuevo documento en un proyecto donde has invertido</p>
                </div>
                <!-- <form [formGroup]="form"> -->
                  <div class="toggle-switch">
                    <input type="checkbox" id="projectToggle4" > 
                    <!-- formControlName="projectToggle" (change)="onToggleChange()" -->
                    <label for="projectToggle4" class="toggle-label">
                      <span class="toggle-button"></span>
                    </label>
                  </div>
                <!-- </form> -->
              </div>

              <div class="divider mt-4"></div>

              <div class="toggle-container mt-4">
                <div class="toggle-header">
                  <p class="m-0">Traspaso a cartera digital</p>
                  <p>Has realizado un traspaso de los retornos generados en un proyecto a tu cartera digital</p>
                </div>
                <!-- <form [formGroup]="form"> -->
                  <div class="toggle-switch">
                    <input type="checkbox" id="projectToggle5" > 
                    <!-- formControlName="projectToggle" (change)="onToggleChange()" -->
                    <label for="projectToggle5" class="toggle-label">
                      <span class="toggle-button"></span>
                    </label>
                  </div>
                <!-- </form> -->
              </div>

              <div class="divider mt-4"></div>
        </div>

        <div class="mt-5">
            <h5><i class="fa fa-user" aria-hidden="true"></i> Cuenta</h5>

            <div class="toggle-container mt-4">
                <div class="toggle-header">
                  <p class="m-0">Deposito realizado</p>
                  <p>Has recibido un deposito en tu cartera digital de Domoblock</p>
                </div>
                <!-- <form [formGroup]="form"> -->
                  <div class="toggle-switch">
                    <input type="checkbox" id="projectToggle6" > 
                    <!-- formControlName="projectToggle" (change)="onToggleChange()" -->
                    <label for="projectToggle6" class="toggle-label">
                      <span class="toggle-button"></span>
                    </label>
                  </div>
                <!-- </form> -->
              </div>

              <div class="divider mt-4"></div>

              <div class="toggle-container mt-4">
                <div class="toggle-header">
                  <p class="m-0">Retirada realizada</p>
                  <p>Has retirado fondos de tu cartera digital a tu cuenta bancaria</p>
                </div>
                <!-- <form [formGroup]="form"> -->
                  <div class="toggle-switch">
                    <input type="checkbox" id="projectToggle7" > 
                    <!-- formControlName="projectToggle" (change)="onToggleChange()" -->
                    <label for="projectToggle7" class="toggle-label">
                      <span class="toggle-button"></span>
                    </label>
                  </div>
                <!-- </form> -->
              </div>

              <div class="divider mt-4"></div>

        </div>

    </div>
</div>