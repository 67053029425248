import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-wallet',
  templateUrl: './admin-wallet.component.html',
  styleUrls: ['./admin-wallet.component.css']
})
export class AdminWalletComponent implements OnInit {

  secretKey: string = '';
  isEmergency: boolean = false;
  constructor(private readonly fns: AngularFireFunctions) { }

  ngOnInit(): void {
  }

  setEmergency(event:any): void {
    this.isEmergency = event.target.checked;
  }
  setSecretKey(){
      Swal.showLoading();   
      this.fns.httpsCallable('saveSecretKey')({secretKey:this.secretKey, isEmergency: this.isEmergency}).subscribe({next:(data)=>{
          if(data.message){
            Swal.fire({
              title:'Wallet added',
              text:'Your wallet was successfully added!',
              icon:'success'
            })
          }
      },error:(error)=>{
        Swal.hideLoading();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      }});
  }
}
