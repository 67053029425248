import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DetailRealEstateComponent } from '../../detail-real-estate/detail-real-estate.component';
import Swal from 'sweetalert2';
import { PaymentService } from 'src/app/services/payment.service';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-modal-investment-detail',
  templateUrl: './modal-investment-detail.component.html',
  styleUrls: ['./modal-investment-detail.component.css']
})
export class ModalInvestmentDetailComponent implements OnInit {

  investment: any;
  dividends: any[] = []; 
  dividendsClaimed: any[] = [];
  walletDomoblock = ''
  isLoading: boolean = true;
  claimedAmount: number = 0;
  moreInfoCollapsed: boolean = true;
  isClaiming: boolean = false;
  retention: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ModalInvestmentDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentService: PaymentService,
    private authSrv: AuthService,
    private afs: AngularFirestore

  ) { 
    this.investment = data.investment;
    this.walletDomoblock = data.walletDomoblock;
  }

  ngOnInit(): void {
    console.log(this.investment);
    this.retention = this.investment.transactions.find((transaction: any) => transaction.hasOwnProperty('retention'))?.retention || 0;
    this.verifyDividends();
  }

 async verifyDividends(){

  let verifyingDividends = [];

  console.log(this.investment.dividends)
  for (const dividend of this.investment.dividends) {
    verifyingDividends.push(this.checkDividendClaimed(dividend));
    }

    await Promise.all(verifyingDividends).then((res) => {
      for (let i = 0; i < res.length; i++) {
            if (res[i].isClaimed ) {
              this.dividendsClaimed.push(res[i].dividend);
            } else {
              this.dividends.push(res[i].dividend);
          }
      }
    }).finally(() => {
      console.log(this.dividends)
      console.log(this.dividendsClaimed)
      this.isLoading = false;
    });
  }
 
  closeDialog() {
    this.dialogRef.close();
  }

  getTimeStamp(timestamp: number) {
    return new Date(+timestamp);
  }

  claimDividends(dividend: any) {
    

    // if (this.dividendClaimed) {
    //   Swal.fire({
    //     icon: 'info',
    //     title: 'Dividendo ya reclamado',
    //     text: 'El dividendo de este proyecto ya ha sido reclamado'
    //   });
    //   return;
    // }

    const data = {
      userId: this.authSrv.userAuth.getValue().uid,
      projectId: this.investment.id,
      dividendId: dividend.dateCreation
    }

    Swal.fire({
      title: 'Reclamando dividendo...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    console.log('se ejecuto')

    this.paymentService.claimDividends(data).subscribe((res) => {
      this.dividendsClaimed = [];
      this.dividends = [];
      this.verifyDividends();
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Dividendo reclamado',
        text: 'El dividendo ha sido reclamado con éxito'
      });
    }, error => {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al reclamar el dividendo'
      });
      console.log(error)
    })
  }

  async checkDividendClaimed(dividend: any) {
    console.log(dividend.dateCreation)
    console.log(this.authSrv.userAuth.getValue().uid)

    const dividendClaimed = await firstValueFrom(this.afs.collection('dividends-user', ref => 
      ref.where('user', '==', this.authSrv.userAuth.getValue().uid).where('dividendId', '==', dividend.dateCreation)).get()) as any;
      const participationPercentage = dividendClaimed.empty ? ((this.investment.mangopayInvested / 100) /  dividend.totalAmount) * 100 : (dividendClaimed.docs[0].data().amount / this.investment.mangopayInvested ) * 100;
      let toClaim = 0;
      if (!dividendClaimed.empty) {
        this.claimedAmount += dividendClaimed.docs[0].data().amount / 100;
         
      } else{
        toClaim = dividend.retention ? ((participationPercentage - this.retention) / 100) * dividend.totalAmount : (participationPercentage / 100) * dividend.totalAmount;
      }

    return {
      dividend: {
        ...dividend,
        amountClaimed: dividendClaimed.empty ? 0 : dividendClaimed.docs[0].data().amount / 100,
        participationPercentage: participationPercentage,
        amountToClaim: dividendClaimed.empty ? toClaim: 0
      },
      isClaimed: dividendClaimed.docs.length > 0
    }
  }

}
