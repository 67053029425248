<div class="backgroundWhite">
    <div class="menu-investment">
        <div class="menu-options font font-weight-bold" style="position: relative;">
            <p #user (click)="selectOption('user')">USUARIO</p>
            <p #changePassword (click)="selectOption('changePassword')">CAMBIAR CONTRASEÑA</p>
            <p #investmentMethod (click)="selectOption('investmentMethod')">MÉTODO DE INVERSIÓN</p>
            <p #alertSettings (click)="selectOption('alertSettings')">CONFIGURAR ALERTAS</p>
        </div>
    
        <div class="divider">
            <div class="divider-section" [ngStyle]="{'width': selectedWidth, 'left': selectedLeft}"></div>
        </div>
    </div>

    <div class="scrollable-container mt-4">
        <div class="user" *ngIf="selectedOption === 'user'">
            <app-user></app-user>
        </div>

        <div class="change-password" *ngIf="selectedOption === 'changePassword'">
            <app-change-password></app-change-password>
        </div>

        <div class="investment-method" *ngIf="selectedOption === 'investmentMethod'">
            <app-investment-method></app-investment-method>
        </div>

        <div class="alert-settings" *ngIf="selectedOption === 'alertSettings'">
            <app-alerts-settings></app-alerts-settings>
        </div>
    </div>
</div>
