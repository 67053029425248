import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { KycService } from '../../../services/kyc.service';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import truncateEthAddress from 'truncate-eth-address';
import * as jsonWhitelist from '../../../abis/WhiteList.json';
import { environment } from '../../../../environments/environment';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import firebase from 'firebase/compat/app';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  web3: any | undefined;
  ourAddress: string | undefined;
  userEmail: string | undefined;
  bankAccount: string | undefined;
  userGender: string | null = null;
  abiWhitelist: any = jsonWhitelist;
  contractWhitelist: any | undefined;
  kycState: string = 'KYC sin completar';
  verification: boolean = true;
  walletsWhitelist: string[] = [];
  hideBlockchain: boolean = false;

  acreditado: boolean = false;
  loggedIn: boolean = false;
  revision: boolean = false;

  formDisplayName: FormGroup = new FormGroup({
    displayName: new FormControl(null, [Validators.required])
  });
  formNewWallet: FormGroup = new FormGroup({
    wallet: new FormControl(null, [Validators.required])
  });

  get displayName() {
    return this.formDisplayName?.get('displayName');
  }
  get wallet() {
    return this.formNewWallet?.get('wallet');
  }

  urlTerminos: string | null = null;
  urlAviso: string | null = null;
  urlPrivacidad: string | null = null;
  urlCookies: string | null = null;

  constructor(
    private afs: AngularFirestore,
    private kycSrv: KycService,
    private authSrv: AuthService,
    private connSrv: ConnectionsService,
    private fns: AngularFireFunctions,
    private crf: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    try {
      // Connect to MetaMask and get the user's address
      this.getVerification()
      this.connSrv.connect();
      
      // Subscribe to the user's address after MetaMask connection
      this.connSrv.addressUser.subscribe((res: string | null) => {
        if (res) {
          this.ourAddress = res;
        } else {
          console.warn('Address is null');
          this.ourAddress = '';
        }
      });

      // Get the web3 instance and set up the contract after web3 is ready
      this.web3 = this.connSrv.web3Instance;
      this.contractWhitelist = new this.web3.eth.Contract(this.abiWhitelist.abi, environment.config.ADDRESS_WHITELIST);

    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
    }

    // Fetch user verification data
    const uid = await this.authSrv.userAuth.getValue().uid;

    // Subscribe to user document in Firestore
    this.afs.collection('users').doc(uid).valueChanges().subscribe((res: any) => {
      this.displayName?.patchValue(res.displayName);

      if (typeof res.walletsWhitelist === 'object') {
        this.walletsWhitelist = res.walletsWhitelist;
      }
      this.hideBlockchain = res.hideBlockchain;

      // Fetch IBAN if available
      this.bankAccount = res.bankAccount;
      this.crf.detectChanges();
    });

    // Get Dapp terms, privacy, cookies, and legal information
    this.afs.collection('dapp').doc('HjdaO0nvpbsWq6LCF4fS').get().subscribe((res: any) => {
      if (res.exists) {
        this.urlTerminos = res.data().urlTerminos;
        this.urlAviso = res.data().urlAviso;
        this.urlPrivacidad = res.data().urlPrivacidad;
        this.urlCookies = res.data().urlCookies;
      }
    });

    // Subscribe to KYC success
    this.kycSrv.kycSuccess.subscribe((res: boolean) => {
      if (res) {
        this.kycState = 'KYC implementado correctamente';
        this.acreditado = true;
      } else {
        this.kycState = 'KYC no implementado';
        this.acreditado = false;
      }
      this.crf.detectChanges();
    });

    // Subscribe to KYC review
    this.kycSrv.kycReview.subscribe((res: boolean) => {
      if (res) {
        this.kycState = 'KYC Pendiente de revisión';
        this.revision = true;
      } else {
        this.kycState = 'KYC no está en revisión';
        this.revision = false;
      }
      this.crf.detectChanges();
    });
  }

  async getVerification() {
    const info = await this.authSrv.userAuth.getValue();
    if (info !== undefined) {
      this.verification = info.emailVerified;
      this.userEmail = info.email;
      this.userGender = info.gender;
      this.bankAccount = info.bankAccount;
      this.loggedIn = true;
    }
  }

  async updateHideBlockchain() {
    this.hideBlockchain = !this.hideBlockchain;
    const uid = await this.authSrv.userAuth.getValue().uid;
    this.afs.collection('users').doc(uid).set({
      hideBlockchain: this.hideBlockchain
    }, { merge: true });
  }

  async setDisplayName() {
    if (this.formDisplayName.invalid) {
      this.formDisplayName.markAsTouched();
      return;
    }

    const uid = await this.authSrv.userAuth.getValue().uid;

    this.afs.collection('users').doc(uid).set({
      displayName: this.displayName?.getRawValue()
    }, { merge: true })
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'El nombre se ha actualizado con éxito'
        });
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  verificateEmail() {
    this.authSrv.sendOtherEmailVerification();
  }

  async addWallet() {
    if (this.formNewWallet.invalid) {
      this.formNewWallet.markAsTouched();
      return;
    }

    if (this.kycState !== 'KYC implementado correctamente') {
      Swal.fire({
        icon: 'error',
        title: 'Erro KYC',
        text: 'Es necesario completar el KYC para poder añadir wallets'
      });
      return;
    }
    console.log(this.web3)
    debugger
    if (this.web3.utils.toChecksumAddress(this.ourAddress) !== this.web3.utils.toChecksumAddress(this.wallet?.getRawValue())) {
      Swal.fire({
        icon: 'error',
        title: 'Selecciona la wallet en tu MetaMask',
        text: 'La wallet insertada no coincide con la de tu MetaMask'
      });
      return;
    }

    for (let i = 0; i < this.walletsWhitelist.length; i++) {
      if (this.web3.utils.toChecksumAddress(this.walletsWhitelist[i]) === this.web3.utils.toChecksumAddress(this.wallet?.getRawValue())) {
        Swal.fire({
          icon: 'error',
          title: 'Selecciona una wallet nueva',
          text: 'La wallet insertada ya está incluida'
        });
        return;
      }
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await this.web3.eth.personal.sign(
      'Set wallet',
      this.ourAddress,
      environment.config.PASSWORD_BACK_AUTH
    )
      .then(async (signature: string) => {
        await this.fns.httpsCallable('newWalletUser')({
          address: this.wallet?.getRawValue(),
          signature: signature
        }).subscribe(async (res: any) => {
          if (!res?.success) {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo ha ido mal!'
            });
            return;
          }

          const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => {
            return Number(block.baseFeePerGas) + 100000000000;
          });

          this.contractWhitelist.methods.setStatusWithSignature(
            this.wallet?.getRawValue(),
            1,
            res.timeStamp,
            res.signature
          ).send({
            from: this.ourAddress,
            maxFeePerGas: this.web3.utils.toHex(baseFee),
            maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))
          })
            .then(async () => {
              const uid = await this.authSrv.userAuth.getValue().uid;

              if (this.walletsWhitelist.length > 0) {
                this.afs.collection('users').doc(uid).set({
                  walletsWhitelist: firebase.firestore.FieldValue.arrayUnion(this.wallet?.getRawValue())
                }, { merge: true });
              } else {
                this.afs.collection('users').doc(uid).set({
                  walletsWhitelist: [this.wallet?.getRawValue()]
                }, { merge: true });
              }

              Swal.close();
              Swal.fire({
                icon: 'success',
                text: 'La wallet se ha añadido con éxito'
              });

              this.formNewWallet.reset();
            })
            .catch((res: any) => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: 'Transacción errónea',
                text: 'Algo ha ido mal, la transacción NO se ha realizado'
              });
            });
        });
      })
      .catch(() => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  async deleteWallet(addressDelete: string) {
    if (this.web3.utils.toChecksumAddress(this.ourAddress) !== this.web3.utils.toChecksumAddress(addressDelete)) {
      Swal.fire({
        icon: 'error',
        title: 'Selecciona la wallet en tu MetaMask',
        text: 'La wallet insertada no coincide con la de tu MetaMask'
      });
      return;
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await this.web3.eth.personal.sign(
      'Set wallet',
      addressDelete,
      environment.config.PASSWORD_BACK_AUTH
    )
      .then(async (signature: string) => {
        await this.fns.httpsCallable('deleteWalletUser')({
          address: addressDelete,
          signature
        }).subscribe(async (res: any) => {
          if (!res?.success) {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo ha ido mal!'
            });
            return;
          }

          const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => {
            return Number(block.baseFeePerGas) + 100000000000;
          });

          this.contractWhitelist.methods.setStatusWithSignature(
            addressDelete,
            0,
            res.timeStamp,
            res.signature
          ).send({
            from: this.ourAddress,
            maxFeePerGas: this.web3.utils.toHex(baseFee),
            maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))
          })
            .then(async () => {
              const uid = await this.authSrv.userAuth.getValue().uid;
              this.afs.collection('users').doc(uid).set({
                walletsWhitelist: firebase.firestore.FieldValue.arrayRemove(addressDelete)
              }, { merge: true });

              Swal.close();
              Swal.fire({
                icon: 'success',
                text: 'La wallet se ha eliminado con éxito'
              });
            })
            .catch(() => {
              Swal.fire({
                icon: 'error',
                title: 'Transacción errónea',
                text: 'Algo ha ido mal, la transacción NO se ha realizado'
              });
            });
        });
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  truncate(address: string) {
    return truncateEthAddress(address);
  }

  async resetPasword() {
    this.authSrv.resetPassword(await this.authSrv.userAuth.getValue().email);
  }
}
