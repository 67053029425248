<div class="container">
    <div>
        <h3 class="mt-4">Documentos</h3>
    </div>
    <div class="mt-2 px-2" *ngIf="documents.length > 0">
        <div class="row mb-2">
            <div class="col-3 fw-bold text-muted">
                Documento
                <!-- <i [class]="'bi ' + getSortIcon('amount')" (click)="sortBy('amount')" class="domo-color" style="cursor: pointer;"></i> -->
            </div>
            <div class="col-7 fw-bold text-muted">Fecha de creación</div>
            <div class="col-2 fw-bold text-muted">Descargar documento</div>
        </div>
        <div class="list-group">
        <div *ngFor="let document of documents" class="list-group-item list-group-item-action">
            <div class="row align-items-center">
                <div class="col-3">{{ document.title }}</div>
                <div class="col-7">{{ document.creationDate  | date:'dd-MM-yyyy' }}</div>
                <div class="col-2 d-flex justify-content-center align-items-center">
                    <a [href]="document.link" target="_blank" rel="noopener noreferrer">
                        <img src="../../../../assets/img/pdf.svg"/>
                    </a>
                </div>
            </div>
        </div>
        </div>
    </div>

    <div class="mt-5 py-4 d-flex justify-content-center align-items-center flex-column" *ngIf="documents.length === 0">
        <h3 class="text-center">Todavía no has realizado una inversión</h3>
        <button type="button" class="btn btn-success text-center px-3 py-1" routerLink="/oportunidades">Invertir</button>
    </div>

    <!-- <app-pagination
        [currentPage]="currentPage"
        [totalPages]="totalPages"
        [itemsPerPage]="itemsPerPage"
        [itemsPerPageOptions]="[10, 20, 30]"
        (pageChange)="onPageChange($event)"
        (itemsPerPageChange)="onItemsPerPageChange($event)">
    </app-pagination> -->
</div>