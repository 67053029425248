<div class="add-dividends-container mb-5 pb-5">
    <section class="d-flex gap-4">
        <button class="btn btn-success bradius mb-5 buttonMid" (click)="showForm('blockchain')">Blockchain</button>
        <button class="btn btn-success bradius mb-5 buttonMid" (click)="showForm('fiat')">Fiat</button>
    </section>

    <!-- form 1 -->
    <form *ngIf="selectedForm === 'blockchain'" class="p-4 bradius" [formGroup]="formAddDividends">
        <p class="title text-center h4 w-100">Ingresar dividendos</p>
        <hr class="w-100 mb-md-5">
        <div class="mb-4">
            <label for="tokenAddress" class="form-label">Address del token (Participaciones ERC-20)</label>
            <input [class.errorInput]="tokenAddress?.invalid && (tokenAddress?.dirty || tokenAddress?.touched)" formControlName="tokenAddress" type="string" class="form-control" id="tokenAddress" placeholder="Dirección del contrato de las participaciones">
        </div>
        <div class="mb-4">
            <label for="amount" class="form-label">Cantidad en bruto de stablecoin:</label>
            <input [class.errorInput]="amount?.invalid && (amount?.dirty || amount?.touched)" formControlName="amount" type="number" class="form-control" id="amount" placeholder="Introduce la cantidad de dividendos en bruto">
        </div>
        <div class="mb-4">
            <label for="priceStableCoin" class="form-label">Precio en EUR de la stablecoin (Unidad):</label>
            <input [class.errorInput]="priceStableCoin?.invalid && (priceStableCoin?.dirty || priceStableCoin?.touched)" formControlName="priceStableCoin" type="number" class="form-control" id="priceStableCoin" placeholder="Introduce el precio en EUR">
        </div>
        <div class="mb-4 w-100">
            <label for="retentionFlag" class="form-label">Retencion</label>
            <select [class.errorInput]="retentionFlag?.invalid && (retentionFlag?.dirty || retentionFlag?.touched)" formControlName="retentionFlag" class="form-select">
                <option value="null" selected>Selecciona un opción</option>
                <option value="false">Sin retención</option>
                <option value="true">Con retención</option>
            </select>
        </div>
        <div class="form-check mb-4 w-100 toBlockchain">
            <input [class.errorInput]="finishFlag?.invalid && (finishFlag?.dirty || finishFlag?.touched)" class="form-check-input" type="checkbox" value="true" formControlName="finishFlag" id="finishFlag">
            <label class="form-check-label mb-0" style="font-size:1rem;" for="finishFlag">Último reparto de dividendos</label>
        </div>
        <button [disabled]="formAddDividends.invalid" (click)="addDividends()" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3">Invertir</button>
    </form>

    <!-- form 2 -->
    <form *ngIf="selectedForm === 'fiat'" class="p-4 bradius" [formGroup]="formAddDividendsFiat">
        <p class="title text-center h4 w-100">Ingresar dividendos (Fiat)</p>
        <hr class="w-100 mb-md-5">
        <div class="form-inline">
            <input [(ngModel)]="contractAddress" [ngModelOptions]="{standalone: true}" type="string" class="form-control input-md" id="tokenAddress" placeholder="Dirección del contrato de las participaciones">
            <button class="btn btn-success bradius buttonMid" (click)="getProjectData()">Buscar</button>
        </div>
        <div *ngIf="existsProject" class="mt-3 d-flex flex-inline">
            <div class="containerImg">
                <img [src]="imageURL" class="mb-4">
            </div>
            <div class="flex-block w-50 marginDiv">
                <p class="h4">Titulo del proyecto</p>
                <span class="custom-span">{{title}}</span>
                <p class="h4">Nombre del Token</p>
                <span class="custom-span">{{description}}</span>
                <p class="h4">Total vendido del proyecto (Blockchain+Mangopay)</p>
                <span class="custom-span">{{totalInvested}} Euros</span>
                <p class="h4">Total vendido por wallet digital</p>
                <span class="custom-span">{{amountSold}} Euros</span>
                <div class="d-flex flex-inline">
                    <input class="form-check-input" style="transform:scale(1.3)" type="checkbox" [(ngModel)]="retentionMangopay" [ngModelOptions]="{standalone: true}" id="retentionMangopay">
                    <p class="h4" style="margin-left: 10px;">Aplicar Retencion</p>
                </div>                             
                <p class="h4">Interes bruto a repartir</p>
                <input type="string" (input)="sumTotal($event)"  class="form-control input-md w-25" id="annualReturn" placeholder="ingrese el monto">
                <p class="h4">Total a repartir Neto:</p>
                <span class="custom-span">{{totalAmountMangopay}} Euros</span>
            </div>
        </div>
        <div class="d-flex flex-block justify-content-center">
            <button [disabled]="!existsProject" (click)="createDividends()" type="button" class="btn btn-success w-50 mt-5 pt-3 pb-3">Invertir</button>
        </div>
    </form>
    
    
</div>
