import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartOptions } from '../my-investments.component';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexLegend, ApexMarkers, ApexStroke, ApexTitleSubtitle, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import { DigitalWalletService } from 'src/app/services/digital-wallet.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart2: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: any; // ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-resume-investment',
  templateUrl: './resume-investment.component.html',
  styleUrls: ['./resume-investment.component.css']
})
export class ResumeInvestmentComponent implements OnInit {
  
  @ViewChild('chart') chart: any;
  @ViewChild('chart2') chart2: any;
  public chartOptions: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions2 & { series: ApexAxisChartSeries }>;
  userTotalWithdraw:any;
  balance:number = 0;
  
  
  constructor(
    private readonly digitalWalletService: DigitalWalletService, 
    private readonly afs: AngularFirestore,
    private readonly authSrv: AuthService) {
    this.chartOptions = {
      series: [40, 30, 30],
      chart: {
        type: 'donut'
      },
      labels: ['test1', 'test2', 'test3'],
      legend: {
        show: false // Ocultar la leyenda
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            chart: {
              height: 150
            },
            legend: {
              show: false,
              position: 'bottom'
            }
          }
        }
      ]
    };

    this.chartOptions2 = {
      series: [
        {
          name: "Session Duration",
          type: "line",
          data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
        },
        {
          name: "Page Views",
          type: "line",
          data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
        },
        {
          name: "Total Visits",
          type: "line",
          data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47]
        }
      ],
      chart2: {
        height: 350,
        type: "line"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 5,
        curve: "straight",
        dashArray: [0, 8, 5]
      },
      title: {
        text: "Page Statistics",
        align: "left"
      },
      legend: {
        tooltipHoverFormatter: function(val, opts) {
          return (
            val +
            " - <strong>" +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            "</strong>"
          );
        }
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        labels: {
          trim: false
        },
        categories: [
          "01 Jan",
          "02 Jan",
          "03 Jan",
          "04 Jan",
          "05 Jan",
          "06 Jan",
          "07 Jan",
          "08 Jan",
          "09 Jan",
          "10 Jan",
          "11 Jan",
          "12 Jan"
        ]
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function(val: any) {
                return val + " (mins)";
              }
            }
          },
          {
            title: {
              formatter: function(val:any) {
                return val + " per session";
              }
            }
          },
          {
            title: {
              formatter: function(val:any) {
                return val;
              }
            }
          }
        ]
      },
      grid: {
        borderColor: "#f1f1f1"
      }
    };
    
  }

  ngOnInit(): void {
    this.balance = this.digitalWalletService.getBalanceValue();
    debugger;
    (async()=>{
      this.userTotalWithdraw = await firstValueFrom(this.afs.collection('withdraws-mangopay',(ref)=>{
        return ref.where('status','==','approved')
        .where('userId','==',this.authSrv.userAuth.getValue().uid)
      }).get());
      console.log(this.userTotalWithdraw.docs)
      this.userTotalWithdraw = this.userTotalWithdraw?.docs?.reduce((acc, curr) => acc + curr.data().amount, 0);
    })()
    console.log(this.userTotalWithdraw);
    console.log(this.balance);
  }

}
