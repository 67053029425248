import { Component, OnInit } from '@angular/core';
import { CustomCurrencyPipe } from '../../shared/custom-currency.pipe';
import { PaymentService } from 'src/app/services/payment.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import Swal from 'sweetalert2';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { error } from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.css']
})
export class WithdrawComponent implements OnInit {

  bankAccounts: string[] = [];
  iban: string = '';
  banks: string[] = ['BBVA', 'Santander', 'CaixaBank', 'Bankia', 'Bankinter', 'Sabadell', 'ING', 'Openbank', 'EVO', 'N26'];
  amount: number = 0;
  formattedAmount: string = '';
  selectedAccount: string = '';
  bic: string = '';
  
  constructor(
    private paymentServive : PaymentService,
    private authSrv: AuthService,
    private loaderService: LoaderService,
    private afs: AngularFirestore,
    private modal: NgbModal
  ) { }

  ngOnInit(): void {
    this.getBancksAccounts();
  }

  deleteBankAccount(index: number) {
    this.bankAccounts.splice(index, 1);
  }

  // formatAmount(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   const value = parseFloat(input.value.replace(/[^0-9.-]+/g, ''));
  //   this.formattedAmount = new CustomCurrencyPipe().transform(value);
  // }

  getBancksAccounts() {
    const uid = this.authSrv.userAuth.getValue().uid;
    this.afs.collection('users').doc(uid).get().subscribe(
      (response:any) => {
        const data = response.data();
        if (data) {
          this.bankAccounts.push(data.bankAccount);
        }
      }
    );
  } 

  formatAmount(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = parseFloat(input.value.replace(/[^0-9.-]+/g, ''));
    if (!isNaN(value)) {
      this.formattedAmount = new CustomCurrencyPipe().transform(value);
    } else {
      this.formattedAmount = '';
    }
  }

  requestWithdraw(): void {
    const data = {
      uid: this.authSrv.userAuth.getValue().uid,
      account: this.selectedAccount,
      amount: this.amount * 100
    };

    this.loaderService.showSpinner('Realizando la solicitud de retirada');
    this.paymentServive.requestWithdrawal(data).subscribe(
      (response) => {
        this.loaderService.hideSpinner();
        Swal.fire('solicitud de retirada realizada', 'La solicitud de retirada se ha realizado correctamente', 'success');
        this.modal.dismissAll();
      },
      (error) => {
        this.loaderService.hideSpinner();
        if (error.error.message === 'Insufficient funds') {
          Swal.fire('Error', 'Fondos insuficientes', 'error');
        } else {
          Swal.fire('Error', 'Ha ocurrido un error, por favor intente mas tarde', 'error');
        }
      }
    );
  }  

  closeModal(){
    this.modal.dismissAll();
  }

}
