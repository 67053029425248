import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as jsonManager from '../../../abis/Manager.json';
import { environment } from '../../../../environments/environment';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app'; 

@Component({
  selector: 'app-buy-through-company',
  templateUrl: './buy-through-company.component.html',
  styleUrls: ['./buy-through-company.component.css'] 
})
export class BuyThroughCompanyComponent {
  formBuyThroughCompany: FormGroup = this.createFormBuyThroughCompany(); 

  ourAddress: string | undefined;
  web3: any | undefined;
  abiManager: any = jsonManager;
  contractManager: any | undefined;

  constructor(private connSrv: ConnectionsService, private fns: AngularFireFunctions, private afs: AngularFirestore) {
    this.web3 = this.connSrv.web3Instance;

    this.connSrv.addressUser.subscribe((res: string) => { 
      this.ourAddress = res;
    });

    this.contractManager = new this.web3.eth.Contract(this.abiManager.abi, environment.config.ADDRESS_MANAGER); 
  }
  
  createFormBuyThroughCompany() {
    return new FormGroup({
      amountTokens: new FormControl(null, [Validators.required]),
      emailUser: new FormControl(null, [Validators.required]),
      addressContractSeller: new FormControl(null, [Validators.required])
    })
  }

  get amountTokens() { return this.formBuyThroughCompany?.get('amountTokens'); }
  get emailUser() { return this.formBuyThroughCompany?.get('emailUser'); }
  get addressContractSeller() { return this.formBuyThroughCompany?.get('addressContractSeller'); }

  async buy() {
    if (this.formBuyThroughCompany.invalid) { 
      this.formBuyThroughCompany.markAllAsTouched();
      return; 
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });
    
    this.afs.collection('users', ref => ref.where('email', '==', this.emailUser?.getRawValue()).limit(1)).get().subscribe((res: any) => {
      if (!res.docs[0].exists) {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Usuario no encontrado'
        });
      } else {
        const uid = res.docs[0].id;
        const userData = res.docs[0].data();
        if(userData?.kyc?.result != 1){
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Usuario no tiene KYC completado'
          });
          return;
        }
        if((!userData?.particular && !userData?.particular?.pais_de_residencia) && !userData?.Empresa?.Representante?.pais_de_residencia){
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Usuario con irregularidades'
          });
          return;
        }
        this.fns.httpsCallable('newWalletByCompany')({userUid: uid}).subscribe(async (res: any) => {
          if (!res.success) {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: res.error
            });
          } else {
            const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
              return Number(block.baseFeePerGas) + 100000000000; 
            });

            this.contractManager.methods.setStatusWhiteList(
              res.address,
              1
            ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))})
              .then(() => {
                this.contractManager.methods.buyThroughCompany( 
                  this.formatToWei(this.amountTokens?.getRawValue()),
                  res.address,
                  this.addressContractSeller?.getRawValue()
                ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))})
                  .then(async () => {
                    this.afs.collection('investments', ref => ref.where('sellerAddress', '==', this.addressContractSeller?.getRawValue()).limit(1)).get()
                      .subscribe((res: any) => { 
                        this.afs.collection('user-investments').doc(uid).set({ [res.docs[0].id]: true }, {merge: true});
                      });

                    this.afs.collection('users').doc(uid).set({
                      walletsWhitelist: firebase.firestore.FieldValue.arrayUnion(res.address)
                    }, {merge: true});

                    Swal.close();
                    Swal.fire({
                      icon: 'success',
                      text: 'Inversión realizada con éxito'
                    });
    
                    this.formBuyThroughCompany.reset();
                  })
                  .catch(() => {
                    Swal.close();
                    Swal.fire({
                      icon: 'error',
                      title: 'Transacción errónea',
                      text: 'Algo ha ido mal, la transacción NO se ha realizado'
                    });
                  });
              })
              .catch(() => {
                Swal.close();
                Swal.fire({
                  icon: 'error',
                  title: 'Transacción errónea',
                  text: 'Algo ha ido mal, la transacción en la Whitelist NO se ha realizado'
                });
              });
          }
        });
      }
    });
  } 

  formatToWei(num: number) {
    return this.web3.utils.toWei(num.toString(), 'ether');
  }
}
