<div class="investment-container">

    <div class="menu-investment">
        <div class="menu-options font font-weight-bold" style="position: relative;">
            <p #resume (click)="selectOption('resume')">RESUMEN FINANCIERO</p>
            <p #transactions (click)="selectOption('transactions')">MOVIMIENTOS</p>
            <p #investments (click)="selectOption('investments')">MIS INVERSIONES</p>
            <p #documents (click)="selectOption('documents')">DOCUMENTOS</p>
        </div>

        <div class="divider">
            <div class="divider-section" [ngStyle]="{'width': selectedWidth, 'left': selectedLeft}"></div>
        </div>
    </div>


    <div class="content">
        <div class="pt-5 d-flex" style="gap: 25px;" *ngIf="selectedOption === 'resume'">
           <div class="data-box">
                <p>Saldo disponible</p>
                <h4 class="font-weight-normal">1.500,00€</h4>
           </div> 
            <div class="data-box">
                <p style="max-width: 140px;">Rentabilidad anual media obtenida</p>
                <h4>12.30%</h4>
            </div> 
            <div class="data-box">
                <p>Saldo disponible</p>
                <h4>1.500,00€</h4>
            </div> 

            <div id="chart" style="max-height: 120px;">
                <apx-chart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [labels]="chartOptions.labels"
                  [responsive]="chartOptions.responsive"
                ></apx-chart>
            </div>
        </div>
        
        <div *ngIf="selectedOption === 'investments'">        
          <app-investments-details></app-investments-details>
        </div>

        <div *ngIf="selectedOption === 'transactions'">        
            <app-resume-investment></app-resume-investment>
        </div>

    </div>
</div>