<div class="new-container mb-5 pb-5">
    <form class="p-4 bradius">
        <p class="title text-center h4 w-100">Admin wallet</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="url" class="form-label">Secret key:</label>
            <input [(ngModel)]="secretKey" name="secretKey" type="text" class="form-control" id="titulo" placeholder="Introduce la Secret key de la wallet">
           <div class="mt-3">
               <input class="form-check-input" type="checkbox" [(ngModel)]="isEmergency" (change)="setEmergency($event)" [ngModelOptions]="{standalone: true}"
               id="isEmergency">
               <label for="isEmergency" style="margin-left: 2px;" class="form-check-label">
                   Guardar como wallet de emergencia
               </label>
           </div>
            
            <div class="d-flex gap-1 mt-2">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <p>Cuidado esta acción es permanente</p>
            </div>
        </div>
        
        <button type="button" class="btn btn-success w-100 pt-3 pb-3" (click)="setSecretKey()">Guardar</button>
    </form>
</div>