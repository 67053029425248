import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';
import { CardsRealEstateComponent } from './components/cards-real-estate/cards-real-estate.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { KycComponent } from './components/kyc/kyc.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile/profile.component';
import { UserInvestmentsComponent } from './components/user-investments/user-investments.component';
import { GuardGuard } from './guards/guard.guard';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import { ModalRegisterComponent } from './components/modal-register/modal-register.component';
import { PaymentSuccessfulComponent } from './components/payment-result/payment-successful/payment-successful.component';
import { PaymentErrorComponent } from './components/payment-result/payment-error/payment-error.component';
import { MyInvestmentsComponent } from './components/my-investments/my-investments.component';
import { UserLoggedGuard } from './guards/user-logged/user-logged.guard';
import { ModalPageComponent } from './components/modal-page/modal-page.component';
import { AuthGuard } from './guards/auth.guard';
import { RegisterPageComponent } from './components/register-page/register-page.component';
import { InvestRealEstateComponent } from './components/invest-real-estate/invest-real-estate.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';

const routes: Routes = [
  { path: 'administracion', component: AdminComponent, canActivate: [GuardGuard] },
  { path: 'oportunidades', component: CardsRealEstateComponent, canActivate: [GuardGuard]},
  { path: 'oportunidad', component: InvestRealEstateComponent, canActivate: [GuardGuard] }, // Ruta para oportunidad con query params
  { path: 'documentacion', component: DocumentationComponent },
  //{ path: 'mis-inversiones', component: UserInvestmentsComponent, canActivate: [GuardGuard] },
  { path: 'mis-inversiones', component: MyInvestmentsComponent, canActivate: [GuardGuard] },
  { path: 'pago-exitoso', component: PaymentSuccessfulComponent, canActivate: [GuardGuard] },
  { path: 'pago-error', component: PaymentErrorComponent, canActivate: [GuardGuard] },
  { path: 'register', component: RegisterPageComponent, canActivate: [AuthGuard] }, 
  //{ path: 'login', component: ModalLoginComponent, canActivate: [UserLoggedGuard] },
  { path: 'login', component: ModalPageComponent, canActivate: [AuthGuard]},
  //{ path: 'kyc', component: KycComponent, canActivate: [GuardGuard] },
  // { path: 'perfil', component: ProfileComponent, canActivate: [GuardGuard] },
  { path: 'perfil', component: MyProfileComponent, canActivate: [GuardGuard] },
  { path: 'contacto', component: ContactUsComponent, canActivate: [GuardGuard] },
  {
    path: 'cartera-digital',
    loadChildren: () => import('./components/digital-wallet/digital-wallet.module').then(m => m.DigitalWalletModule)
  },
  { path: '', pathMatch: 'full', redirectTo: '/oportunidades' },
  { path: '**', pathMatch: 'full', redirectTo: '/oportunidades' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'ignore',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
