<div class="mt-5">
    <div class="history-head">
        <div class="containerButtons">
            <button type="button" class="btn btn-success text-center">Inversiones</button>
            <button type="button" class="btn btn-outline-success">Traspasos a la cartera</button>
        </div>
        <div class="transactions-btns">
            <span style="color: #b9b9b9;">Descargar movimientos:</span>
            <a href="https://www.domoblock.io/" target="_blank" style="color: #20a366;">EXCEL</a>
            <a href="https://www.domoblock.io/" target="_blank" style="color: #e3262a;">PDF</a>
        </div>
    </div>
  
    <div class="filters mt-4">
        <div class="d-flex gap-2" style="align-items: center; width: 250px;">
  
            <input type="date" class="form-control" id="from" placeholder="Desde">
  
            <i class="bi bi-arrow-right" style="font-size: 1.5rem; color: #848484;"></i>
  
            <input type="date" class="form-control" id="to" placeholder="Hasta">
        </div>
  
        <div class="d-flex gap-3">
            <ng-select 
                [items]="['Todos', 'Entrantes', 'Salientes']" 
                [placeholder]="'Tipo de movimiento'" 
                [multiple]="false" 
                [searchable]="false" 
                [clearable]="false" 
                [hideSelected]="true" 
                [closeOnSelect]="true" 
                [notFoundText]="'No se encontraron resultados'" 
                [appendTo]="'body'">
            </ng-select>
  
            <input type="number" class="form-control" id="amount" placeholder="Importe">
        </div>
    </div>
  
    <!-- transactions-history.component.html -->
    <div class="container mt-4">
      <div class="row mb-2">
      <div class="col-3 fw-bold text-muted">
          Proyecto
          <!-- <i [class]="'bi ' + getSortIcon('amount')" (click)="sortBy('amount')" class="domo-color" style="cursor: pointer;"></i> -->
      </div>
      <div class="col-2 fw-bold text-muted">Importe</div>
      <div class="col-2 fw-bold text-muted">Participaciones</div>
      <div class="col-3 fw-bold text-muted">Cartera digital</div>
      <div class="col-2 fw-bold text-muted">
          Fecha
          <!-- <i [class]="'bi ' + getSortIcon('date')" (click)="sortBy('date')" style="cursor: pointer;"></i> -->
      </div>
      </div>
      <div class="list-group">
      <!-- <div *ngFor="let investment of investments" class="list-group-item list-group-item-action">
          <div class="row align-items-center">
          <div class="col-3">{{ investment.totalAmount | customCurrency }}</div>
          <div class="col-2">{{ investment.fee }}</div>
          <div class="col-2">{{ investment.amount | customCurrency }}</div>
          <div class="col-3">{{ investment.method }}</div>
          <div class="col-2">{{ investment.date | date:'dd-MM-yyyy' }}</div>
          </div>
      </div> -->
      </div>
    </div>
  
  </div>
