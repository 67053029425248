<div *ngIf="info !== undefined" class="card bradius">
    <div class="containerImg" [ngStyle]="{'background-image': getUrlMainImage()}">
    </div>
    <div class="card-body">
        <div class="title mb-4 mt-2">
            <img loading="lazy" src="../../../assets/img/grafismo-gris.png">
            <div>
                <p *ngIf="info.nameTokens !== null" class="fw-bold small-text">{{info.nameTokens}}</p>
                <p *ngIf="info.nameTokens === null" class="fw-bold">{{info.location}}</p>
                <p *ngIf="info?.onlyInversors && info?.proyectStatus == 'Activo' && !(eurosSold >= info.amountToSell * (info.priceToken / 100))"
                    class="bradius small estado text-center" style="background-color: #a2cb6c;">
                    Venta privada
                </p>
                <p *ngIf="info?.proyectStatus == 'En estudio'" class="bradius small estado text-center"
                    style="background-color: #FA629C;">{{info?.proyectStatus}}</p>
                <p *ngIf="!info?.onlyInversors && info?.proyectStatus == 'Activo' && toFloor(info.endOfSale) != 'Tiempo agotado' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                    class="bradius small estado text-center" style="background-color: #a2cb6c;">{{info?.proyectStatus}}
                </p>
                <p *ngIf="info?.proyectStatus == 'Activo' && eurosSold >= info.amountToSell * (info.priceToken / 100)"
                    class="bradius small estado text-center" style="background-color: #848484;">Financiado</p>
                <p *ngIf="info?.proyectStatus == 'Activo' && toFloor(info.endOfSale) == 'Tiempo agotado' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                    class="bradius small estado text-center" style="background-color: #848484;">Finalizado</p>
                <p *ngIf="info?.proyectStatus == 'Repartiendo dividendos'" class="bradius small estado text-center"
                    style="background-color: #6EC8CA;">{{info?.proyectStatus}}</p>
                <p *ngIf="info?.proyectStatus == 'Finalizado'" class="bradius small estado text-center"
                    style="background-color: #848484;">{{info?.proyectStatus}}</p>
                <p *ngIf="info?.proyectStatus == 'Vendido'" class="bradius small estado text-center"
                    style="background-color: #0093A2;">{{info?.proyectStatus}}</p>
                <p *ngIf="info?.proyectStatus == 'Financiado'" class="bradius small estado text-center"
                    style="background-color: #848484;">{{info?.proyectStatus}}</p>
            </div>
        </div>
        <div class="mb-3 w-100 containerProgress">
            <div class="pdata">
                <div>
                    <p class="small mb-1">Financiación</p>
                    <p class="small mb-1 fw-bold" style="font-size: 1rem;">{{eurosSold | euros}}</p>
                </div>
                <div>
                    <p class="small mb-1">Objetivo</p>
                    <p class="small mb-1 fw-bold" style="font-size: 1rem;">{{info.amountToSell * info.priceToken/100 |
                        euros}}</p>
                </div>
            </div>
            <div class="progress">
                <div *ngIf="eurosSold > 0 && eurosSold < info.amountToSell * (info.priceToken / 100)"
                    class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-label="Animated striped example"
                    [attr.aria-valuenow]="(eurosSold * 100) / (info.amountToSell * (info.priceToken / 100)) | number : '1.2-2'"
                    aria-valuemin="0" aria-valuemax="100"
                    [style.width.%]="(eurosSold * 100) / (info.amountToSell * (info.priceToken / 100)) | number : '1.2-2'">
                    {{percentageSold}}%
                </div>
                <div *ngIf="eurosSold >= info.amountToSell * (info.priceToken / 100)"
                    class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                    style="width: 100%">
                    100%
                </div>
            </div>
            <div class="pdata">
                <div>
                    <p class="small mb-1">{{info.numberInvestors}} Inversiones</p>
                </div>
                <div>
                    <p *ngIf="toFloor(info.endOfSale) != '0' && eurosSold >= info.amountToSell * (info.priceToken / 100)"
                        class="small mb-1">Inversión completa</p>
                    <p *ngIf="toFloor(info.endOfSale) != '0' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                        class="small mb-1">{{toFloor(info.endOfSale)}}</p>
                </div>
            </div>
        </div>
        <div class="mb-4 w-100 pdatas">
            <div class="pdata1">
                <p class="small w-100" style="font-size: .9rem;">Plazo Estimado</p>
                <p class="mb-1 fw-bold" style="align-self: flex-end;">{{info.estimatedDeliveryTime}}</p>
            </div>
            <div class="pdata2">
                <p class="small w-100" style="font-size: .9rem;">Rentabilidad Anual</p>
                <p class="mb-1 fw-bold" style="align-self: flex-end;">{{info.annualReturn}}%</p>
            </div>
            <div class="pdata3">
                <p class="small w-100" style="font-size: .9rem;">Precio Ticket</p>
                <p class="mb-1 fw-bold" style="align-self: flex-end;">{{info.priceToken/100 | euros}}</p>
            </div>
        </div>
        <div class="containerButtons">
                    <button [disabled]="!logged || loadingButtons" type="button" class="btn btn-outline-success mb-2" (click)="openFullScreenModal()"><i
                    class="bi bi-search"></i><span style="margin-left:10px">{{ loadingButtons ? 'Cargando detalle' : 'Ver detalle'}}</span></button>
            <button (click)="goToInvestment()"
                [disabled]="(!info.blockchain || info?.proyectStatus != 'Activo' || eurosSold >= info.amountToSell * (info.priceToken / 100) || toFloor(info.endOfSale) == 'Tiempo agotado') || loadingButtons"
                class="btn btn-success text-center">
                <i class="bi bi-graph-up-arrow"></i><span style="margin-left:10px">{{ loadingButtons ? 'Cargando inversión' : 'Invertir'}}</span>
            </button>
        </div>
    </div>
</div>
