<div class="mt-5">
    <div class="head-info">
        <div>
            <h3>
                Movimientos
            </h3>
            <p style="max-width: 530px;" >
                Aquí puedes consultar los movimientos asociados a los ingresos y retiradas de dinero en tu cartera digital en €. 
                Además, puedes filtrar por el rango de fechas que quieres consultar, importe u ordenarlas por en base a diferentes criterios.
            </p>
        </div>
        <div>
            <img src="assets/img/house.svg" alt="withdraw" class="withdraw-image">
        </div>
    </div>
    
<div class="mt-4">
        <div class="history-head">
            <div class="containerButtons">
                <button type="button" (click)="getMovements('PAYIN')" 
                        class="btn text-center" 
                        [ngClass]="{'btn-success': typeMovementSelected === 'PAYIN', 'btn-outline-success': typeMovementSelected === 'PAYOUT'}">
                  Entrantes
                </button>
                <button type="button" (click)="getMovements('PAYOUT')" 
                        class="btn text-center" 
                        [ngClass]="{'btn-success': typeMovementSelected === 'PAYOUT', 'btn-outline-success': typeMovementSelected === 'PAYIN'}">
                  Salientes
                </button>
            </div>
            <div class="transactions-btns">
                <span style="color: #b9b9b9;">Descargar movimientos:</span>
                <a href="https://www.domoblock.io/" target="_blank" style="color: #20a366;">EXCEL</a>
                <a href="https://www.domoblock.io/" target="_blank" style="color: #e3262a;">PDF</a>
            </div>
        </div>

        <div class="filters mt-4">
            <div class="d-flex gap-2" style="align-items: center; width: 250px;">

                <input type="date" class="form-control" id="from" placeholder="Desde">

                <i class="bi bi-arrow-right" style="font-size: 1.5rem; color: #848484;"></i>

                <input type="date" class="form-control" id="to" placeholder="Hasta">
            </div>

            <div class="d-flex gap-3">
                <ng-select 
                    [items]="['Todos', 'Entrantes', 'Salientes']" 
                    [placeholder]="'Tipo de movimiento'" 
                    [multiple]="false" 
                    [searchable]="false" 
                    [clearable]="false" 
                    [hideSelected]="true" 
                    [closeOnSelect]="true" 
                    [notFoundText]="'No se encontraron resultados'" 
                    [appendTo]="'body'">
                </ng-select>

                <input type="number" class="form-control" id="amount" placeholder="Importe">
            </div>
        </div>

        <!-- transactions-history.component.html -->
    <ng-container *ngIf="typeMovementSelected == 'PAYIN'">
        <div class="container mt-4" *ngIf="transactions.length > 0 && !isLoading">
            <div class="row mb-2">
            <div class="col-3 fw-bold text-muted">
                Importe total
                <i [class]="'bi ' + getSortIcon('amount')" (click)="sortBy('amount')" class="domo-color" style="cursor: pointer;"></i>
            </div>
            <div class="col-2 fw-bold text-muted">Comisiones</div>
            <div class="col-2 fw-bold text-muted">Ingreso</div>
            <div class="col-3 fw-bold text-muted">Método de inversión</div>
            <div class="col-2 fw-bold text-muted">
                Fecha
                <i [class]="'bi ' + getSortIcon('date')" (click)="sortBy('date')" style="cursor: pointer;"></i>
            </div>
            </div>
            <div class="list-group">
            <div *ngFor="let transaction of transactions" class="list-group-item list-group-item-action">
                <div class="row align-items-center">
                <div class="col-3">{{ transaction.amount | customCurrency }}</div>
                <div class="col-2">{{ transaction.fee}}%</div>
                <div class="col-2">{{ transaction.amount | customCurrency }}</div>
                <div class="col-3">{{ transaction.fee === 0 ? 'Transferencia' : 'Tarjeta' }}</div>
                <div class="col-2">{{ transaction.date }}</div>
                </div>
            </div>
            </div>
        </div>

        <div class="mt-4" *ngIf="transactions.length == 0 && !isLoading">
            <p class="text-center">Todavía no tienes ningún movimiento</p>
        </div>
    </ng-container>

    <ng-container *ngIf="typeMovementSelected == 'PAYOUT'">
        <div class="container mt-4" *ngIf="transactions.length > 0 && !isLoading">
            <div class="row mb-2">
            <div class="col-3 fw-bold text-muted">
                Importe total
                <i [class]="'bi ' + getSortIcon('amount')" (click)="sortBy('amount')" class="domo-color" style="cursor: pointer;"></i>
            </div>
            <div class="col-7 fw-bold text-muted">Cuenta bancaria</div>
            <div class="col-2 fw-bold text-muted">
                Fecha
                <i [class]="'bi ' + getSortIcon('date')" (click)="sortBy('date')" style="cursor: pointer;"></i>
            </div>
            </div>
            <div class="list-group">
            <div *ngFor="let transaction of transactions" class="list-group-item list-group-item-action">
                <div class="row align-items-center">
                <div class="col-3">{{ transaction.amount | customCurrency }}</div>
                <div class="col-7">{{ transaction.account}}</div>
                <div class="col-2">{{ transaction.date | date:'dd-MM-yyyy' }}</div>
                </div>
            </div>
            </div>
        </div>

        <div class="mt-4" *ngIf="transactions.length == 0 && !isLoading">
            <p class="text-center">Todavía no tienes ningún movimiento</p>
        </div>
    </ng-container>

</div>



</div>
