import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalKycComponent } from '../modal-kyc.component';
import { KycService } from 'src/app/services/modal-kyc/kyc.service';
import { ModalConnector } from 'src/app/services/modal-connection.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import { environment } from 'src/environments/environment';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import * as jsonWhitelist from '../../../abis/WhiteList.json';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { countries, excludedCountries } from 'src/app/interfaces/all-country';
import * as IBAN from 'iban';
import { PaymentService } from 'src/app/services/payment.service';
@Component({
  selector: 'app-kyc-stages',
  templateUrl: './kyc-stages.component.html',
  styleUrls: ['./kyc-stages.component.css'],
})
export class KycStagesComponent implements OnInit {
  currentStage = 1;
  currentStep = 1;
  typeOfInvestment: string = '';
  formRegistro: FormGroup = this.createFormText();
  private readonly regexTwoNumbers = new RegExp(/\d{2}/);
  private readonly regexTwoLetters = new RegExp(/[a-zA-Z]{2}/);
  private readonly regexTenNumbers = new RegExp(/\d{10}/);
  private readonly regexFourNumbers = new RegExp(/\d{4}/);
  private readonly regexTowNumbersLetters = new RegExp(/[a-zA-Z]{2}[0-9]{2}/);
  bankFirstComponent: string = '';
  subscription: any;
  bankTransferSelected = true;
  blockchainSelected = false;
  hasMetamask?: boolean;
  walletConnected?: string;
  web3: any;
  ourAddress: any;
  abiWhitelist: any = jsonWhitelist;
  continueDisabled = false;
  public countries: any = countries;
  selectedCountry: any;

  constructor(
    public dialogRef: MatDialogRef<ModalKycComponent>,
    private kycSrv: KycService,
    private modalConnectionSrv: ModalConnector,
    private router: Router,
    private afs: AngularFirestore,
    private connSrv: ConnectionsService,
    private fns: AngularFireFunctions,
    private authSrv: AuthService,
    private paymanetSrv: PaymentService
  ) {
    this.web3 = this.connSrv.web3Instance;
    this.connSrv.addressUser.subscribe((res: string) => {
      this.ourAddress = res;
    });
    this.selectedCountry = this.countries.find(
      (country: any) => country.code === 'ES'
    );
  }

  async ngOnInit(): Promise<void> {
    this.subscription = this.kycSrv.currentStageData.subscribe((data) => {
      if (data) {
        this.currentStage = data.nextStage;
        this.currentStep = data.nextStep;
        if (data.backFromRegister) {
          this.currentStage = 2;
          this.currentStep = 2;
          this.typeOfInvestment = data.isPerson ? 'person' : 'company';
        }
      }
    });
  }

  searchText: string = '';

  filteredCountries = this.countries;
  filterCountries() {
    this.filteredCountries = this.countries.filter((country: any) =>
      country.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  onCountryChange(country: any) {
    this.selectedCountry = country;
  }

  createFormText() {
    return new FormGroup({
      bankFirstComponent: new FormControl('', [
        Validators.required,
        Validators.pattern(this.regexTowNumbersLetters),
      ]),
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async logOut() {
    this.dialogRef.close();
    await this.authSrv.logout();
    this.dialogRef.close();
    this.router.navigateByUrl('/login');
    this.authSrv.reload();
  }

  validateIBAN(iban: string): boolean {
    return IBAN.isValid(iban);
  }

  shouldValidateIBAN(countryCode: string): boolean {
    return !excludedCountries.includes(countryCode);
  }

  async nextStep() {
    if (this.currentStep == 2 && this.typeOfInvestment) {
      this.kycSrv.setModalContent('register');
      this.kycSrv.setTypeRegister({ inversorType: this.typeOfInvestment });
      await this.modalConnectionSrv
        .saveCurrentData(
          {
            inversorType: this.typeOfInvestment,
            uid: this.modalConnectionSrv.getUserUid(),
          },
          0,
          0
        )
        .then()
        .catch(async () => {
          await Swal.fire({
            title: 'Error guardando sus datos',
            text: 'Ha ocurrido un error al intentar registrar sus datos en la plataforma',
            icon: 'error',
          });
        });
    }

    if (
      this.currentStage === 2 &&
      this.currentStep === 1 &&
      this.typeOfInvestment === ''
    ) {
      await Swal.fire({
        title: 'Seleccione un tipo de inversión',
        text: 'Por favor seleccione un tipo de inversión para continuar',
        icon: 'warning',
      });
      return;
    }

    if (
      this.currentStage == 3 &&
      this.currentStep == 2 &&
      this.bankTransferSelected
    ) {
      const uid = this.modalConnectionSrv.getUserUid();
      this.continueDisabled = true;
      this.afs
        .collection('users')
        .doc(uid)
        .set({ hideBlockchain: true }, { merge: true })
        .then(() => {
          this.continueDisabled = false;
        })
        .catch(() => {
          Swal.fire({
            title: 'Algo ha ido mal',
            text: 'Ocurrido un error al intentar registrar sus datos en la plataforma',
            icon: 'error',
          });
          this.currentStep = 2;
          this.continueDisabled = false;
          return;
        });
    }

    if (
      this.currentStage == 3 &&
      this.currentStep == 3 &&
      this.bankTransferSelected &&
      !this.hasMetamask
    ) {
      if (this.hasMetamask === undefined) return;
      this.currentStep++;
      this.currentStep++;
      return;
    }

    if (this.currentStage == 3 && this.currentStep == 4) {
      const added = await this.addWallet();
      if (!added) return;
      this.currentStep++;
    }
    if (
      this.currentStage == 3 &&
      this.currentStep == 5 &&
      this.bankTransferSelected &&
      !this.hasMetamask
    ) {
      if (
        this.shouldValidateIBAN(this.selectedCountry.code) &&
        !this.validateIBAN(this.selectedCountry.code + this.bankFirstComponent)
      ) {
        Swal.fire({
          title: 'Datos invalidos',
          text: 'Ha introducido una cuenta bancaria invalida',
          icon: 'warning',
        });
        return;
      }
      const uid = this.modalConnectionSrv.getUserUid();
      const bankAccount = `
      ${this.bankFirstComponent.toUpperCase()}`.replace(/\s+/g, '');
      this.afs
        .collection('users')
        .doc(uid)
        .set({ bankAccount }, { merge: true })
        .then(() => {
          console.log('Success');
        })
        .catch(() => {
          console.log('ERROR');
        });
    }
    if (this.currentStage == 3 && this.currentStep == 6) {
      this.kycSrv.setModalContent('completed');
      this.kycSrv.isPaymentAdded(true);
    }

    this.currentStep++;
    if (this.currentStep > 1 && this.currentStage === 1) {
      this.currentStep = 1;
      this.currentStage++;
    }
    if (this.currentStep > 2 && this.currentStage === 2) {
      this.currentStep = 1;
      this.currentStage++;
    }

    if (
      this.currentStage === 3 &&
      this.currentStep === 3 &&
      this.blockchainSelected
    ) {
      this.currentStep++;
    }
  }

  verStep() {
    console.log(this.currentStep);
  }

  async selectInvestmentType(type: string = 'bankTransfer') {
    const data = {
      investmentType: type,
      inversorType: this.typeOfInvestment,
    };
    if (type === 'bankTransfer') {
      this.bankTransferSelected = true;
      this.blockchainSelected = false;
      this.hasMetamask = undefined;
    } else if (type === 'blockchain') {
      this.blockchainSelected = true;
      this.bankTransferSelected = false;
      this.hasMetamask = true;
    }
    await this.modalConnectionSrv.saveCurrentData(
      data,
      this.currentStep,
      this.currentStage
    );
  }

  connect() {
    this.connSrv.connect();
  }

  async addWallet() {
    return await new Promise<boolean>(async (resolve, reject) => {
      if (!this.ourAddress) {
        this.connect();
        Swal.fire({
          title: 'Conectando wallet',
          text: 'Conectando su wallet con metamask',
          icon: 'info',
        });
        return;
      }
      if (!this.walletConnected) {
        Swal.fire({
          icon: 'error',
          title: 'Campo vacio',
          text: 'Inserta una wallet',
        });
        return resolve(false);
      }
      if (
        this.web3.utils.toChecksumAddress(this.ourAddress) !==
        this.web3.utils.toChecksumAddress(this.walletConnected)
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Selecciona la wallet en tu MetaMask',
          text: 'La wallet insertada no coincide con la de tu MetaMask',
        });

        return resolve(false);
      }

      Swal.fire({
        title: 'Procesando...',
        html: 'No cierres esta pantalla!!',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await this.web3.eth.personal
        .sign(
          'Set wallet',
          this.ourAddress,
          environment.config.PASSWORD_BACK_AUTH
        )
        .then(async (signature: string) => {
          await this.fns
            .httpsCallable('newWalletUser')({
              address: this.walletConnected,
              signature: signature,
            })
            .subscribe(async (res: any) => {
              console.log(res);
              const contractWhitelist = new this.web3.eth.Contract(
                this.abiWhitelist.abi,
                environment.config.ADDRESS_WHITELIST
              );
              if (
                res === undefined ||
                res.success === undefined ||
                !res.success
              ) {
                Swal.close();
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Algo ha ido mal!',
                });

                return resolve(false);
              }

              const baseFee: number = await this.web3.eth
                .getBlock('pending')
                .then((block: any) => {
                  return Number(block.baseFeePerGas) + 100000000000;
                });

              contractWhitelist.methods
                .setStatusWithSignature(
                  this.walletConnected,
                  1,
                  res.timeStamp,
                  res.signature
                )
                .send({
                  from: this.ourAddress,
                  maxFeePerGas: this.web3.utils.toHex(baseFee),
                  maxPriorityFeePerGas: this.web3.utils.toHex(
                    this.web3.utils.toWei('50', 'gwei')
                  ),
                })
                .then(async () => {
                  const uid = this.modalConnectionSrv.getUserUid();

                  this.afs
                    .collection('users')
                    .doc(uid)
                    .set(
                      {
                        walletsWhitelist: [this.walletConnected],
                        hideBlockchain: false,
                      },
                      { merge: true }
                    );

                  Swal.close();
                  Swal.fire({
                    icon: 'success',
                    text: `La wallet se ha añadido con éxito`,
                  });
                })
                .then(() => resolve(true))
                .catch(
                  (res: any) => {
                    Swal.close();
                    Swal.fire({
                      icon: 'error',
                      title: 'Transacción errónea',
                      text: 'Algo ha ido mal, la transacción NO se ha realizado',
                    });
                    resolve(false);
                  },
                  { timeout: 300000 }
                );
            });
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo ha ido mal!',
          });

          return;
        });
    });
  }

  backStep() {
    if (
      this.currentStage === 3 &&
      this.currentStep === 4 &&
      this.blockchainSelected
    ) {
      this.currentStep--;
    }

    if (
      this.currentStage === 3 &&
      this.currentStep === 5 &&
      this.bankTransferSelected &&
      !this.hasMetamask
    ) {
      this.currentStep--;
    }

    this.currentStep--;
  }
}
