import { Injectable } from '@angular/core';
import { MatDialog, MatDialogState } from '@angular/material/dialog';
import { ModalLoginComponent } from '../components/modal-login/modal-login.component';
import { ModalRegisterComponent } from '../components/modal-register/modal-register.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';

@Injectable({
  providedIn: 'root'
})
export class ModalLoginService {
  private readonly loginModalId:string = 'login-component'
  private readonly registerModalId:string = 'register-component'
  private readonly forgotPasswordModalId:string = 'forgot-password-component'

  constructor(
    private matDialog: MatDialog
    
  ) { }

  openModalLogin(){
    const dialog = this.matDialog.getDialogById(this.loginModalId);
    if(dialog?.getState() == MatDialogState.CLOSED || !dialog)
      this.matDialog.open(ModalLoginComponent,{disableClose:true,id: this.loginModalId, panelClass: 'modal-login-register'});
  }

  closeModalLogin(){
    const dialog = this.matDialog.getDialogById(this.loginModalId);
    dialog?.close();
  }

  openModalRegister(){
    const dialog = this.matDialog.getDialogById(this.registerModalId);
    if(dialog?.getState() == MatDialogState.CLOSED || !dialog)
      this.matDialog.open(ModalRegisterComponent,{disableClose:true,id: this.registerModalId, panelClass: 'modal-login-register'});
  }

  closeModalRegister(){
    const dialog = this.matDialog.getDialogById(this.registerModalId);
    dialog?.close();
  }

  openModalForgotPassword(){
    const dialog = this.matDialog.getDialogById(this.forgotPasswordModalId);
    if(dialog?.getState() == MatDialogState.CLOSED || !dialog)
      this.matDialog.open(ForgotPasswordComponent,{disableClose:true,id: this.forgotPasswordModalId, panelClass: 'modal-login-register'});
  }

  closeModalForgotPassword(){
    const dialog = this.matDialog.getDialogById(this.forgotPasswordModalId);
    dialog?.close();
  }
}
