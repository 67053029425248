<div class="investments-container scrollable-container">
    <div *ngIf="already == false" class="noProyects bradius">
        <p class="m-0 p-4 parpadea">Cargando datos ...</p>
    </div>

    <div *ngIf="already && investmentsUser.length > 0 && investmentsUser.length != sortedArray.length "
        class="noProyects bradius">
        <p class="m-0 p-4 parpadea">Ordenando inversiones</p>
    </div>

    <div *ngIf="already == true && investmentsUser.length == 0" class="noProyects bradius">
        <p class="m-0 p-4">No tienes inversiones</p>
    </div>

    <div class="referral-container" style="text-align: center;">
        <h1 class="referral-title">
            ¡Consigue 50€ adicionales por cada amigo que invites!
        </h1>
        <div class="referral-button-container">
            <a href="https://www.domoblock.io/programa-de-referidos" target="_blank"
                class="btn btn-warning referral-button">
                Programa de Referidos
            </a>
        </div>
    </div>


    <div *ngFor="let item of sortedArray; let i = index" class="w-100 bradius mb-4">
        <div class="cimg">
            <img [src]="item.mainImage">
        </div>
        <div class="cinfo">
            <p class="mb-2">{{item.title}} ({{item.symbolTokens}})</p>
            <!--<p class="small mb-2">Token: {{item.nameTokens}} ({{item.symbolTokens}})</p>-->
            <p class="small mb-3">{{(item.description.length > 125) ? (item.description | slice: 0:125) + ' ...' :
                item.description}}</p>
            <div class="documentation mb-3">
                <a *ngIf="item.urlWhitepapperPdf !== null" [href]="item.urlWhitepapperPdf" target="_blank"><i
                        class="bi bi-filetype-pdf h2 mr-4" style="color:#f09502;"></i>Whitepaper</a>
                <a *ngIf="item.urlDosierPdf !== null" [href]="item.urlDosierPdf" target="_blank"><i
                        class="bi bi-filetype-pdf h2 mr-4" style="color:#f09502;"></i>Dossier</a>
                <a *ngIf="item.urlInfoTokenPdf !== null" [href]="item.urlInfoTokenPdf" target="_blank"><i
                        class="bi bi-filetype-pdf h2 mr-4" style="color:#f09502;"></i>Acuerdo de suscripción al
                    token</a>
                <a *ngIf="item.infoProyecto" [href]="item.infoProyecto" target="_blank" class="btn btn-success">Más
                    información</a>
            </div>
            <div class="w-100">
                <p *ngIf="item.amountTokens > 0" class="w-100 mb-0 directTokens"
                    style="text-align: right; font-size: 1.2rem;"><span style="color:#f09502;"
                        class="info-span-mobile">{{item.amountTokens}} {{item.symbolTokens}} ( {{item.amountTokens *
                        item.priceToken / 100}}€ ) comprados.</span></p>
                <p *ngIf="item.amountTokensCompany > 0" class="w-100 mb-0 companyTokens"
                    style="text-align: right; font-size: 1.2rem;" (click)="infoTokensCompany()"><span
                        style="color:#f09502;" class="info-span-mobile"><i class="bi bi-info-circle-fill"></i>
                        {{item.amountTokensCompany}} Tokens {{item.symbolTokens}} ( {{item.amountTokensCompany *
                        item.priceToken / 100}}€ ) comprados por transferencia/tarjeta bancaria.</span></p>
            </div>
            <div *ngIf="item.snapshotsFlag == false" class="cdata mt-2 mb-0">
                <p *ngIf="!item.canRevert && item.proyectStatus != 'Vendido' && item.proyectStatus != 'Repartiendo dividendos'"
                    class="mb-0">Aún no se han repartido beneficios</p>
                <p *ngIf="!item.canRevert && item.proyectStatus == 'Vendido'" class="mb-0">Ganancias repartidas</p>
                <p *ngIf="!item.canRevert && item.proyectStatus == 'Repartiendo dividendos'" class="mb-0">Repartiendo
                    dividendos</p>
                <p *ngIf="item.canRevert" class="mb-3 w-100">No se ha llegado al objetivo de la financiación, puedes
                    retornar tus fondos:</p>
                <button *ngIf="item.canRevert" (click)="returnFounds(item, item.id + 'retorn')"
                    [attr.id]="item.id + 'retorn'" class="btn btn-success">Retornar fondos</button>
            </div>
            <div *ngIf="item.snapshotsFlag == true" class="cdata mt-2 mb-0">
                <table class="table text-center mb-0">
                    <thead>
                        <tr class="text-white">
                            <th class="small" scope="col">Id de reparto</th>
                            <th class="small" scope="col">Ganancias</th>
                            <th class="small" scope="col">Se ha aplicado rentención</th>
                            <th class="small" scope="col">Fecha</th>
                            <th class="small" scope="col">Reinvertir o retirar</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let snap of item.snapshots" class="text-white">
                        <tr [class.toClaim]="!snap.used" [class.claimed]="snap.used">
                            <td class="small">{{snap.id}}</td>
                            <td class="small">{{snap.amount}} {{item.symbolStableCoin}} ({{snap.amountTokens}}
                                {{item.symbolTokens}})</td>
                            <td class="small" *ngIf="snap.retention > 0">Sí</td>
                            <td class="small" *ngIf="snap.retention == 0">No</td>
                            <td class="small">{{getDate(snap.timeStamp)}}</td>
                            <td>
                                <button data-bs-toggle="modal"
                                    (click)="filterInvestments(item, snap.id); currentAmountReinvest = [0, snap.amount];"
                                    [attr.data-bs-target]="'#modalDetail' + i" [disabled]="snap.used"
                                    class="btn btn-success reinvertirBtn small">Reinvertir</button>
                                <button [disabled]="snap.used" (click)="getOut(item, snap.id)"
                                    class="btn btn-outline-success small">Retirar</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="modal fade" [id]="'modalDetail' + i" tabindex="-1" aria-labelledby="modalDetailFullscreenLabel"
                aria-hidden="true" role="dialog">
                <div class="modal-dialog modal-fullscreen">
                    <div class="modal-content text-white">
                        <div class="modal-header pt-5">
                            <h1 class="modal-title fs-4" id="exampleModalFullscreenLabel">Proyectos para reinvertir</h1>
                            <button (click)="currentAmountReinvest = [0, 0];" type="button" class="btn-close"
                                style="background-color:white !important;" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div *ngIf="investmentsFiltered.length == 0" class="modal-body pb-5">
                            <p>En estos momentos no hay ningún proyecto activo para reinvertir</p>
                        </div>
                        <div *ngIf="investmentsFiltered.length > 0" class="modal-body pb-5">
                            <ng-container *ngFor="let item of investmentsFiltered">
                                <div class="reinvestment w-100 pt-5 pb-5">
                                    <div class="containerImg">
                                        <img [src]="item.mainImage" class="mb-4">
                                        <div class="progressData">
                                            <div class="pdata">
                                                <div>
                                                    <p class="small mb-1">Financiación</p>
                                                    <p class="small mb-1 fw-bold" style="font-size: 1rem;">
                                                        {{item.eurosSold | euros}}</p>
                                                </div>
                                                <div>
                                                    <p class="small mb-1">Objetivo</p>
                                                    <p class="small mb-1 fw-bold" style="font-size: 1rem;">
                                                        {{item.amountToSell * item.priceToken/100 | euros}}</p>
                                                </div>
                                            </div>
                                            <div class="progress">
                                                <div *ngIf="item.eurosSold > 0 && item.eurosSold < item.amountToSell * (item.priceToken / 100)"
                                                    class="progress-bar progress-bar-striped progress-bar-animated"
                                                    role="progressbar" aria-label="Animated striped example"
                                                    [attr.aria-valuenow]="(item.eurosSold * 100) / (item.amountToSell * (item.priceToken / 100)) | number : '1.2-2'"
                                                    aria-valuemin="0" aria-valuemax="100"
                                                    [style.width.%]="(item.eurosSold * 100) / (item.amountToSell * (item.priceToken / 100)) | number : '1.2-2'">
                                                    {{(item.eurosSold * 100) / (item.amountToSell * (item.priceToken /
                                                    100)) | number : '1.2-2'}}%
                                                </div>
                                                <div *ngIf="item.eurosSold >= item.amountToSell * (item.priceToken / 100)"
                                                    class="progress-bar progress-bar-striped progress-bar-animated"
                                                    role="progressbar" aria-label="Animated striped example"
                                                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                                    style="width: 100%">
                                                    100%
                                                </div>
                                            </div>
                                            <div class="pdata">
                                                <div>
                                                    <p class="small mb-1">{{item.numberInvestors}} Inversiones</p>
                                                </div>
                                                <div>
                                                    <p *ngIf="toFloor(item.endOfSale) != '0'" class="small mb-1">
                                                        {{toFloor(item.endOfSale)}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="data">
                                        <p class="mb-2">{{item.title}} ({{item.symbolTokens}})</p>
                                        <!--<p class="mb-2">Token: {{item.nameTokens}} ({{item.symbolTokens}})</p>-->
                                        <p class="mb-3">{{(item.description.length > 250) ? (item.description | slice:
                                            0:250) + ' ...' : item.description}}</p>
                                        <p class="mb-2">Plazo Estimado: {{item.estimatedDeliveryTime}}</p>
                                        <p class="mb-2">Rentabilidad Anual Estimada: {{item.annualReturn}}%</p>
                                        <p class="mb-3">Precio Ticket: {{item.priceToken / 100 | euros}}</p>
                                        <div class="documentation mb-5">
                                            <a *ngIf="item.urlWhitepapperPdf !== null" class="text-white"
                                                [href]="item.urlWhitepapperPdf" target="_blank"><i
                                                    class="bi bi-filetype-pdf h2 mr-4"
                                                    style="color:#f09502;"></i>Whitepaper</a>
                                            <a *ngIf="item.urlDosierPdf !== null" class="text-white"
                                                [href]="item.urlDosierPdf" target="_blank"><i
                                                    class="bi bi-filetype-pdf h2 mr-4"
                                                    style="color:#f09502;"></i>Dossier</a>
                                            <a *ngIf="item.urlInfoTokenPdf !== null" class="text-white"
                                                [href]="item.urlInfoTokenPdf" target="_blank"><i
                                                    class="bi bi-filetype-pdf h2 mr-4"
                                                    style="color:#f09502;"></i>Acuerdo de suscripción al token</a>
                                        </div>
                                        <div class="reinvertBtn">
                                            <p class="w-100 mb-2">La cantidad no reinvertida será ingresada en tu wallet
                                                personal.</p>
                                            <p class="w-100">Indica que cantidad quieres reinvertir, máximo
                                                {{currentAmountReinvest[1]}} {{item.symbol}}:</p>
                                            <input type="number" [(ngModel)]="currentAmountReinvest[0]"
                                                (change)="onChangeReinvest(item)" class="form-control" min="0"
                                                [max]="currentAmountReinvest[1]">
                                            <button
                                                [disabled]="currentAmountReinvest[0] === 0 || currentAmountReinvest[0] > currentAmountReinvest[1]"
                                                (click)="reinvest(item)" class="btn btn-success"><i
                                                    class="bi bi-bank"></i>Reinvertir</button>
                                            <p class="w-100 mt-2 mb-0">Tokens: {{this.newTokens}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

