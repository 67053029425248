<div class="modal-body">
    <div class="div-position-close">
        <i class="fa fa-times fa-2x position-close" (click)="goToLogin()" aria-hidden="true"></i>
    </div>
    <div class="form-title text-center">
        <h4>¿Has olvidado tu contraseña?</h4>
        <label class="textcolor">No te preocupes, recupérala a continuación</label>
    </div>

    <div class="d-flex flex-column text-start">
        <div *ngIf="capsLockActivated" class="text-danger">
            <span> *Las mayúsculas están activadas.
            </span>
        </div>
        <form *ngIf="!sentEmail" [formGroup]="sentEmailForm">
            <div class="form-group pb-0">
                <label for="Email" class="labeltext">Email</label>
                <input formControlName="email" class="form-control" id="email1" placeholder="Ingresa tu email">
            </div>
            <div class="text-end">
                <label (click)="goToLogin()" class="changecolor">¿Recuerdas tu contraseña?
                </label>
            </div>
            <button (click)="getOtp()" type="button" class="btn mt-3">Enviar email</button>
        </form>
        <form *ngIf="(forgotForm !== undefined || forgotForm !== null) && sentEmail" [formGroup]="forgotForm">
            <div class="form-group pb-0">
                <label for="Otp" class="labeltext">Código de verificación</label>
                <input formControlName="otp" class="form-control" id="Otp"
                    placeholder="Escribe el codigo enviado a tu correo" (input)="convertToUppercase($event)">
            </div>
            <div class="text-end">
                <label mat-button *ngIf="!isDisabled" (click)="getOtp()" class="changecolor"
                    [class.disabled]="isDisabled" [attr.disabled]="isDisabled ? true : null">
                    Reenviar código de verificación
                </label>
                <label *ngIf="isDisabled" class="countdown">Reenviar código en {{ countdown }}s</label>
            </div>
            <div class="form-group pbc password-div">
                <div>
                    <label for="Contraseña" class="labeltext">Nueva contraseña</label>
                    <input formControlName="password" type="{{showPassword ? 'text' : 'password' }}"
                           class="form-control form-control-sm" placeholder="Ingresa tu contraseña"
                           (focus)="onFocus('password')">
                    
                    <a (click)="togglePassword('password')" class="eye-b">
                      <i class="{{showPassword ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
                    </a>
                    
                    <div *ngIf="forgotForm.get('password').touched && passwordErrors.length > 0" class="warning-messages">
                        <span class="text-danger">
                            <span *ngFor="let error of passwordErrors; let i = index">
                                <span [ngClass]="{'error-message': true}">
                                    {{ error }}<span *ngIf="i < passwordErrors.length - 1">, 
                                </span></span>
                              </span>
                              .
                        </span>
                    </div>
                </div>

                <div class="mt-3">
                    <label for="Contraseña" class="labeltext">Confirmar nueva contraseña</label>
                    <input formControlName="confirmPassword" type="{{showConfirmPassword ? 'text' : 'password' }}"
                        class="form-control form-control-sm" placeholder="Ingresa tu contraseña">

                    <a (click)="togglePassword('confirmPassword')" class="eye-b" style="top: 130px;">
                        <i class="{{showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
                    </a>
                </div>
                <div class="text-end">
                    <label (click)="goToLogin()" class="changecolor">¿Recuerdas tu contraseña?
                    </label>
                </div>
            </div>
            <button (click)="changePassword()" type="button" class="btn ">Cambiar contraseña</button>
        </form>
    </div>
</div>
<app-spinner *ngIf="isLoading" [loadingText]="loadingMessage"></app-spinner>