<nav class="navbar fixed-top" style="background-color:black; padding-bottom: 1rem">
    <div class="container-fluid">
        
       <div>
    <a [routerLink]="['/oportunidades']">
        <img src="../../../assets/img/logo.png" alt="Domoblock logotipo" style="height: 2.3rem; width: auto; margin-left: 20px;">
    </a>
</div>

        <button class="navbar-toggler bg-white" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                    <div>
                        <a [routerLink]="['/oportunidades']">
                            <img src="../../../assets/img/logo.png" alt="Domoblock logotipo">
                        </a>
                    </div>
                </h5>
            </div>
            <div class="offcanvas-body">
                <p data-bs-dismiss="offcanvas" aria-label="Close" *ngIf="loggedIn && adminFlag" [routerLink]="['/administracion']" routerLinkActive="activo"><i class="bi bi-gear"></i> Administración</p>
                <p data-bs-dismiss="offcanvas" aria-label="Close" [routerLink]="['/oportunidades']" routerLinkActive="activo"><i class="bi bi-lightning-charge-fill"></i> Oportunidades</p>
                <p data-bs-dismiss="offcanvas" aria-label="Close" *ngIf="loggedIn" [routerLink]="['/mis-inversiones']" routerLinkActive="activo"><i class="bi bi-piggy-bank"></i> Mis inversiones</p>
                <p data-bs-dismiss="offcanvas" aria-label="Close" [routerLink]="['/documentacion']" routerLinkActive="activo"><i class="bi bi-file-pdf-fill"></i> Documentos</p>
                <!-- <p data-bs-dismiss="offcanvas" aria-label="Close" *ngIf="loggedIn && !kycFlag" (click)="openModalKyc()" routerLinkActive="activo"><i class="bi bi-person-bounding-box"></i>Continuar KYC</p> -->
                <p data-bs-dismiss="offcanvas" aria-label="Close" *ngIf="loggedIn" [routerLink]="['/perfil']" routerLinkActive="activo"><i class="bi bi-person-bounding-box"></i> Perfil</p>
                <p data-bs-dismiss="offcanvas" aria-label="Close" *ngIf="loggedIn" (click)="logout()"><i class="bi bi-x-octagon-fill" style="color:brown;"></i> Cerrar sesión</p>
            </div>
            <button class="navbar-toggler closeMenuMov" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                Cerrar
            </button>
        </div>
    </div>
</nav>