<div class="mt-3">
  <div class="history-head">
      <div class="containerButtons" style="visibility: hidden;">
          <button type="button" class="btn btn-success text-center">Inversiones</button>
          <button type="button" class="btn btn-outline-success">Traspasos a la cartera</button>
      </div>
      <div class="transactions-btns">
          <span style="color: #b9b9b9;">Descargar: </span>
          <a href="https://www.domoblock.io/" target="_blank" style="color: #20a366;">Inversiones en excel</a>
          <a href="https://www.domoblock.io/" target="_blank" style="color: #e3262a;">Certificado de inversión</a>
      </div>
  </div>

  <div class="filter-container">
    <div class="d-flex flex-column">
        <div class="filter-label">Filtrar por:</div>
        <div class="filter-options">
        <button 
            class="filter-tag" 
            [class.active]="selectedFilter === 'en-curso'"
            (click)="setFilter('en-curso')">
            En curso
        </button>
        <button 
            class="filter-tag" 
            [class.active]="selectedFilter === 'cerrada'"
            (click)="setFilter('cerrada')">
            Cerrada
        </button>
        <button 
            class="filter-tag" 
            [class.active]="selectedFilter === 'gestion-pendiente'"
            (click)="setFilter('gestion-pendiente')">
            Gestión pendiente
        </button>
        </div>
    </div>
    
    <div class="d-flex gap-4">
        <div class="search-container ">
          <input 
            type="text" 
            [(ngModel)]="searchQuery" 
            placeholder="Búsqueda proyecto"
            class="search-input">
        </div>
        <ng-select
        [items]="['Todos', 'Entrantes', 'Salientes']" 
        [placeholder]="'Mas recientes'" 
        [multiple]="false" 
        [searchable]="false" 
        [clearable]="false" 
        [hideSelected]="true" 
        [closeOnSelect]="true" 
        [notFoundText]="'No se encontraron resultados'" 
        [appendTo]="'body'"
        >
        </ng-select>
    </div>
  </div>

  <!-- transactions-history.component.html -->
  <div class="container mt-4">
    <div class="row mb-2">
    <div class="col-3 fw-bold text-muted">
        Proyecto
        <!-- <i [class]="'bi ' + getSortIcon('amount')" (click)="sortBy('amount')" class="domo-color" style="cursor: pointer;"></i> -->
    </div>
    <div class="col-2 fw-bold text-muted">Plazo</div>
    <div class="col-2 fw-bold text-muted">Rentabilidad</div>
    <div class="col-2 fw-bold text-muted">Capital invertido</div>
    <div class="col-1 fw-bold text-muted">
        Estado
        <!-- <i [class]="'bi ' + getSortIcon('date')" (click)="sortBy('date')" style="cursor: pointer;"></i> -->
    </div>
    <div class="col-2 fw-bold text-muted">Retorno de inversión</div>
    </div>
    <div class="list-group">
    <div *ngFor="let investment of arrayInvestments" class="list-group-item list-group-item-action">
        <div class="row align-items-center">
        <div class="col-3">
            <div class="project-info">
                <img [src]="investment.mainImage" alt="Project Icon" class="project-icon">
                <div>
                  <div class="project-name">{{investment.title}}</div>
                  <div class="project-location">{{investment.location}}</div>
                </div>
            </div>
        </div>
        <div class="col-2">{{ investment.estimatedDeliveryTime }}</div>
        <div class="col-2">{{ investment.annualReturn}}%</div>
        <div class="col-2">{{ investment.amountInvested | customCurrency }}</div>
        <div class="col-1">{{ investment.proyectStatus }}</div>
        <div class="col-2">
            <button mat-stroked-button (click)="claimDividends(investment.id)" [disabled]="investment.dividendClaimed || (investment.dividend == null)">
                <span *ngIf="investment.dividend != null" style="color: #FF9600;">{{investment.dividendClaimed ? 'Dividendos reclamados' : 'Reclamar dividendos'}}</span>
                <span *ngIf="investment.dividend == null" style="color: #FF9600;">No hay dividendos</span>
              </button>
        </div>
        </div>
    </div>
    </div>
  </div>

  <div class="d-flex justify-content-center align-items-center mt-4">
    <img src="../../../../assets/img/referals-banner.svg" alt="Empty" class="empty-img">
  </div>

</div>


<!-- <div class="card-content">
    <div class="project-info">
      <img [src]="project.mainImage" alt="Project Icon" class="project-icon">
      <div>
        <div class="project-name">{{project.title}}</div>
        <div class="project-location">{{project.location}}</div>
      </div>
    </div> -->