<div class="px-1">
    <div>
        <h3>Selecciona tu método de inversión predeterminado</h3>
    </div>

    <div class="mt-5">
        <p>
            Puedes elegir invertir en la plataforma de manera tradicional, a través de tu cartera digital, ingresando dinero por medio de transferencia bancaria o tarjeta de crédito.
            <br>
            <br>
            Si lo prefieres, también puedes operar directamente a través de blockchain, invirtiendo tanto con tu wallet de Metamask en la red de Polygon.
        </p>

        <div class="w-50 mt-5">
            <div class="row mt-4 justify-content-around">
                <div
                  class="col-5 d-flex justify-content-center align-items-center flex-column text-center p-3"
                  style="gap: 10px; cursor: pointer"
                  (click)="selectInvestmentType('bankTransfer')"
                  [ngClass]="{ selected: bankTransferSelected }"
                >
                  <img
                    src="../../../../assets/img/modal-kyc/phone-cash.svg"
                    alt="phone-cash"
                  />
                  <p class="m-0">Cartera digital</p>
                </div>
      
                <div
                  class="col-5 d-flex justify-content-center align-items-center flex-column text-center p-3"
                  style="gap: 10px; cursor: pointer"
                  (click)="selectInvestmentType('blockchain')"
                  [ngClass]="{ selected: blockchainSelected }"
                >
                  <img
                    src="../../../../assets/img/modal-kyc/blockchain.svg"
                    alt="blockchain"
                  />
                  <p class="m-0">Blockchain, a través de una stablecoin</p>
                </div>
    
                <!-- <button type="button" class="btn col-4 mt-4">Aceptar</button> -->
            </div>
        </div>
    </div>
</div>