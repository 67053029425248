import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent {
  documents: any[] = [];

  constructor(private afs: AngularFirestore) {
    this.afs.collection('general-documentation').valueChanges().subscribe((res: any) => {
      if (res !== undefined && res !== null) {
        this.documents = res;
        console.log(this.documents);
      }
    });
  }
}
