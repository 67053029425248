import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {
  @Input() currentPage = 1; // Página actual
  @Input() totalPages = 1; // Total de páginas
  @Input() itemsPerPage = 10; // Elementos por página
  @Input() itemsPerPageOptions = [10, 20, 30, 50]; // Opciones para cambiar elementos por página

  @Output() pageChange = new EventEmitter<number>(); // Para emitir cambios de página
  @Output() itemsPerPageChange = new EventEmitter<number>(); // Para emitir cambios de items por página

  // Cambiar página
  changePage(newPage: number) {
    if (newPage >= 1 && newPage <= this.totalPages) {
      this.currentPage = newPage;
      this.pageChange.emit(this.currentPage);
    }
  }

  // Cambiar elementos por página
  onItemsPerPageChange() {
    this.itemsPerPageChange.emit(this.itemsPerPage);
  }
  
}
