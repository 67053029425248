import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { KycService } from 'src/app/services/kyc.service';
import Swal from 'sweetalert2';
import { KycService as ModalKycSrv } from 'src/app/services/modal-kyc/kyc.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DetailRealEstateComponent } from '../detail-real-estate/detail-real-estate.component';

declare var $: any;

export interface EncryptedData {
  userEmail: string;
  projectName: string;
  tokenQuantity: number;
  tokenAddress: string;
  sellerAddress: string;
  uid: string;
  wallet: string;
}

@Component({
  selector: 'app-card-real-estate',
  templateUrl: './card-real-estate.component.html',
  styleUrls: ['./card-real-estate.component.css']
})
export class CardRealEstateComponent implements OnInit {
  @Input() info: any | undefined;
  @Input() pushLeft: boolean = false;
  @Input() userInversor: any | undefined;
  @Input() userWallets: any
  @Input() userID: string = '';
  @Input() loadingButtons: boolean = true;
  @ViewChild('modalContent') modalContent: TemplateRef<any> | undefined;
  logged: boolean = false;
  nowTimeStamp: number = Math.floor(Date.now() / 1000);
  eurosSold: number = 0;
  acreditado: any;
  percentageSold: string = '0.00';
  percentageSoldNumber: number = 0;
  percentageToPrivateSale: number = 0;

  constructor(
    private authSrv: AuthService, 
    private kycSrv: KycService, 
    private afs: AngularFirestore, 
    private crf: ChangeDetectorRef, 
    private modalKycSrv: ModalKycSrv,
    private dialog: MatDialog,
    private router: Router,
    // private loaderService: LoaderService
  ) {
    this.authSrv.loggedIn.subscribe((res: boolean) => {
      this.logged = res;
    });

  }

  async ngOnInit() {
    this.eurosSold = this.info.amountSold * (this.info.priceToken / 100);
    this.calculatePercentageSold();
    this.percentageToPrivateSale = this.info.percentageToPrivateSale ?? 0;

    setInterval(() => {
      this.nowTimeStamp = Math.floor(Date.now() / 1000);
      this.crf.detectChanges();
    }, 3000);
  }

  calculatePercentageSold() {
    if (this.info.amountToSell && this.info.priceToken) {
      const result = (this.eurosSold * 100) / (this.info.amountToSell * (this.info.priceToken / 100));
      this.percentageSoldNumber = result;
      this.percentageSold = result.toFixed(2);
    }
  }

  transformDecimals(num: number) {
    const numberFormated = new Intl.NumberFormat('de-DE').format(num);
    return numberFormated;
  }

  getUrlMainImage() {
    return `url('${this.info.mainImage}')`;
  }

  toFloor(num: number) {
    if (num === undefined || num === null || num === 0) {
      return 'No activo';
    }

    const secondsDiff = num - this.nowTimeStamp;
    const minutesDiff = secondsDiff / 60;
    const hoursDiff = minutesDiff / 60;
    const daysDiff = hoursDiff / 24;

    if (daysDiff >= 1) {
      return 'Quedan: ' + Math.floor(daysDiff) + ' días';
    }

    if (hoursDiff >= 1) {
      return 'Quedan: ' + Math.floor(hoursDiff) + ' horas';
    }

    if (minutesDiff >= 1) {
      return 'Quedan: ' + Math.floor(minutesDiff) + ' minutos';
    }

    if (secondsDiff >= 1) {
      return 'Quedan: ' + Math.floor(secondsDiff) + ' segundos';
    }

    return 'Tiempo agotado';
  }

  convertToAmount(number: any, factor: any) {
    return number * factor;
  }

  kycNoCompleted(){
    Swal.fire('Error', 'Debes completar la verificacion de tu cuenta para realizar esta operación.', 'error');
    this.router.navigate( ['/perfil'] );
    this.modalKycSrv.openModalKyc();
  }

  openFullScreenModal() {
    const dialogRef = this.dialog.open(DetailRealEstateComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'custom-full-screen-modal',
      data: {
        info: this.info,
        cantInvest: (!this.logged || !this.info.blockchain || this.info?.proyectStatus != 'Activo' || this.eurosSold >= this.info.amountToSell * (this.info.priceToken / 100) || this.toFloor(this.info.endOfSale) == 'Tiempo agotado')
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('El modal se ha cerrado');
    });
  }

  goToInvestment() {
    if (this.info?.onlyInversors && !this.userInversor && (this.percentageSoldNumber < this.percentageToPrivateSale) && (this.percentageToPrivateSale > 0)) {
      Swal.fire('Venta privada', 'Esta oportunidad se encuentra en venta privada, espera que esta termine y el equipo domoblock la haga publica', 'info');
      return;
    }
    if(this.info?.onlyInversors && this.percentageSoldNumber >= this.percentageToPrivateSale && this.percentageToPrivateSale > 0){
      Swal.fire('Venta privada finalizada', 'La venta privada está cerrada. Para poder invertir deberás esperar a la apertura de la venta pública', 'info');
      return;
    }

    this.router.navigate(['/oportunidad'], { queryParams: { id: this.info.id } });
  }
}