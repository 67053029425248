import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  capsLockActivated: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  forgotForm: FormGroup = this.createForm();
  isLoading: boolean = false;
  isDisabled: boolean = false;
  countdown: number = 60;
  interval: any;
  sentEmail: boolean = false;
  userEmail: string = '';

  constructor(
    private authSrv: AuthService,
    private loaderSrv: LoaderService
  ) { }

  ngOnInit(): void {
    this.getEmail();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event instanceof KeyboardEvent) {
      this.capsLockActivated = event.getModifierState('CapsLock');
    }
  }

  createForm() {
    return new FormGroup({
      otp: new FormControl(null, [Validators.required, Validators.minLength(7), Validators.maxLength(7)]),
      password: new FormControl({ value: null, disabled: false }, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
      ]),
      confirmPassword: new FormControl({ value: null, disabled: false })
    })
  }

  togglePassword(showPassword: string) {
    switch (showPassword) {
      case 'password':
        this.showPassword = !this.showPassword;
        break;
      case 'confirmPassword':
        this.showConfirmPassword = !this.showConfirmPassword;
        break;
      default:
        break;
    }
  }

  get password() { return this.forgotForm?.get('password'); }
  get confirmPassword() { return this.forgotForm?.get('confirmPassword'); }
  get otp() { return this.forgotForm?.get('otp'); }

  get passwordErrors(): string[] {
    const errors = this.password?.errors as any;
    const messages = [];
  
    if (errors) {
      if (errors.required) {
        messages.push('La contraseña es obligatoria' );
      }
      if (errors.minlength) {
        messages.push('Debe tener al menos 6 caracteres');
      }
      if (errors.maxlength) {
        messages.push(`La contraseña debe tener máximo ${errors.maxlength.requiredLength} 
                caracteres y actualmente tiene  ${errors.maxlength.actualLength}`);
        return messages;
      }
      if (errors.pattern) {
        const passwordValue = this.forgotForm.get('password')?.value || '';
        if (!/(?=.*[0-9])/.test(passwordValue)) {
          messages.push('Debe contener al menos un número');
        }
        if (!/(?=.*[A-Z])/.test(passwordValue)) {
          messages.push('Debe contener al menos una letra mayúscula');
        }
        if (!/(?=.*[a-z])/.test(passwordValue)) {
          messages.push('Debe contener al menos una letra minúscula');
        }
      }
    }
  
    return messages;
  }

  onFocus(field: string): void {
    this.forgotForm.get(field)?.markAsTouched();
  }

  getEmail(){
    this.authSrv.userAuth.subscribe((res: any) => {
      if (res !== undefined && res !== null) {
        this.userEmail = res.email;
      } else {
        this.userEmail = '';
      }
    });
  }

  async getOtp() {

    if (this.isDisabled) {
      return;
    }

    if(this.userEmail === ''){
      Swal.fire({
        icon: 'error',
        title: 'Error al obtener su correo',
        text: 'Por favor, intenta mas tarde'
      })
    }

    this.isLoading = true;
    this.loaderSrv.showSpinner('Enviando OTP');
    await this.authSrv.sentOtpforResetPassword(this.userEmail).then((res) => {
      if (res.status === 201) {
        this.loaderSrv.hideSpinner();
        Swal.fire({
          icon: 'success',
          title: 'OTP enviado',
          text: 'Hemos enviado un OTP a tu email'
        });
        this.sentEmail = true;
        this.isDisabled = true;
        this.countdown = 60;
        this.interval = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            this.isDisabled = false;
            clearInterval(this.interval);
          }
        }, 1000);
      }
    }).catch((error) => {
      console.log(error);
      this.loaderSrv.hideSpinner();
      Swal.fire({
        icon: 'error',
        title: 'Error al enviar el OTP',
        text: 'Por favor, intenta de nuevo'
      });
    });

    this.isLoading = false;
  }

  convertToUppercase(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
    this.forgotForm.get('otp')?.setValue(input.value);
  }

  async changePassword() {

    if (!this.otp?.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Otp no válido',
        text: 'Por favor, otp válido'
      }); 
      return; 
    }

    if (!this.password?.valid) {
      console.log(this.password)
      const error = this.password?.errors as any
      console.log(error);
      if (error.minlength) {
        Swal.fire({
          icon: 'error',
          title: 'Contraseña no válida',
          text: `La contraseña debe tener al menos ${error.minlength.requiredLength} 
                caracteres y actualmente tiene  ${error.minlength.actualLength}`
        });
        return;
      }
      if (error.maxlength) {
        Swal.fire({
          icon: 'error',
          title: 'Contraseña no válida',
          text: `La contraseña debe tener máximo ${error.maxlength.requiredLength} 
                caracteres y actualmente tiene  ${error.maxlength.actualLength}`
        });
        return;
      }
      if (this.password?.errors?.['pattern']?.requiredPattern){
        switch (this.password?.errors?.['pattern']?.requiredPattern) {
          case '/[A-Z]/':
            Swal.fire({
              icon: 'error',
              title: 'Contraseña no válida',
              text: 'Por favor, introduce al menos una mayúscula'
            });
            break;
          case '/[a-z]/':
            Swal.fire({
              icon: 'error',
              title: 'Contraseña no válida',
              text: 'Por favor, introduce al menos una minúscula'
            });
            break;
          case '/[0-9]/':
            Swal.fire({
              icon: 'error',
              title: 'Contraseña no válida',
              text: 'Por favor, introduce al menos un número'
            });
            break;
          default:
            Swal.fire({
              icon: 'error',
              title: 'Contraseña no válida',
              text: 'Por favor, introduce una contraseña válida'
            });
            break
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Contraseña no válida',
        text: 'Por favor, introduce una contraseña con al menos 6 caracteres, un número, una letra mayúscula y una letra minúscula.'
      });
      return;
    }

    if (this.password?.value != this.confirmPassword?.value) { 
      Swal.fire({
        icon: 'error',
        title: 'Las contraseñas no coinciden',
        text: 'Por favor, verifica que las contraseñas coincidan'
      });
      return;
    }

    this.isLoading = true;
    this.loaderSrv.showSpinner('Cambiando contraseña');
    try {
      const restPassword = await this.authSrv.forgotPassword(this.userEmail, this.password?.value, this.otp?.value);
      if (restPassword.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Contraseña cambiada',
          text: 'Tu contraseña ha sido cambiada correctamente'
        });
      }
      this.forgotForm.reset();
      this.loaderSrv.hideSpinner();
      this.isLoading = false;
    } catch (error : any) {
      console.log(error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error al cambiar la contraseña',
        text: 'Por favor, intenta de nuevo'
      });
      this.isLoading = false;
      this.loaderSrv.hideSpinner();
      return;
    }
  }
}
