import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoaderService } from 'src/app/services/loader.service';
import { KycService } from 'src/app/services/modal-kyc/kyc.service';
import { KycService as KycSrv } from 'src/app/services/kyc.service';
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.css']
})
export class MainContainerComponent implements OnInit, AfterViewInit {

  isLoading: boolean = true;
  loadingMessage: string = 'Cargando...';
  intialLoading: boolean = false;
  initialLoadingMessage: string = 'Cargando...';
  isInvestPage: boolean = false;
  isWhiteBackground: boolean = false;

  constructor(
    private loaderService: LoaderService, 
    private kycService: KycService, 
    private kycSrv: KycSrv,
    private crf: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.loaderService.initialLoadingStatus.subscribe((loading) => {
    //   this.intialLoading = loading?.isLoading || false;
    //   this.initialLoadingMessage = loading?.loadingMessage || '';
    // });

    this.loaderService.dataIsLoading.subscribe((loading) => {
      this.isLoading = loading?.isLoading || false;
      this.loadingMessage = loading?.loadingMessage || '';
    });

    this.router.events
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.isWhiteBackground = event.url === '/contacto';
          console.log(this.isWhiteBackground);
        }
      });

    setTimeout(() => {
      this.kycService.openModalKyc();
    }, 1500);

    // Suscribirse a los cambios de ruta
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        const currentRoute = event.urlAfterRedirects;
        this.isInvestPage = currentRoute.startsWith('/oportunidad') && this.router.routerState.snapshot.root.queryParamMap.has('id');
        console.log('Se ejecutó el evento de navegación', currentRoute);
      });
  }

  ngAfterViewInit(): void {
    // Forzar la detección de cambios después de que la vista se haya inicializado
    this.crf.detectChanges();
  }
}