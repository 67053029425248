import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';

interface DocumentNative {
  projectName: string;
  filename: string;
  project: string;
}
@Component({
  selector: 'app-investments-documents',
  templateUrl: './investments-documents.component.html',
  styleUrls: ['./investments-documents.component.css'],
})

export class InvestmentsDocumentsComponent implements OnInit {
  documents: any;
  baseUrl = 'gs://domoblock-devnew.appspot.com'

  constructor(
    private afs: AngularFirestore,
    private authSrv: AuthService,
    private storage: AngularFireStorage,
    private loaderSrvc: LoaderService
  ) {}

  ngOnInit(): void {
    this.getDocuments();
  }


  getDocuments() {
    this.loaderSrvc.showSpinner('Cargando documentos');
    this.afs.collection('user-tx-documents').doc(this.authSrv.userAuth.getValue().uid).get().subscribe(async(data: any) => {
      if (!data.exists) {
        console.log('No documents found');
        this.documents = [];
        this.loaderSrvc.hideSpinner();
        return;
      }
      console.log(data.data().documents);
      this.documents = await Promise.all((data.data().documents as DocumentNative[]).map(async (doc:DocumentNative, index) => {
        return ({
          title: `${doc.projectName ?? 'Contrato de inversion'}`,
          creationDate: new Date(+doc.filename),
          link: await firstValueFrom(this.storage.ref(`user/${this.authSrv.userAuth.getValue().uid}/${doc.project}/${doc.filename}.pdf`).getDownloadURL())
        });
      }));
      console.log(this.documents);
      this.loaderSrvc.hideSpinner();
    });
  }
}
