<div>
    <div>
        <div class="modal-content">
            <div class="modal-body">
                <div class="form-title text-center">
                    <h4>Regístrate</h4>
                    <label class="textcolor">Domoblock es una forma fácil, segura y rentable de invertir en
                        inmuebles</label>
                </div>
                <div class="d-flex flex-column text-start">
                    <div *ngIf="capsLockActivated" class="text-danger">
                        <span> *Las mayúsculas están activadas.
                        </span>
                    </div>
                    <form *ngIf="formRegistro !== undefined || formRegistro !== null" [formGroup]="formRegistro">

                        <div class="form-group">
                            <label for="Email" class="labeltext">Email</label>
                            <input formControlName="emailR" class="form-control" id="emailR"
                                placeholder="Ingresa tu email">
                        </div>
                        <div class="form-group pbc password-div">
                            <label for="Contraseña" class="labeltext">Contraseña</label>
                            <input formControlName="passwordR1" type="{{showPassword ? 'text' : 'password' }}"
                                id="passwordR1" #passwordR1 class="form-control form-control-sm"
                                placeholder="Ingresa tu contraseña">

                            <div id="passwordValidationMessage" style="display: none;"
                                class="password-validation-message text-danger">
                                <span> *La contraseña debe contener al menos 6 caracteres, un número, una letra mayúscula
                                    y una letra minúscula.
                                </span>
                            </div>

                            <a (click)="togglePassword()" class="eye-b">
                                <i class="{{showPassword ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
                            </a>
                        </div>
                        <button (click)="registro()" [disabled]="formRegistro.invalid" type="button" class="btn ">
                            REGÍSTRATE
                        </button>
                    </form>
                    <label class="pbl">¿Ya tienes una cuenta? <span class="changecolor"
                            (click)="openLoginForm()">Iniciar sesión</span></label>
                    <div class="flex-container">
                        <div class="small-border"></div>
                        <div class="text-center">O si lo prefieres</div>
                        <div class="small-border"></div>
                    </div>
                    <button type="button" class="btngoogle" (click)="registerWithGoogle()">
                        <div class="flex-containerbutton">
                            <img class="img" src="../../../assets/img/devicon_google.png" alt="">
                            <label class="text" for="">Regístrate con Google</label>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>