<div class="pb-4">
    <div class="pt-5 d-flex justify-content-between">
        <div class="data-box">
             <p>Saldo disponible</p>
             <h4 class="font-weight-normal">{{balance}} €</h4>
        </div> 
         <div class="data-box">
             <p style="max-width: 140px;">Rentabilidad anual media obtenida</p>
             <h4>12.30%</h4>
         </div> 
         <div class="data-box">
             <p>Saldo disponible</p>
             <h4>1.500,00€</h4>
         </div> 
    
         <div id="chart" style="max-height: 120px;">
             <apx-chart
               [series]="chartOptions.series"
               [chart]="chartOptions.chart"
               [labels]="chartOptions.labels"
               [responsive]="chartOptions.responsive"
             ></apx-chart>
         </div>
     </div>
     <div class="pt-5 d-flex justify-content-between">
        <div class="data-box">
            <p>Capital total invertido</p>
            <h4 class="font-weight-normal">2.100,00€</h4>
       </div> 
        <div class="data-box">
            <p style="max-width: 140px;">Capital invertido en curso</p>
            <h4>1.000,00€</h4>
        </div> 
        <div class="data-box">
            <p>Intereses totales generados</p>
            <h4 class="font-weight-normal">351,00€</h4>
       </div> 
        <div class="data-box">
            <p style="max-width: 140px;">Intereses estimados a percibir</p>
            <h4>106,54€</h4>
        </div> 
        <div class="data-box">
            <p style="max-width: 140px;">Capital retirado</p>
            <h4>0,00€</h4>
        </div> 

     </div>

     <div class="pt-5" >
        <div id="chart2">
            <apx-chart
              [series]="chartOptions2.series"
              [chart]="chartOptions2.chart2"
              [xaxis]="chartOptions2.xaxis"
              [stroke]="chartOptions2.stroke"
              [tooltip]="chartOptions2.tooltip"
              [dataLabels]="chartOptions2.dataLabels"
              [legend]="chartOptions2.legend"
              [markers]="chartOptions2.markers"
              [grid]="chartOptions2.grid"
              [yaxis]="chartOptions2.yaxis"
              [title]="chartOptions2.title"
            ></apx-chart>
          </div>
     </div>


</div>