
import {
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detail-real-estate',
  templateUrl: './detail-real-estate.component.html',
  styleUrls: ['./detail-real-estate.component.css'],
})
export class DetailRealEstateComponent implements OnInit {
  @Input() info: any | undefined;
  @Input() cantInvest: boolean = true;
  @Output() closeDetailOpenInvest = new EventEmitter<string>();
  formattedString: string = '';
  keys: any[] | undefined;
  images: string[] | undefined;
  infoObject: any;
  constructor(
    private crf: ChangeDetectorRef,
    public dialogRef: MatDialogRef<DetailRealEstateComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.info = data.info;
    this.cantInvest = data.cantInvest;
  }

  closeOpen() {
    this.closeDetailOpenInvest.emit(this.info.id);
    this.dialogRef.close();
  }

  invest(){
    this.closeDetailOpenInvest.emit(this.info.id);
    this.dialogRef.close();
    this.router.navigate(['/oportunidad'], { queryParams: { id: this.info.id } });
  }

  transformDecimals(num: number) {
    const numberFormated = new Intl.NumberFormat('de-DE').format(num);
    return numberFormated;
  }

  getUriIframeGoogle(iframe: string) {
    let googleUrl: any = iframe;
    googleUrl = googleUrl.replace('<iframe src="', '');
    googleUrl = googleUrl.split('" width');
    return googleUrl[0];
  }

  goPolyscan(sellerAddress: string) {
    window.open(`https://polygonscan.com/address/${sellerAddress}`, '_blank');
  }

  ngOnInit(): void {
    this.keys = Object.keys(this.info.attributes);

    this.infoObject = this.info.attributes;
    this.formatDataToString();
    let imgs: any[] = [];
    this.info.images.forEach((url: string) => {
      imgs.push(url);
    });
    imgs.length > 0 ? (this.images = imgs) : null;

    this.crf.detectChanges();
  }

  formatDataToString(): void {
    const order = ['Superficie', 'Planta', 'Habitaciones', 'Baños', 'Ascensor', 'Garaje'];


    const orderedFields = order
      .filter(field => field in this.infoObject) 
      .map(field => `${field}: ${this.infoObject[field]}`); 


    const remainingFields = Object.keys(this.infoObject)
      .filter(field => !order.includes(field))
      .map(field => `${field}: ${this.infoObject[field]}`); 

    this.formattedString = [...orderedFields, ...remainingFields].join('&nbsp;&nbsp;&nbsp;'); 
  }
}
