import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PaymentService } from 'src/app/services/payment.service';
import * as jsonHoldings from 'src/app/abis/TokenHoldings.json';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import { LoaderService } from 'src/app/services/loader.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { ModalInvestmentDetailComponent } from '../modal-investment-detail/modal-investment-detail.component';

@Component({
  selector: 'app-investments-details',
  templateUrl: './investments-details.component.html',
  styleUrls: ['./investments-details.component.css']
})
export class InvestmentsDetailsComponent implements OnInit {
  arrayInvestments: any[] = [];
  walletDomoblock: any;
  holdingsAbi: any = jsonHoldings;
  web3: any | undefined;
  dividendClaimed: boolean = false;

  //Filters and sort variables
  searchQuery: string = '';
  selectedFilter: string = '';
  selectedSort: string = 'Mas recientes';
  isDropdownOpen: boolean = false;
  userPersonalAddress: string = '';

  constructor(
    private afs: AngularFirestore,
    private authSrv: AuthService,
    private  paymentService: PaymentService,
    private connSrv: ConnectionsService,
    private loaderSrv: LoaderService,
    private dialog: MatDialog,
  ) { 
    this.web3 = this.connSrv.web3Instance;
    this.connSrv.addressUser.subscribe((res: string) => {
      this.userPersonalAddress = res;
    });
  }

  ngOnInit(): void {
    this.getInvestments();
  }

  async getInvestments() {

    const userId = this.authSrv.userAuth.getValue().uid;

    this.loaderSrv.showSpinner('Cargando inversiones...');
    const walletRef = await firstValueFrom(this.afs.collection("users-walletsByCompany").doc(userId).get())
    const walletData = walletRef.data() as any;
    this.walletDomoblock = walletData.address;

    const userInvesments = await firstValueFrom(this.afs.collection("user-investments").doc(userId).get())
    const investmentsId = userInvesments.data() as any
    const investmentsArray = Object.keys(investmentsId)
    const resultMap = await Promise.all(investmentsArray.map(async (investment) => {
      const investmentDataRef = await firstValueFrom(this.afs.collection("investments").doc(investment).get());
      if(!investmentDataRef.exists)
        return;
      const investmentData = investmentDataRef.data() as any;
      const dividendRef = await firstValueFrom(this.afs.collection('dividends-mangopay').doc(investment).get());
      // console.log(dividendRef.data())
      if (dividendRef.exists) {
        const tempDividend = dividendRef.data() as any;
        console.log(tempDividend)
        investmentData.dividends = tempDividend?.dividend ? tempDividend.dividend : [];
        // console.log(investmentData.dividend)
        
        // console.log(this.dividendClaimed)
      } else {
        investmentData.dividends = [];
      }

      const transactionsRef = await firstValueFrom(this.afs.collection("transactions-mangopay", ref => ref
        .where('user','==',userId)
        .where('project','==',investmentDataRef.id)
      ).get());

      let mangopayInvested = 0;
      investmentData.transactions = transactionsRef.docs.map((doc) => {
        const transactionData = doc.data() as any;
        mangopayInvested += transactionData?.amount ?? 0;
        return transactionData;
      });

      investmentData.mangopayInvested = mangopayInvested;

      const contractHoldings: any = new this.web3.eth.Contract(this.holdingsAbi.abi, investmentData.tokensAddress);
      let amountInvested = this.formatToEther(await contractHoldings.methods.balanceOf(this.walletDomoblock).call());
      if(this.userPersonalAddress)
        amountInvested += this.formatToEther(await contractHoldings.methods.balanceOf(this.userPersonalAddress).call());
      investmentData.amountInvested = parseFloat(amountInvested);
      

      return investmentData;
    }));

    this.arrayInvestments = resultMap.filter((data)=> data !== undefined || data != null)
    console.log(this.arrayInvestments)
    this.loaderSrv.hideSpinner();
  }


  claimDividends(projectId: string) {

    if (this.dividendClaimed) {
      Swal.fire({
        icon: 'info',
        title: 'Dividendo ya reclamado',
        text: 'El dividendo de este proyecto ya ha sido reclamado'
      });
      return;
    }

    const data = {
      userId: this.authSrv.userAuth.getValue().uid,
      projectId: projectId,
      userWallet: this.walletDomoblock
    }

    Swal.fire({
      title: 'Reclamando dividendo...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    this.paymentService.claimDividends(data).subscribe((res) => {
      console.log(res)
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Dividendo reclamado',
        text: 'El dividendo ha sido reclamado con éxito'
      });
    }, error => {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al reclamar el dividendo'
      });
      console.log(error)
    })
  }


  getStatusClass(status: string): string {
    switch (status) {
      case 'Activo':
        return 'status-in-progress';
      case 'Gestión pendiente':
        return 'status-pending';
      case 'Cerrada':
        return 'status-closed';
      default:
        return '';
    }
  }

  formatToEther(num: number) {
    const BN = this.web3.utils.BN;

    try {
      return this.web3.utils.fromWei(new BN(num).toString(), 'ether');
    } catch (error) {
      return this.web3.utils.fromWei(num.toString(), 'ether');
    }
  }

  setFilter(filter: string) {
    this.selectedFilter = filter;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectSort(sort: string) {
    this.selectedSort = sort;
    this.isDropdownOpen = false;
  }



  openInvestmentDetail(investment: any) {
    const dialogRef = this.dialog.open(ModalInvestmentDetailComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '90%',
      maxHeight: '90%',
      //panelClass: 'custom-full-screen-modal',
      data: {investment: investment, walletDomoblock: this.walletDomoblock}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('El modal se ha cerrado');
    });
  }

}
