import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CustomCurrencyPipe } from '../../shared/custom-currency.pipe';
import { PaymentService } from 'src/app/services/payment.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import Swal from 'sweetalert2';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import firebase from 'firebase/compat/app';
import { countries, excludedCountries } from 'src/app/interfaces/all-country';
import * as IBAN from 'iban';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.css']
})
export class WithdrawComponent implements OnInit {
  @ViewChild('closeModalButton') closeModalButton!: ElementRef;
  @ViewChild('closeAddBankAccount') closeAddBankAccount!: ElementRef;
  @ViewChild('openModalBankAccount') openModalBankAccount!: { _elementRef: ElementRef; };
  @ViewChild('openModalBankWithdraw') openModalBankWithdraw!: { _elementRef: ElementRef; };

  bankAccounts: any[] = [];
  iban: string = '';
  banks: string[] = ['BBVA', 'Santander', 'CaixaBank', 'Bankia', 'Bankinter', 'Sabadell', 'ING', 'Openbank', 'EVO', 'N26'];
  amount: number = 0;
  formattedAmount: string = '';
  selectedAccount: string = '';
  bic: string = '';
  iban1: any;
  selectedCountry: any;
  countries: any = countries;
  userRef: any;

  constructor(
    private paymentServive : PaymentService,
    private authSrv: AuthService,
    private loaderService: LoaderService,
    private afs: AngularFirestore,
  ) { }

  ngOnInit(): void {
    this.getBancksAccounts();
    this.getUserRef();

    this.selectedCountry = this.countries.find(
      (country: any) => country.code === 'ES'
    );
  }

  async deleteBankAccount(index: number) {
    this.loaderService.showSpinner();
    await this.userRef.ref.update({ bankAccount: this.bankAccounts.filter((_, i) => i !== index) });
    this.loaderService.hideSpinner();
    Swal.fire('Eliminado', 'Cuenta bancaria eliminada correctamente', 'success');
    this.bankAccounts.splice(index, 1);
  }

  // formatAmount(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   const value = parseFloat(input.value.replace(/[^0-9.-]+/g, ''));
  //   this.formattedAmount = new CustomCurrencyPipe().transform(value);
  // }

  async getUserRef() {
    this.userRef = await firstValueFrom(this.afs.collection('users').doc(this.authSrv.userAuth.getValue().uid).get());
  }

  getBancksAccounts() {
    const uid = this.authSrv.userAuth.getValue().uid;
    this.afs.collection('users').doc(uid).get().subscribe(
      (response:any) => {
        const data = response.data();
        if (data) {
          if(typeof data?.bankAccount == 'string')
          this.bankAccounts.push({alias:'sin asignar',iban:data.bankAccount});
          else
          this.bankAccounts = data.bankAccount.map((item:any) => {
            return {
              ...item,
              string: `${item.iban} (${item.alias})`
            }
          });
        }
      }
    );
  } 

  formatAmount(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = parseFloat(input.value.replace(/[^0-9.-]+/g, ''));
    if (!isNaN(value)) {
      this.formattedAmount = new CustomCurrencyPipe().transform(value);
    } else {
      this.formattedAmount = '';
    }
  }

  requestWithdraw(): void {
    if (this.amount <= 0) {
      Swal.fire('Error', 'La cantidad debe ser mayor a 0', 'error');
      return;
    }

    if (this.selectedAccount === '') {
      Swal.fire('Error', 'Seleccione una cuenta bancaria', 'error');
      return;
    }
    const data = {
      uid: this.authSrv.userAuth.getValue().uid,
      account: this.selectedAccount,
      amount: this.amount * 100
    };

    this.loaderService.showSpinner('Realizando la solicitud de retirada');
    this.paymentServive.requestWithdrawal(data).subscribe(
      (response) => {
        this.loaderService.hideSpinner();
        Swal.fire('Solicitud de retirada realizada con éxito', 'La retirada de fondos se está gestionando. Este proceso puede tardar hasta 72 horas', 'success');
        this.closeModal('withdraw');
      },
      (error) => {
        this.loaderService.hideSpinner();
        if (error.error.message === 'Insufficient funds') {
          Swal.fire('Error', 'Fondos insuficientes', 'error');
        } else {
          Swal.fire('Error', 'Ha ocurrido un error, por favor intente mas tarde', 'error');
        }
      }
    );
  }  

  closeModal(modal: string) {
    if (modal === 'addBankAccount') this.closeAddBankAccount.nativeElement.click();
    if (modal === 'withdraw') this.closeModalButton.nativeElement.click();
  }

  openModalWithdraw(){
    console.log(this.openModalBankWithdraw);
    if(this.bankAccounts.length === 0){
      Swal.fire({
        title: '¡No tienes ninguna cuenta vinculada!',
        text: 'Para solicitar un retiro primero deberás añadir una cuenta bancaria',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Vincular una cuenta',
        cancelButtonText: 'Ahora no',
      }).then((result) => {
        if (result.isConfirmed) {
          this.closeModal('withdraw');
          this.openModalBankAccount._elementRef.nativeElement.click();
        }
      });
      return;
    }
    this.openModalBankWithdraw._elementRef.nativeElement?.click();
  }

  async addIban(){

   if ( this.shouldValidateIBAN(this.selectedCountry.code) || !this.validateIBAN(this.selectedCountry.code + this.iban1)) {      
    
    Swal.fire(
      {        
        title: 'Datos invalidos',
        text: 'Ha introducido una cuenta bancaria invalida',        
        icon: 'warning',      
      });
    return;
  }

    if(this.iban.trim()!== ''){
      if(!this.userRef.exists)
      Swal.fire('Error','Usuario no encontrado', 'error');
      if(!this.iban1)
        Swal.fire('Error', 'Todos los campos IBAN son requeridos', 'error')
      else{
        const iban:string = this.selectedCountry.code + this.iban1;
        const accountObject = {
          alias:this.iban,
          iban:iban.toUpperCase(),
          bic: this.bic
        };
        this.loaderService.showSpinner('Agregando IBAN...');
        await this.userRef.ref.update({ bankAccount: firebase.firestore.FieldValue.arrayUnion(accountObject)});
        this.loaderService.hideSpinner();
        this.closeModal('addBankAccount');
        Swal.fire('Agregado', 'IBAN agregado correctamente', 'success');
        this.bankAccounts.push(accountObject);
      }
    }else{
      Swal.fire('Error', 'El campo IBAN no puede estar vacío', 'error');
    }
  }

  searchText: string = '';

  filteredCountries = this.countries;
  filterCountries() {
    this.filteredCountries = this.countries.filter((country: any) =>
      country.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  onCountryChange(country: any) {
    this.selectedCountry = country;
  }

    validateIBAN(iban: string): boolean {
      return IBAN.isValid(iban);
    }
  
    shouldValidateIBAN(countryCode: string): boolean {
      return excludedCountries.includes(countryCode);
    }
}
