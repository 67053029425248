import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import Swal from 'sweetalert2';
import * as jsonWhitelist from '../../../abis/WhiteList.json';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ModalConnector } from 'src/app/services/modal-connection.service';
import { AuthService } from 'src/app/services/auth.service';
import firebase from 'firebase/compat/app';

declare global {
  interface Window {
    ethereum: any;
  }
}

@Component({
  selector: 'app-blockchain',
  templateUrl: './blockchain.component.html',
  styleUrls: ['./blockchain.component.css']
})
export class BlockchainComponent implements OnInit {

  web3: any;
  abiWhitelist: any = jsonWhitelist;
  walletsWhitelist: string[] = [];
  contractWhitelist: any;

  constructor(
    private fns: AngularFireFunctions,
    private connSrv: ConnectionsService,
    private afs: AngularFirestore,
    private modalConnectionSrv: ModalConnector,
    private authSrv: AuthService
  ) { 
    this.web3 = this.connSrv.web3Instance;
   }

  ngOnInit(): void {
    const uid = this.authSrv.userAuth.getValue().uid;

    this.afs.collection('users').doc(uid).valueChanges().subscribe((res: any) => {
      if (typeof res.walletsWhitelist == 'object') {
        this.walletsWhitelist = res.walletsWhitelist;
      }
    });
  }

  async deleteWallet(wallet: string){
    this.contractWhitelist = new this.web3.eth.Contract(this.abiWhitelist.abi, environment.config.ADDRESS_WHITELIST);
    if (this.web3.utils.toChecksumAddress(wallet) !== this.web3.utils.toChecksumAddress(wallet)) {
      Swal.fire({
        icon: 'error',
        title: 'Selecciona la wallet en tu MetaMask',
        text: 'La wallet insertada no coincide con la de tu MetaMask'
      });

      return;
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    await this.web3.eth.personal.sign(
      'Set wallet', 
      wallet, 
      environment.config.PASSWORD_BACK_AUTH 
    )
      .then(async (signature: string) => { 
        await this.fns.httpsCallable('deleteWalletUser')({
          address: wallet,
          signature
        }).subscribe(async (res: any) => {
          if (res === undefined || res.success === undefined || !res.success) {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo ha ido mal!'
            });

            return;
          }

          const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
            return Number(block.baseFeePerGas) + 100000000000; 
          });

          this.contractWhitelist.methods.setStatusWithSignature(
            wallet,
            0,
            res.timeStamp,
            res.signature
          ).send({from: wallet, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
            .then(async () => {
              const uid = await this.authSrv.userAuth.getValue().uid;
              this.afs.collection('users').doc(uid).set({
                walletsWhitelist: firebase.firestore.FieldValue.arrayRemove(wallet)
              }, {merge: true});
              
              Swal.close();
              Swal.fire({
                icon: 'success',
                text: `La wallet se ha eliminado con éxito`
              });
            })
            .catch(() => {
              Swal.fire({
                icon: 'error',
                title: 'Transacción errónea',
                text: 'Algo ha ido mal, la transacción NO se ha realizado'
              });
            }, {timeout: 300000});
        });
      })
      .catch(() => { 
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });

        return; 
      });
  }

  async addWallet() {
    
    return await new Promise<boolean>(async (resolve, reject) => {

      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const wallet = accounts[0];
      Swal.fire({
        title: 'Procesando...',
        html: 'No cierres esta pantalla!!',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });


      if(!wallet){
        Swal.fire({
          icon: 'error',
          title: 'Cartera no detectada',
          text: 'Verifica que tu cartera de metamask esta conectada'
        });
        return;
      } 
   
   
       await this.web3.eth.personal.sign(
         'Set wallet', 
         wallet, 
         environment.config.PASSWORD_BACK_AUTH
       )
         .then(async (signature: string) => {
          this.fns.httpsCallable('newWalletUser')({
             address: wallet,
             signature: signature
           }).subscribe(async (res: any) => {
             console.log(res);
             const contractWhitelist =  new this.web3.eth.Contract(this.abiWhitelist.abi, environment.config.ADDRESS_WHITELIST);
             if (res === undefined || res.success === undefined || !res.success) {
               Swal.close();
               Swal.fire({
                 icon: 'error',
                 title: 'Oops...',
                 text: 'Algo ha ido mal!'
               });
   
               return resolve(false);
             }
   
             const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
               return Number(block.baseFeePerGas) + 100000000000; 
             });
   
             contractWhitelist.methods.setStatusWithSignature(
                wallet,
               1,
               res.timeStamp,
               res.signature
             ).send({from: wallet, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
               .then(async () => {
                 const uid = this.modalConnectionSrv.getUserUid();
   
                 this.afs.collection('users').doc(uid).set({
                     walletsWhitelist: firebase.firestore.FieldValue.arrayUnion(wallet),
                     hideBlockchain: false
                   }, {merge: true});
                 
   
                 Swal.close();
                 Swal.fire({
                   icon: 'success',
                   text: `La wallet se ha añadido con éxito`
                 });
               }).then(() => resolve(true))
               .catch((res: any) => {
                 Swal.close();
                 Swal.fire({
                   icon: 'error',
                   title: 'Transacción errónea',
                   text: 'Algo ha ido mal, la transacción NO se ha realizado'
                 });
                 resolve(false);
               }, {timeout: 300000});
           });
         })
         .catch(() => { 
           Swal.close();
           Swal.fire({
             icon: 'error',
             title: 'Oops...',
             text: 'Algo ha ido mal!'
           });
   
           return; 
         });
     })
   }

}
