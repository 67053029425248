<div class="investment-container">

    <div class="menu-investment">
        <div class="menu-options font font-weight-bold" style="position: relative;">
            <p #resume (click)="selectOption('resume')">RESUMEN FINANCIERO</p>
            <p #transactions (click)="selectOption('transactions')">MOVIMIENTOS</p>
            <p #investments (click)="selectOption('investments')">MIS INVERSIONES</p>
            <p #documents (click)="selectOption('documents')">DOCUMENTOS</p>
        </div>

        <div class="divider">
            <div class="divider-section" [ngStyle]="{'width': selectedWidth, 'left': selectedLeft}"></div>
        </div>
    </div>


    <div class="content scrollable-container mt-3">
        <div *ngIf="selectedOption === 'resume'">
            <app-resume-investment></app-resume-investment>
        </div>
        
        <div *ngIf="selectedOption === 'transactions'">        
            <app-investments-transactions></app-investments-transactions>
        </div>

        <div *ngIf="selectedOption === 'investments'">        
          <app-investments-details></app-investments-details>
        </div>

        <div *ngIf="selectedOption === 'documents'">        
            <app-investments-documents></app-investments-documents>
        </div>

    </div>
</div>