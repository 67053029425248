<div class="d-flex justify-content-start align-items-center mb-4 text-white ">
    <div class="me-3">
        <strong>Email registrado:</strong> {{ userEmail }}
    </div>
    <div class="ms-3">
        <strong>Cuenta Bancaria:</strong> {{ bankAccount }}
    </div>
</div>
<div class="profile-container">

    <form class="p-4 bradius" [formGroup]="formDisplayName">
        <!-- Estado de verificación del usuario con emoji a la izquierda del texto -->
        <div class="mb-4 text-white d-flex align-items-center">
            <span *ngIf="acreditado" class="d-flex align-items-center">
                <i class="bi bi-emoji-sunglasses-fill me-2" style="font-size:1.5rem;color:#f09502;"></i>Usuario
                ACREDITADO
            </span>
            <span *ngIf="loggedIn && !acreditado && !revision" class="d-flex align-items-center">
                <i class="bi bi-bell-fill me-2" style="font-size:1.5rem;color:#f09502;"></i>Usuario NO ACREDITADO
            </span>
        </div>

        <!-- Título de ajustes generales -->
        <p class="h3 text-center mb-5 mt-3">Ajustes generales</p>

        <div class="mb-4">
            <label for="displayName" class="form-label text-white">Alias (No modifica el KYC):</label>
            <input formControlName="displayName" type="text" class="form-control" id="displayName"
                placeholder="Introduce tu alias">
        </div>

        <div class="mb-5">
            <button (click)="setDisplayName()" [disabled]="formDisplayName.invalid"
                class="btn btn-success pt-3 pb-3 mb-4">Cambiar alias</button>
            <button (click)="resetPasword()" class="btn btn-outline-success pt-3 pb-3">Cambiar contraseña de
                login</button>
            <button *ngIf="!verification" (click)="verificateEmail()"
                class="btn btn-outline-success pt-3 pb-3 mt-3">Verificar email</button>
            <div class="form-check form-switch mt-3 position-relative">
                <input class="form-check-input position-absolute" type="checkbox" id="flexSwitchCheckDefault"
                    [checked]="hideBlockchain" (click)="updateHideBlockchain()">
                <label class="form-check-label" for="flexSwitchCheckDefault">Desactivar Funciones Blockchain</label>
            </div>
        </div>

        <div class="legal">
            <div *ngIf="urlTerminos != null"><a [href]="urlTerminos" target="_blank" download>Términos legales y
                    condiciones</a></div>
            <div *ngIf="urlAviso != null" class="mt-1"><a [href]="urlAviso" target="_blank" download>Aviso legal</a>
            </div>
            <div *ngIf="urlPrivacidad != null" class="mt-1"><a [href]="urlPrivacidad" target="_blank" download>Política
                    de Privacidad</a></div>
            <div *ngIf="urlCookies != null" class="mt-1"><a [href]="urlCookies" target="_blank" download>Política de
                    Cookies</a></div>
            <div class="mt-3"><a href="https://www.jotform.com/form/223313245145042" target="_blank">
                    <p class="btn btn-outline-success mb-xl-0">Contactar con la empresa</p>
                </a></div>
        </div>
    </form>

    <form class="p-4 bradius" *ngIf="(formNewWallet !== undefined || formNewWallet !== null) && !hideBlockchain"
        [formGroup]="formNewWallet">
        <p class="h3 text-center mb-2 mt-3">Configuración de wallets</p>
        <p class="mb-5 text-center">Añade una dirección donde recibir tus tokens</p>

        <div class="mb-4">
            <label for="wallet" class="form-label text-white">Wallet para usar (Max 3):</label>
            <input formControlName="wallet" type="text" class="form-control" id="wallet"
                placeholder="Introduce una address">
        </div>

        <div>
            <button (click)="addWallet()" [disabled]="formNewWallet.invalid || walletsWhitelist.length >= 3"
                class="btn btn-success pt-3 pb-3">Añadir wallet</button>
            <p class="small wallet pb-3 mt-5" *ngFor="let w of walletsWhitelist">{{truncate(w)}} <button
                    (click)="deleteWallet(w)" class="btn btn-danger">Eliminar</button></p>
        </div>
    </form>
</div>