<div class="p-4">
    <div  class="position-relative">
        <div class="close-btn" (click)="closeDialog()"><i class="bi bi-x-lg"></i></div> 
    </div>
    <div class="modal-header">
        <div class="project-info">
            <img [src]="investment.mainImage" alt="Project Icon" class="project-icon">
            <div>
              <div class="project-name">{{investment.title}}</div>
              <div class="project-location"><img src="../../../../assets/img/location.svg"/> {{investment.location}}</div>
            </div>
        </div>

        <div class="d-flex gap-4">
            <div class="data-box">
                <p>Capital Invertido</p>
                <h4 class="font-weight-normal">{{ (investment.mangopayInvested / 100) | customCurrency }}</h4>
           </div> 
            <div class="data-box">
                <p style="max-width: 140px;">Retorno estimado</p>
                <h4>{{ (investment.mangopayInvested / 100) * (1 + (investment.annualReturn / 100)) | customCurrency }}</h4>
            </div>  
            <div class="data-box">
                <p>¿Buscas más info?</p>
                <a href="https://www.domoblock.io/" target="_blank" style="color: #ff9600;">Seguimiento</a>
           </div> 
        </div>

        <div class="transactions-btns">
            <span style="color: #b9b9b9;">Generar documento: </span>
            <a href="https://www.domoblock.io/" target="_blank" style="color: #20a366;">EXCEL</a>
            <a href="https://www.domoblock.io/" target="_blank" style="color: #e3262a;">PDF</a>
        </div>
    </div>

    <!-- table -->
    <div class="mt-4" style="font-size: 14px;" *ngIf="dividends.length > 0 && !isLoading">
        <div class="row row-cols-10">
            <div class="col-1 fw-bold text-muted">Id</div>
            <div class="col fw-bold text-muted">Capital Invertido</div>
            <div class="col fw-bold text-muted">Interés bruto</div>
            <div class="col fw-bold text-muted">Retorno bruto</div>
            <div class="col fw-bold text-muted">Tipo impositivo</div>
            <div class="col fw-bold text-muted">Intereses netos</div>
            <div class="col fw-bold text-muted">Retorno neto</div>
            <div class="col fw-bold text-muted">Tipo de retorno</div>
            <div class="col fw-bold text-muted">Fecha</div>
            <div class="col fw-bold text-muted">Acción</div>
        </div>
        <div class="list-group">
                <div class="row row-cols-10 info-card" *ngFor="let dividend of dividends">
                    <!-- id -->
                    <div class="col">{{ dividend.dateCreation.toString().slice(-4) }}</div>

                    <!-- amount invested -->
                    <div class="col">{{investment.mangopayInvested / 100 | customCurrency}}</div>

                    <!-- interes bruto -->
                    <div class="col" >{{ (dividend.participationPercentage / 100) * dividend.totalAmount | customCurrency }}</div>

                    <!-- retorno bruto -->
                    <div class="col" *ngIf="!dividend.isRent">{{ ((dividend.participationPercentage / 100) * dividend.totalAmount) + (investment.mangopayInvested / 100) | customCurrency }}</div>
                    <div class="col" *ngIf="dividend.isRent">{{ ((dividend.participationPercentage / 100) * dividend.totalAmount) | customCurrency }}</div>

                    <!-- tipo impositivo -->
                    <div class="col">{{ !dividend.retention ? '0' : retention }}%</div>

                    <!-- intereses neto -->
                    <div class="col">{{ dividend.amountToClaim | customCurrency }}</div>
                    
                    <!-- retorno neto -->
                    <div class="col" *ngIf="!dividend.isRent">{{ ((investment.mangopayInvested / 100) + dividend.amountToClaim) | customCurrency }}</div>
                    <div class="col" *ngIf="dividend.isRent">{{ dividend.amountToClaim  | customCurrency }}</div>

                    <div class="col">Euros</div>
                    <div class="col" >{{getTimeStamp(dividend.dateCreation) | date:'dd-MM-yyyy'}}</div>
                    <div class="col">
                        <button mat-stroked-button (click)="claimDividends(dividend)">
                            <span  style="color: #FF9600;">Traspasar a cartera</span>
                        </button>
                    </div>
                </div>
        </div>
      </div>

    <div class="mt-4" *ngIf="(dividends.length === 0) && (dividendsClaimed.length === 0) && !isLoading">
        <div class="d-flex justify-content-center align-items-center">
            ⌛ Todavía no hay ningún retorno, no se han repartido beneficios ⌛
        </div>
    </div>

    <div class="mt-5" *ngIf="(dividendsClaimed.length > 0) && !isLoading">
        <div class="d-flex justify-content-center align-items-center" style="font-size: 16px;">
            💰 Retirada de <span class="fs-5 px-2" style="font-weight: 550;">{{claimedAmount | customCurrency}}</span> realizada correctamente a la cartera digital en euros 💰 
        </div>
    </div>

    <div class="mt-5" *ngIf="(dividendsClaimed.length > 0) && !isLoading">
        <div class="d-flex justify-content-center align-items-center" style="font-size: 16px;" 
        (click)="moreInfoCollapsed = !moreInfoCollapsed" [ngClass]="{'padding-p-collapse': moreInfoCollapsed}">
            Desglosar información   <i class="bi bi-chevron-down chevron-icon" style="margin-left: 0.4vw;" 
            [class.rotate]="moreInfoCollapsed"></i>
        </div>

        <!-- table -->
        <div class="mt-4" style="font-size: 14px;" [ngbCollapse]="moreInfoCollapsed">
            <div class="row row-cols-9">
                <div class="col-1 fw-bold text-muted">Id</div>
                <div class="col fw-bold text-muted">Capital Invertido</div>
                <div class="col fw-bold text-muted">Interés bruto</div>
                <div class="col fw-bold text-muted">Retorno bruto</div>
                <div class="col fw-bold text-muted">Tipo impositivo</div>
                <div class="col fw-bold text-muted">Intereses netos</div>
                <div class="col fw-bold text-muted">Retorno neto</div>
                <div class="col fw-bold text-muted">Tipo de retorno</div>
                <div class="col fw-bold text-muted">Fecha</div>
            </div>
            <div class="list-group">
                    <div class="row row-cols-9 info-card" *ngFor="let dividend of dividendsClaimed">

                        <!-- id column -->
                        <div class="col">{{ dividend.dateCreation.toString().slice(-4) }}</div>

                        <!-- amount invested -->
                        <div class="col">{{investment.mangopayInvested / 100 | customCurrency}}</div>

                        <!-- interes bruto -->
                        <div class="col" *ngIf="dividend.isRent">{{ dividend.amountClaimed | customCurrency }}</div>

                        <!-- retorno bruto -->
                        <div class="col" *ngIf="!dividend.isRent">{{ (investment.mangopayInvested / 100) * (1 + (dividend.participationPercentage / 100)) | customCurrency }}</div>
                        <div class="col" *ngIf="dividend.isRent">{{ dividend.amountClaimed | customCurrency }}</div>

                        <!-- tipo impositivo -->
                        <div class="col">{{ !dividend.retention ? '0' : retention }}%</div>

                        <!-- intereses netos -->
                        <div class="col" *ngIf="!dividend.retention">{{ (investment.mangopayInvested / 100) * (dividend.participationPercentage / 100) | customCurrency }}</div>
                        <div class="col" *ngIf="dividend.retention">{{ (investment.mangopayInvested / 100) * ((dividend.participationPercentage - retention)/ 100) | customCurrency }}</div>

                        <!-- retorno neto -->
                        <div class="col" *ngIf="dividend.retention && !dividend.isRent">{{ (investment.mangopayInvested / 100) * (1 + ((dividend.participationPercentage - retention)/ 100)) | customCurrency }}</div>
                        <div class="col" *ngIf="dividend.retention && dividend.isRent">{{ (investment.mangopayInvested / 100) * ((dividend.participationPercentage - retention)/ 100) | customCurrency }}</div>
                        <div class="col" *ngIf="!dividend.retention && !dividend.isRent">{{ (investment.mangopayInvested / 100) * (1 + (dividend.participationPercentage/ 100)) | customCurrency }}</div>
                        <div class="col" *ngIf="!dividend.retention && dividend.isRent">{{ (investment.mangopayInvested / 100) * (dividend.participationPercentage/ 100) | customCurrency }}</div>
                        
                        <!-- tipos de retorno -->
                        <div class="col">Euros</div>

                        <!-- fecha -->
                        <div class="col" >{{getTimeStamp(dividend.dateCreation) | date:'dd-MM-yyyy'}}</div>
                    </div>
            </div>
        </div>
    </div>


</div>